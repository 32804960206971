import React, { useEffect, useState, useCallback } from 'react';
import { Space, TablePaginationConfig } from 'antd';
import { Pagination, RoleTableRow } from '@app/api/table.api';
import { Table } from '@app/components/common/Table/Table';
import { ColumnsType } from 'antd/es/table';
import { Button } from '@app/components/common/buttons/Button/Button';
import { useTranslation } from 'react-i18next';
import { useMounted } from '@app/hooks/useMounted';
import { getRoles } from '@app/pages/Utilities/Roles/RolesData/RoleController';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';

const pageSize = 5;

export const RoleDataTable: React.FC = () => {
  const [tableData, setTableData] = useState<{ data: RoleTableRow[]; currentPage: number; loading: boolean }>({
    data: [],
    currentPage: 0,
    loading: false,
  });

  const { t } = useTranslation();
  const { isMounted } = useMounted();
  const [dataLength, setDataLength] = useState<number>();

  const fetch = useCallback(
    async (page: number) => {
      setTableData((tableData) => ({ ...tableData, loading: true }));
      try {
        const response = await getRoles(page * pageSize, pageSize);
        if (isMounted.current) {
          // Extract relevant information from the response and format it as ListenerTableRow[]
          const formattedData: RoleTableRow[] = response.roles.map((roles: any) => ({
            key: roles.roleId,
            desc: roles.roleDesc,
            status: roles.roleStatus ? t('tables.active') : t('tables.inactive'),
          }));
          setDataLength(formattedData.length);
          setTableData({ data: formattedData, currentPage: page, loading: false });
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
      }
    },
    [isMounted, t],
  );

  useEffect(() => {
    fetch(0);
  }, [fetch]);

  const columns: ColumnsType<RoleTableRow> = [
    {
      title: t('common.description'),
      dataIndex: 'desc',
      width: '30%',
    },
    {
      title: t('common.status'),
      dataIndex: 'status',
      width: '30%',
    },

  ];

  const handleNextPage = () => {
    fetch(tableData.currentPage + 1);
  };

  const handlePreviousPage = () => {
    if (tableData.currentPage > 0) {
      fetch(tableData.currentPage - 1);
    }
  };

  return (
    <>
      <Table
        columns={columns}
        dataSource={tableData.data}
        loading={tableData.loading}
        scroll={{ x: 800 }}
        bordered
        className="w-full"
        pagination={false}
      />
      <div className="custom-pagination">
        <Button onClick={handlePreviousPage} disabled={tableData.currentPage + 1 === 1}>
          <LeftOutlined />
        </Button>
        <span className="page-no"> {tableData.currentPage + 1}</span>
        <Button onClick={handleNextPage} disabled={dataLength !== undefined && dataLength < 5}>
          <RightOutlined />
        </Button>
      </div>
    </>
  );
};
