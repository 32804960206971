import CryptoJS from 'crypto-js';

const secretPass = process.env.REACT_APP_ENCRYPTION_SECRET_KEY as string;

/**
 * This method is used to decrypt a value
 * @param session
 * @returns
 */
export const DecryptData = (session: any) => {
  try {
    const bytes = CryptoJS.AES.decrypt(session, secretPass);
    const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return data;
  } catch (error) {
    console.error('Error during decryption:', error);
    return null;
  }
};
