import React, { useState, useEffect } from 'react';
import { Button, Space, Row, Col, Card } from 'antd';
import type { SizeType } from 'antd/es/config-provider/SizeContext';
import { useTranslation } from 'react-i18next';
import MediaQuery from 'react-responsive';
import {
  CloudFilled,
  CloudUploadOutlined,
  DeleteFilled,
  EditFilled,
  EditOutlined,
  EyeFilled,
  FolderAddFilled,
  FolderAddOutlined,
  PoweroffOutlined,
  PrinterFilled,
  QuestionCircleFilled,
  QuestionOutlined,
  SaveFilled,
  SaveOutlined,
} from '@ant-design/icons';

interface TopToolBarProps {
  onViewClick?: () => void;
  onAddNewClick?: () => void;
  onEditClick?: () => void;
  onDelete?: () => void;
  listenerID?: any;
}

const TopToolBar: React.FC<TopToolBarProps> = ({ onViewClick, onAddNewClick, onEditClick, onDelete, listenerID }) => {
  const [size, setSize] = useState<SizeType>('large');
  const { t } = useTranslation();

  useEffect(() => {
    if (listenerID) {
      if (!isNaN(listenerID) && listenerID > 0) {
        onAddNewClick;
      } else {
      }
    }
  }, [listenerID]);

  return (
    <div>
      <Card className="borderless">
        <Row gutter={{ xs: 8, md: 0, xl: 0 }}>
          <Col xs={3} md={3}>
            <MediaQuery minWidth={768}>
              {(matches) =>
                matches ? (
                  <Button
                    className="w-full br-0 h-40"
                    type="primary"
                    size="small"
                    disabled
                    icon={<QuestionCircleFilled />}
                  >
                    {t('toolBar.help')}
                  </Button>
                ) : (
                  <Button
                    className="w-full br-0 h-40"
                    type="primary"
                    size="small"
                    disabled
                    icon={<QuestionCircleFilled />}
                  />
                )
              }
            </MediaQuery>
          </Col>
          <Col xs={3} md={3}>
            <MediaQuery minWidth={768}>
              {(matches) =>
                matches ? (
                  <Button
                    className="w-full br-0 h-40"
                    type="primary"
                    size="small"
                    icon={<FolderAddFilled />}
                    onClick={onAddNewClick}
                  >
                    {t('toolBar.addNew')}
                  </Button>
                ) : (
                  <Button
                    className="w-full br-0 h-40"
                    type="primary"
                    size="small"
                    icon={<FolderAddFilled />}
                    onClick={onAddNewClick}
                  />
                )
              }
            </MediaQuery>
          </Col>
          <Col xs={3} md={3}>
            <MediaQuery minWidth={768}>
              {(matches) =>
                matches ? (
                  <Button
                    className="w-full br-0 h-40"
                    type="primary"
                    size="small"
                    color={'green'}
                    icon={<SaveFilled />}
                  >
                    {t('toolBar.save')}
                  </Button>
                ) : (
                  <Button
                    className="w-full br-0 h-40"
                    type="primary"
                    size="small"
                    color={'green'}
                    icon={<SaveFilled />}
                  />
                )
              }
            </MediaQuery>
          </Col>
          <Col xs={3} md={3}>
            <MediaQuery minWidth={768}>
              {(matches) =>
                matches ? (
                  <Button
                    className="w-full br-0 h-40"
                    type="primary"
                    size="small"
                    color={'green'}
                    icon={<EditFilled />}
                    onClick={onEditClick}
                  >
                    {t('toolBar.edit')}
                  </Button>
                ) : (
                  <Button
                    className="w-full br-0 h-40"
                    type="primary"
                    size="small"
                    color={'green'}
                    icon={<EditFilled />}
                    onClick={onEditClick}
                  />
                )
              }
            </MediaQuery>
          </Col>
          <Col xs={3} md={3}>
            <MediaQuery minWidth={768}>
              {(matches) =>
                matches ? (
                  <Button
                    className="w-full br-0 h-40"
                    type="primary"
                    size="small"
                    color={'green'}
                    disabled
                    icon={<CloudFilled />}
                  >
                    {t('toolBar.publish')}
                  </Button>
                ) : (
                  <Button
                    className="w-full br-0 h-40"
                    type="primary"
                    size="small"
                    color={'green'}
                    disabled
                    icon={<CloudFilled />}
                  />
                )
              }
            </MediaQuery>
          </Col>
          <Col xs={3} md={3}>
            <MediaQuery minWidth={768}>
              {(matches) =>
                matches ? (
                  <Button
                    className="w-full br-0 h-40"
                    type="primary"
                    size="small"
                    color={'green'}
                    icon={<DeleteFilled />}
                    danger
                    onClick={onDelete}
                  >
                    {t('toolBar.delete')}
                  </Button>
                ) : (
                  <Button
                    className="w-full br-0 h-40"
                    type="primary"
                    size="small"
                    color={'green'}
                    icon={<DeleteFilled />}
                    danger
                    onClick={onDelete}
                  />
                )
              }
            </MediaQuery>
          </Col>
          <Col xs={3} md={3}>
            <MediaQuery minWidth={768}>
              {(matches) =>
                matches ? (
                  <Button
                    className="w-full br-0 h-40"
                    type="primary"
                    size="small"
                    color={'green'}
                    icon={<EyeFilled />}
                    onClick={onViewClick}
                  >
                    {t('toolBar.view')}
                  </Button>
                ) : (
                  <Button
                    className="w-full br-0 h-40"
                    type="primary"
                    size="small"
                    color={'green'}
                    icon={<EyeFilled />}
                    onClick={onViewClick}
                  />
                )
              }
            </MediaQuery>
          </Col>
          <Col xs={3} md={3}>
            <MediaQuery minWidth={768}>
              {(matches) =>
                matches ? (
                  <Button
                    className="w-full br-0 h-40"
                    type="primary"
                    size="small"
                    color={'green'}
                    icon={<PrinterFilled />}
                    disabled
                  >
                    {t('toolBar.print')}
                  </Button>
                ) : (
                  <Button
                    className="w-full br-0 h-40"
                    type="primary"
                    size="small"
                    color={'green'}
                    disabled
                    icon={<PrinterFilled />}
                  />
                )
              }
            </MediaQuery>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default TopToolBar;
