import React from 'react';
import * as S from './References.styles';
// import { FacebookOutlined, GithubOutlined, LinkedinOutlined, TwitterOutlined } from '@ant-design/icons';

// const GithubIcon = S.withStyles(GithubOutlined);
// const TwitterIcon = S.withStyles(TwitterOutlined);
// const FacebookIcon = S.withStyles(FacebookOutlined);
// const LinkedinIcon = S.withStyles(LinkedinOutlined);

export const References: React.FC = () => {
  return (
    <S.ReferencesWrapper>
      <S.Text>Made by Tune IN Radio in 2023 &copy;.</S.Text>
    </S.ReferencesWrapper>
  );
};
