import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// import { LoginForm } from '@app/components/auth/LoginForm/LoginForm';
import { LoginForm } from '@app/pages/Login/LoginForm';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { GoogleReCaptchaProvider, useGoogleReCaptcha, GoogleReCaptcha } from 'react-google-recaptcha-v3';

const LoginPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY ? process.env.REACT_APP_RECAPTCHA_KEY : ''}
    >
      <PageTitle>{t('common.login')}</PageTitle>
      <LoginForm />
    </GoogleReCaptchaProvider>
  );
};

export default LoginPage;
