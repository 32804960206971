import axios, { AxiosRequestConfig } from 'axios';
import ApiCallAxio from '@app/api/apiCallAxio';
import APICallMethods from '@app/types/APICallMethods';
import dayjs from 'dayjs';

//Import url from config file
const baseUrl = process.env.REACT_APP_API_BASEURL;
const sublink = process.env.REACT_APP_API_SUBLINK;
const version = process.env.REACT_APP_API_VERSION;

interface listenerData {
  listenerFirstName: string;
  listenerMiddleName: string;
  listenerLastName: string;
  listenerEmail: string;
  listenerNic: string;
  listenerDob: dayjs.Dayjs | null;
  listenerMobile: number;
  listenerCreateBy: number;
  listenerStatus: boolean;
  listenerUpdateBy?: number | null;
  listenerSpamBy?: number | null;
  listenerBlockedBy?: number | null;
  listenerDeleteBy?: number | null;
}

interface UpdatelistenerData {
  listenerId: number;
  listenerFirstName?: string;
  listenerMiddleName?: string;
  listenerLastName?: string;
  listenerEmail?: string;
  listenerNic?: string;
  listenerDob?: dayjs.Dayjs | null;
  listenerMobile?: number;
  listenerCreateBy?: number;
  listenerStatus?: boolean;
  listenerIsdelete?: boolean;
  listenerDeleteBy?: number | null;
  listenerBlockedBy?: number | null;
  listenerUpdateBy?: number | null;
}

const config: AxiosRequestConfig = {
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': location.hostname,
    'Access-Control-Allow-Credentials': 'true',
  },
  withCredentials: true,
};

/**
 * This method is used to add a new listener
 * @param newListenerData
 * @returns
 */
const AddNewListener = async (newListenerData: listenerData) => {
  const url = `${baseUrl}/${sublink}/${version}/listener`;
  const result = await ApiCallAxio({ url: url, method: APICallMethods.POST, data: newListenerData });
  return result;
};

/**
 * This method is used to update the listener by listener ID
 * @param updateListener
 * @returns
 */
const UpdateListener = async (updateListener: UpdatelistenerData) => {
  const url = `${baseUrl}/${sublink}/${version}/listener`;
  const result = await ApiCallAxio({ url: url, method: APICallMethods.POST, data: updateListener });
  return result;
};

/**
 * This method is used to get listener by listenerID
 * @param listenerId
 * @returns
 */
const getListenerByID = async (listenerId: number) => {
  try {
    const url = `${baseUrl}/${sublink}/${version}/listener/single`;
    const params = { listenerId };
    const response = await axios.get(url, { ...config, params });
    const Listener = response.data;
    return Listener;
  } catch (error) {
    console.error('Error fetching user data:', error);
    throw error;
  }
};

/**
 * This method is used to get the listeners by giving page no and page size
 * @param pageNo
 * @param pageSize
 * @returns
 */
const getListeners = async (pageNo: number, pageSize: number) => {
  try {
    const url = `${baseUrl}/${sublink}/${version}/listener`;
    const params = { pageNo, pageSize };
    const response = await axios.get(url, { ...config, params });
    const Listener = response.data;
    return Listener;
  } catch (error) {
    console.error('Error fetching user data:', error);
    throw error;
  }
};

export { AddNewListener, getListenerByID, getListeners, UpdateListener };
