import React, { useEffect, useState, useCallback } from 'react';
import { Space } from 'antd';
import { UserTableRow } from '@app/api/table.api';
import { Table } from '@app/components/common/Table/Table';
import { ColumnsType } from 'antd/es/table';
import { Button } from '@app/components/common/buttons/Button/Button';
import { useTranslation } from 'react-i18next';
import { useMounted } from '@app/hooks/useMounted';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import { getSearchResults } from '../../SearchModal/SearchModalController';
const pageSize = 5;

export const UsersDataTable: React.FC<{ onUsercodeUpdate: (listenerID: any) => void }> = ({ onUsercodeUpdate }) => {
  const [tableData, setTableData] = useState<{ data: UserTableRow[]; currentPage: number; loading: boolean }>({
    data: [],
    currentPage: 0,
    loading: false,
  });

  const { t } = useTranslation();
  const { isMounted } = useMounted();
  const [dataLength, setDataLength] = useState<number>();
  const [userCode, setUsercode] = useState('');


  const fetch = useCallback(
    async (page: number) => {
      setTableData((tableData) => ({ ...tableData, loading: true }));
      try {
        const strMenuCode = 'US001';
        const strSearchControl = 'userData';
        const strSearchKey = 'USS01';
        const strSearchCol = 'USERHED_USERCODE';
        const strSearchVal = '0';
        const strFilter = 'a';
        const OrderBy = 'asc';
        const searchPlace = 1;
        const response = await getSearchResults(
          strMenuCode,
          strSearchControl,
          strSearchKey,
          strSearchCol,
          strSearchVal,
          page * pageSize,
          pageSize,
          strFilter,
          OrderBy,
          searchPlace,
        );
        console.log('respone for user', response);

        if (isMounted.current) {
          // Extract relevant information from the response and format it as ListenerTableRow[]
          const formattedData: UserTableRow[] = response.data.map((user: any) => ({
            key: user.USERHED_ID,
            usercode: user.USERHED_USERCODE,
            groupCode: user.ROLES,
            name: user.FULL_NAME,
          }));
          setDataLength(formattedData.length);
          setTableData({ data: formattedData, currentPage: page, loading: false });
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
      }
    },
    [isMounted, t],
  );

  useEffect(() => {
    fetch(0);
  }, [fetch]);


  const handleViewUsers = (userCode: string) => {
    console.log(' row id', userCode);
    if (!userCode) {
      console.log('handleview', userCode);
    } else {
      setUsercode(userCode);
      onUsercodeUpdate(userCode);
      return userCode;
    }
  };

  const columns: ColumnsType<UserTableRow> = [
    {
      title: t('common.name'),
      dataIndex: 'name',
    },
    {
      title: t('common.usercode'),
      dataIndex: 'usercode',
    },
    {
      title: t('common.group'),
      dataIndex: 'groupCode',
    },
    {
      title: t('tables.actions'),
      dataIndex: 'actions',
      width: '15%',
      render: (text: string, record: { usercode: string; key: number }) => {
        return (
          <Space>
            <Button type="default" onClick={() => handleViewUsers(record.usercode)}>
              {t('common.view')}
            </Button>
          </Space>
        );
      },
    },
  ];

  const handleNextPage = () => {
    fetch(tableData.currentPage + 1);
  };

  const handlePreviousPage = () => {
    if (tableData.currentPage > 0) {
      fetch(tableData.currentPage - 1);
    }
  };

  return (
    <>
      <Table
        columns={columns}
        dataSource={tableData.data}
        loading={tableData.loading}
        scroll={{ x: 800 }}
        bordered
        pagination={false}
      />
      <div className="custom-pagination">
        <Button onClick={handlePreviousPage} disabled={tableData.currentPage + 1 === 1}>
          <LeftOutlined />
        </Button>
        <span className="page-no"> {tableData.currentPage + 1}</span>
        <Button onClick={handleNextPage} disabled={dataLength !== undefined && dataLength < 5}>
          <RightOutlined />
        </Button>
      </div>
    </>
  );
};
