import React, { useState, useEffect } from 'react';
import { ListenerData } from '@app/pages/Utilities/Listeners/ListenerData/ListenersData';
import { ListenerDataTable } from '@app/pages/Utilities/Listeners/ListenerData/ListenerDataTable';
import { useTranslation } from 'react-i18next';
import * as S from '../Listeners/Listeners.style';
import TopToolBar from '@app/framework/layout/TopToolBar';
import { UpdateListener, getListenerByID } from '@app/pages/Utilities/Listeners/ListenerData/ListenerDataController';
import { notificationController } from '@app/controllers/notificationController';
import { SearchModalWithTable } from '../SearchModal/SearchModalWithTable';
import { Modal } from 'antd';
import { DecryptData } from '../Common/DecryptData';
import { SongRequestDataTable } from './SongRequestData/SongRequestDataTable';
import { SongRequestData } from './SongRequestData/SongRequestData';
import { addNewSongRequest } from './SongRequestData/SongRequestController';

export const SongRequests = () => {
  const { t } = useTranslation();
  const [showSongRequestData, setShowSongRequestData] = useState(true);
  const [showSongRequestDataTable, setShowSongRequestDataTable] = useState(false);
  const [searchData, setSearchData] = useState<any>(null);
  const [isEditable, setIsEditable] = useState(false);
  const [rowClickedID, setRowClickedId] = useState(0);

  const handleViewClick = () => {
    setShowSongRequestData(false);
    setShowSongRequestDataTable(true);
  };

  const onAddNewClick = () => {
    setShowSongRequestData(true);
    setShowSongRequestDataTable(false);
  };

  const handleRowClickInPage = (clickedRow: any) => {
    // Print the clicked row data immediately in the Page component
    setShowSongRequestData(true);
    setShowSongRequestDataTable(false);
    setRowClickedId(clickedRow.SONG_REQ_ID);
    console.log('Clicked row data in Page component:', clickedRow);
    console.log('clicked row data id', clickedRow.SONG_REQ_ID);

    setSearchData(clickedRow);
  };

  const handleEditListener = () => {
    setShowSongRequestData(true);
    setShowSongRequestDataTable(false);
    setIsEditable(true);
  };

  const userId = sessionStorage.getItem('session2');
  const userIdDecrypted = DecryptData(userId);

  const handleDelete = async () => {
    Modal.confirm({
      title: 'Confirm Delete',
      content: 'Are you sure you want to delete this song?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: async () => {
        const newData = {
          songReqID: rowClickedID,
          songReqIsDeleted: true,
          songReqDeleteBy: userIdDecrypted,
        };
        const deleteSongRequest = await addNewSongRequest(newData);
        console.log('delete user', deleteSongRequest);
        const isSuccess = deleteSongRequest.success;

        if (isSuccess) {
          notificationController.success({
            message: t('notifications.successTitle'),
            description: t('notifications.successSongRequestDeleteDescription'),
          });
          setIsEditable(true);
          setSearchData(null);
        } else {
          notificationController.warning({
            message: t('notifications.warningTitle'),
            description: t('notifications.customWarningDescription'),
          });
        }
      },
      onCancel() {
        //on cancel
      },
    });
  };

  return (
    <>
      <S.TablesWrapper>
        <S.Card id="user-table" title={t('common.songRequests')} padding="1.25rem 1.25rem 0">
          <div className="search-Wrapper">
            <SearchModalWithTable
              FieldName=""
              Placeholder="Song Name"
              strMenuCode={'USR01'}
              strSearchControl={'songRequestSearch'}
              strSearchKey={'USR01'}
              strSearchCol={'SONG_REQ_NAME'}
              strFilter={'a'}
              OrderBy={'asc'}
              searchPlace={1}
              actionAdd={false}
              actionView={true}
              actionEdit={false}
              actionDelete={false}
              onRowClick={handleRowClickInPage}
            />
          </div>
          <TopToolBar
            onViewClick={handleViewClick}
            onAddNewClick={onAddNewClick}
            onEditClick={handleEditListener}
            onDelete={handleDelete}
          />
          {showSongRequestData ? <SongRequestData searchData={searchData} isEditable={isEditable} /> : null}{' '}
          {/* Only render ListenerData when showListenerData is true */}
          {showSongRequestDataTable ? <SongRequestDataTable /> : null}{' '}
          {/* Only render ListenerDataTable when showListenerDataTable is true */}
        </S.Card>
      </S.TablesWrapper>
    </>
  );
};
