import axios, { AxiosRequestConfig } from 'axios';
import ApiCallAxio from '@app/api/apiCallAxio';
import APICallMethods from '@app/types/APICallMethods';

//Import url from config file
const baseUrl = process.env.REACT_APP_API_BASEURL;
const sublink = process.env.REACT_APP_API_SUBLINK;
const version = process.env.REACT_APP_API_VERSION;

const config: AxiosRequestConfig = {
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': location.hostname,
    'Access-Control-Allow-Credentials': 'true',
  },
  withCredentials: true,
};

/**
 * This method is used to get roles
 * @param pageNo
 * @param pageSize
 * @returns
 */
const getRoles = async (pageNo: number, pageSize: number) => {
  try {
    const url = `${baseUrl}/${sublink}/${version}/CommonCodes/roles`;
    const params = { pageNo, pageSize };
    const response = await axios.get(url, { ...config, params });
    const roles = response.data;
    return roles;
  } catch (error) {
    console.error('Error fetching roles data:', error);
    throw error;
  }
};

export { getRoles };
