import React, { useEffect, useState, useCallback } from 'react';
import { Space, TablePaginationConfig } from 'antd';
import { Pagination, ProgramTableRow } from 'api/table.api';
import { Table } from 'components/common/Table/Table';
import { ColumnsType } from 'antd/es/table';
import { Button } from 'components/common/buttons/Button/Button';
import { useTranslation } from 'react-i18next';
import { useMounted } from '@app/hooks/useMounted';
import { format } from 'date-fns';
import { getPrograms } from '@app/pages/Utilities/Programs/ProgramController';
import { Rate } from '@app/components/common/Rate/Rate';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import { getSearchResults } from '../../SearchModal/SearchModalController';

const pageSize = 5;

export const ProgramsDataTable: React.FC<{ onProgramIDUpdate: (prpgramID: any) => void }> = ({ onProgramIDUpdate }) => {
  const [tableData, setTableData] = useState<{ data: ProgramTableRow[]; currentPage: number; loading: boolean }>({
    data: [],
    currentPage: 0,
    loading: false,
  });

  const { t } = useTranslation();
  const { isMounted } = useMounted();
  const [dataLength, setDataLength] = useState<number>();
  const [programID, setProgramID] = useState<any>(null);

  const fetch = useCallback(
    async (page: number) => {
      setTableData((tableData) => ({ ...tableData, loading: true }));
      try {
        const strMenuCode = 'UP001';
        const strSearchControl = 'programData';
        const strSearchKey = 'UPS01';
        const strSearchCol = 'PROGRAM_NAME';
        const strSearchVal = '0';
        const strFilter = 'a';
        const OrderBy = 'asc';
        const searchPlace = 1;
        const response = await getSearchResults(
          strMenuCode,
          strSearchControl,
          strSearchKey,
          strSearchCol,
          strSearchVal,
          page * pageSize,
          pageSize,
          strFilter,
          OrderBy,
          searchPlace,
        );
        if (isMounted.current) {
          // Extract relevant information from the response and format it as ListenerTableRow[]
          const formattedData: ProgramTableRow[] = response.data.map((program: any) => ({
            key: program.PROGRAM_ID,
            name: program.PROGRAM_NAME,
            description: program.PROGRAM_DESCRIPTION,
            type: program.PROGRAM_TYPE,
            dateAndTime: format(new Date(program.PROGRAM_DATE), 'yyyy-MM-dd'),
            creator: program.CREATOR_NAME,
          }));
          setDataLength(formattedData.length);
          setTableData({ data: formattedData, currentPage: page, loading: false });
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
      }
    },
    [isMounted, t],
  );

  useEffect(() => {
    fetch(0);
  }, [fetch]);

  const handleViewRow = (rowId: number) => {
    if (!rowId) {
    } else {
      setProgramID(rowId);
      onProgramIDUpdate(rowId);
      return rowId;
    }
  };

  const columns: ColumnsType<ProgramTableRow> = [
    {
      title: t('common.programName'),
      dataIndex: 'name',
    },
    {
      title: t('common.programDescription'),
      dataIndex: 'description',
    },
    {
      title: t('common.programType'),
      dataIndex: 'type',
    },
    {
      title: t('common.programDate'),
      dataIndex: 'dateAndTime',
    },
    {
      title: t('common.programCreator'),
      dataIndex: 'creator',
    },
    {
      title: t('tables.actions'),
      dataIndex: 'actions',
      width: '15%',
      render: (text: string, record: { name: string; key: number }) => {
        return (
          <Space>
            <Button type="default" onClick={() => handleViewRow(record.key)}>
              {t('common.view')}
            </Button>
          </Space>
        );
      },
    },
  ];

  const handleNextPage = () => {
    fetch(tableData.currentPage + 1);
  };

  const handlePreviousPage = () => {
    if (tableData.currentPage > 0) {
      fetch(tableData.currentPage - 1);
    }
  };

  return (
    <>
      <Table
        columns={columns}
        dataSource={tableData.data}
        loading={tableData.loading}
        scroll={{ x: 800 }}
        bordered
        pagination={false}
      />
      <div className="custom-pagination">
        <Button onClick={handlePreviousPage} disabled={tableData.currentPage + 1 === 1}>
          <LeftOutlined />
        </Button>
        <span className="page-no"> {tableData.currentPage + 1}</span>
        <Button onClick={handleNextPage} disabled={dataLength !== undefined && dataLength < 5}>
          <RightOutlined />
        </Button>
      </div>
    </>
  );
};
