import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import * as S from '@app/components/auth/SecurityCodeForm/SecurityCodeForm.styles';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import {
  getAuth,
  GoogleAuthProvider,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from 'firebase/auth';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import FirebaseConfig from '@app/pages/Utilities/FireBaseAuth/FIrebaseConfig';
import ReactCountryFlag from 'react-country-flag';

interface SecurityCodeFormProps {
  onBack?: () => void;
  onFinish?: () => void;
}

export const SignUpWithPhone: React.FC<SecurityCodeFormProps> = ({ onBack, onFinish }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [recaptchaVerifier, setRecaptchaVerifier] = useState<RecaptchaVerifier | null>(null);

  const location = useLocation();
  const action = (location.state as any)?.searchColumn || '';
  const signInWhere = (location.state as any)?.where || '';
  const navigatetUserCode = (location.state as any)?.navigateStateUserCode || '';
  const navigateListenerId = (location.state as any)?.navigateStateListenerId || '';

  const [phoneNumber, setPhoneNumber] = useState('');

  const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(event.target.value);
    console.log(event.target.value);
  };

  // Initialize Firebase
  const app = initializeApp(FirebaseConfig);
  const auth = getAuth();
  auth.useDeviceLanguage();

  useEffect(() => {
    console.log('where in second', signInWhere);

    const recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
      size: 'invisible',
      callback: (response: any) => {
        console.log('reCAPTCHA response', response);
        // reCAPTCHA solved, allow signInWithPhoneNumber.
      },
    });

    setRecaptchaVerifier(recaptchaVerifier);
  }, []);

  const UserSignInUsingPhone = async () => {
    if (recaptchaVerifier) {
      const countryCode = '+94';
      let phone = '';
      if (phoneNumber.charAt(0) === '0') {
        phone = phoneNumber.slice(1);
      } else {
        phone = phoneNumber;
      }
      const phoneNumberWithContryCode = countryCode + phone;
      console.log('phone nummber', phoneNumberWithContryCode);

      if (phoneNumberWithContryCode.length === 12) {
        console.log('recaptcha verifier', recaptchaVerifier);
        console.log('phone number', phoneNumberWithContryCode);

        signInWithPhoneNumber(auth, phoneNumberWithContryCode, recaptchaVerifier)
          .then((confirmationResult) => {
            (window as any).confirmationResult = confirmationResult;
            console.log(confirmationResult);
            navigate('/auth/otp-code', { state: { action, signInWhere, navigateListenerId, navigatetUserCode } });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY ? process.env.REACT_APP_RECAPTCHA_KEY : ''}
    >
      <Auth.FormWrapper>
        <BaseForm layout="vertical" requiredMark="optional">
          <Auth.BackWrapper>
            <Auth.BackIcon />
            {t('common.back')}
          </Auth.BackWrapper>
          <S.ContentWrapper>
            <Auth.FormTitle>{t('securityCodeForm.sendOtpTitle')}</Auth.FormTitle>
            <S.VerifyEmailDescription>{t('common.verifOTPSent')}</S.VerifyEmailDescription>
            <div className="password-input-container">
              <div className="flag-icon border-flag">
                <ReactCountryFlag svg countryCode="LK" />
              </div>
              <input className="Phone-input" value={phoneNumber} onChange={handlePhoneNumberChange} />
            </div>
            <Button className="w-full mt-40 primary-button" onClick={UserSignInUsingPhone}>
              Send OTP
            </Button>
            <div id="sign-in-button"></div>
            <div id="recaptcha-container"></div>
          </S.ContentWrapper>
        </BaseForm>
      </Auth.FormWrapper>
    </GoogleReCaptchaProvider>
  );
};
