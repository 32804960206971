import React, { useState, useEffect } from 'react';
import { Col, Row } from 'antd';
import { Input, Modal, Table } from 'antd';
import { Button } from '@app/components/common/buttons/Button/Button';
import { useTranslation } from 'react-i18next';
import { Card } from '@app/components/common/Card/Card';
import defaltUserProfilePic from '@app/assets/images/default_user_profile_picture.png';
import { Dropdown } from '@app/components/common/Dropdown/Dropdown';
import { Menu, MenuItem } from '@app/components/common/Menu/Menu';
import { SignInWithComponent } from '@app/pages/Utilities/SignUpWith/SignInWithComponent';
import { AddNewUser } from '@app/pages/Utilities/Users/UserData/UserDataController';
import { SearchOutlined, DownOutlined, CloseCircleFilled, CheckCircleFilled } from '@ant-design/icons';
import { getListenerByID } from '@app/pages/Utilities/Listeners/ListenerData/ListenerDataController';
import { notificationController } from '@app/controllers/notificationController';
import { getUsersByUsercode } from '@app/pages/Utilities/Users/UserData/UserDataController';
import { Pagination, UserSearchUsercodeTableRow, ListenerSearchListenerIDTableRow } from '@app/api/table.api';
import { getRoles } from '@app/pages/Utilities/Roles/RolesData/RoleController';
import {
  ValidationRequestProps
} from '@app/pages/Utilities/Validations/ValidationProps';
import ValidationRuleType from '@app/pages/Utilities/Validations/ValidationRuleType';
import Validations from '@app/pages/Utilities/Validations/Validations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { EditFilled } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import axios, { AxiosRequestConfig } from 'axios';
import { SearchModalWithTable } from '../../SearchModal/SearchModalWithTable';

const initialPagination: Pagination = {
  current: 0,
  pageSize: 16,
};

interface UserrDataProps {
  searchData?: any;
  isEditable?: any;
}

export const UsersData: React.FC<UserrDataProps> = ({ searchData, isEditable }) => {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [isModalVisibleListener, setModalVisibleListener] = useState(false);
  const [isModalVisibleUserCode, setModalVisibleUserCode] = useState(false);
  const [roles, setRoles] = useState<any[]>([]);
  const [selectedRole, setSelectedRole] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [isSearching, setIsSearching] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [defaultRoleText, setDefaultRoleText] = useState<string>('');
  const [viewImage, setViewImage] = useState('');
  const [selectedListenerName, setSelectedListenerName] = useState('');
  const [selectedFile, setSelectedFile] = useState<any>('');
  const [genaratedUuid, setGeneratedUuid] = useState('');
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [confirmPasswordMsg, setConfirmPasswordMsg] = useState('');
  const [userCodeMsg, setUserCodeMsg] = useState('');
  const [listenerMsg, setListenerMsg] = useState('');
  const [roleMsg, setRoleMsg] = useState('');
  const [statusMsg, setStatusMsg] = useState('');
  const [passwordMsg, setPasswordMsg] = useState('');

  interface userDataProp {
    userhedUsercode: string;
    userhedTitle: string;
    userhedPassword: string;
    userhedGroupcode: number;
    userhedSerial: string;
    userhedToken: string;
    userhedListenerid: number;
    confirmPassword: string;
    userhedActiveuser: number;
    userhedPicture: string;
  }

  const [userState, setUserState] = useState<userDataProp>({
    userhedUsercode: '',
    userhedTitle: 'Mx',
    userhedPassword: '',
    userhedGroupcode: 0,
    userhedSerial: '',
    userhedToken: '',
    userhedListenerid: 0,
    confirmPassword: '',
    userhedActiveuser: 0,
    userhedPicture: '',
  });

  const [tableData, setTableData] = useState<{
    data: UserSearchUsercodeTableRow[];
    pagination: Pagination;
    loading: boolean;
  }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });

  const [tableDataListener, setTableDataListener] = useState<{
    data: ListenerSearchListenerIDTableRow[];
    pagination: Pagination;
    loading: boolean;
  }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });

  /**
   * This method is used to add a new user when click on the submit button
   */
  const onSubmit = async () => {
    setLoading(true);
    if (!validateFormData()) {
      return;
    }

    try {
      console.log('user state data inside onsubmit', userState);

      const result = await AddNewUser(userState);
      const isSuccess = result.success;

      //To show success and warning toast based on the result
      if (isSuccess) {
        notificationController.success({
          message: t('notifications.successTitle'),
          description: t('notifications.successUserDescription'),
        });
        clearFormData();
        setSelectedListenerName('');
      } else {
        notificationController.warning({
          message: t('notifications.warningTitle'),
          description: t('notifications.customWarningDescription'),
        });
      }
    } catch (error) {
      setLoading(false);
    }
  };

  /**
   * This method is used to handle the userCode
   * @param e
   */
  const handleUserCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserState((prevState) => ({
      ...prevState,
      userhedUsercode: e.target.value,
    }));
  };

  /**
   * This method is used to handle the password
   * @param e
   */
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserState((prevState) => ({
      ...prevState,
      userhedPassword: e.target.value,
    }));
  };

  /**
   * This method is used to handle the serial
   * @param e
   */
  const handleUserSerialChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserState((prevState) => ({
      ...prevState,
      userhedSerial: e.target.value,
    }));
  };

  /**
   * This method is used to handle the user token
   * @param e
   */
  const handleUserTokenChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserState((prevState) => ({
      ...prevState,
      userhedToken: e.target.value,
    }));
  };


  /**
   * This method is used to handle the confirm password
   * @param e
   */
  const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    const validationRequest: ValidationRequestProps = {
      fieldName: 'Password',
      fieldId: 'passwordInput',
      rules: [
        {
          Type: ValidationRuleType.COMPARE,
          Rule: userState.userhedPassword,
        },
      ],
      required: true,
      value: inputValue,
      fieldType: 'text',
    };

    // Perform the validation
    const validationResponse = Validations(validationRequest);

    setConfirmPasswordMsg(validationResponse.Msg);

    setUserState((prevState) => ({
      ...prevState,
      confirmPassword: e.target.value,
    }));
  };

  /**
   * This method is used to open the modal and show result (Usercode)
   */
  const handleModalVisibleUserCode = () => {
    setModalVisibleUserCode(true);
    handleSearchUsers();
  };
 
  /**
   * This method is used to show the result on the table when search by using usercode
   */
  const handleSearchUsers = async () => {
    try {
      const response = await getUsersByUsercode(userState.userhedUsercode);

      if (response) {
        const formattedData: UserSearchUsercodeTableRow[] = [
          {
            key: response.userhedId,
            usercode: response.userhedUsercode,
            name: `${response.listenerFirstName} ${response.listenerMiddleName} ${response.listenerLastName}`,
            email: response.listenerEmail,
          },
        ];

        const newUserData = {
          userhedUsercode: userState.userhedUsercode,
          userhedTitle: 'Mx',
          userhedPassword: '',
          userhedGroupcode: 0,
          userhedSerial: response.userhedSerial,
          userhedToken: response.userhedToken,
          userhedListenerid: response.userhedListenerid,
          confirmPassword: '',
          userhedActiveuser: response.userhedActiveuser,
        };
        setDefaultRoleText(response.userhedGroupName);
        if (response.userhedActiveuser === 0) {
          setSelectedStatus('Inactive');
        } else if (response.userhedActiveuser === 1) {
          setSelectedStatus('Active');
        } else if (response.userhedActiveuser === 2) {
          setSelectedStatus('Delete');
        } else if (response.userhedActiveuser === 3) {
          setSelectedStatus('Pending');
        } else {
          setSelectedStatus('Select');
        }

        setUserState((prevState) => ({
          ...prevState,
          ...newUserData,
        }));

        setTableData({
          data: formattedData,
          pagination: tableData.pagination,
          loading: false,
        });
      } else {
        setModalVisibleListener(true);
        setTableData({
          data: [],
          pagination: tableData.pagination,
          loading: false,
        });
      }
    } catch (error) {
      setModalVisibleListener(true);
      console.error('Error fetching user data:', error);
      setTableData({
        data: [],
        pagination: tableData.pagination,
        loading: false,
      });
    }
  };

  /**
   * This method is used to handle the role selection from the role dropdown list
   * @param selectedRoleDesc
   * @param selectedRoleId
   */
  const handleRoleSelection = (selectedRoleDesc: string, selectedRoleId: number) => {
    setSelectedRole(selectedRoleDesc);
    setUserState((prevState) => ({
      ...prevState,
      userhedGroupcode: selectedRoleId,
    }));
  };

  const clearFormData = () => {
    const newData = {
      userhedUsercode: '',
      userhedTitle: 'Mx',
      userhedPassword: '',
      userhedGroupcode: 0,
      userhedSerial: '',
      userhedToken: '',
      userhedListenerid: 0,
      confirmPassword: '',
      userhedActiveuser: 0,
      userhedPicture: '',
    };
    setSelectedStatus('Select');
    setDefaultRoleText('Select');
    setSelectedRole('Select');
    setUserState(newData);
  };

  useEffect(() => {
    (async () => {
      if (searchData) {
        const listenerName = await getListenerByID(searchData.userhedListenerid);
        console.log('listener Nmae', listenerName.listener.listenerFirstName);

        setSelectedListenerName(listenerName.listener.listenerFirstName);
      }
    })();
  }, [searchData]);

  useEffect(() => {
    if (!searchData && isEditable === false) {
      setIsSearching(false);
    } else {
      if (searchData === null || searchData.listener === null) {
        setIsSearching(false);
        clearFormData();
      } else {
        const newUserData = {
          userhedUsercode: searchData.userhedUsercode,
          userhedTitle: 'Mx',
          userhedPassword: searchData.userhedPassword,
          userhedGroupcode: searchData.userhedGroupcode,
          userhedSerial: searchData.userhedSerial,
          userhedToken: searchData.userhedToken,
          userhedListenerid: searchData.userhedListenerid,
          confirmPassword: searchData.userhedPassword,
          userhedActiveuser: searchData.userhedActiveuser,
          userhedPicture: searchData.userhedPicture,
        };

        const picture = searchData.userhedPicture;
        const imageUrl = baseUrl + '/Users/' + picture.replace(/\s/g, '%20');
        console.log('image here ', imageUrl);
        if (picture != '') {
          setViewImage(imageUrl);
          console.log('image here', imageUrl);
        }
        setDefaultRoleText(searchData.userhedGroupName);
        if (searchData.userhedActiveuser === 0) {
          setSelectedStatus('Inactive');
        } else if (searchData.userhedActiveuser === 1) {
          setSelectedStatus('Active');
        } else if (searchData.userhedActiveuser === 2) {
          setSelectedStatus('Delete');
        } else if (searchData.userhedActiveuser === 3) {
          setSelectedStatus('Pending');
        } else {
          setSelectedStatus('Select');
        }
        setIsSearching(true);
        setUserState(newUserData);
      }
    }

    if (isEditable === true) {
      setIsSearching(false);
    } else {
    }
    //To load roles to role dropdown when the page load
    handleRolesInDropDown();
  }, [searchData, isEditable]);

  /**
   * This method is used to get the roles from database
   */
  const handleRolesInDropDown = async () => {
    try {
      const response = await getRoles(0, 10);
      if (response && response.roles) {
        setRoles(response.roles);
      }
    } catch (error) {}
  };

  // To show the roles in dropdown options
  const rolesMenu = (
    <Menu>
      {roles.map((role) => (
        <MenuItem key={role.roleId}>
          <Button
            type="link"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => handleRoleSelection(role.roleDesc, role.roleId)}
          >
            {role.roleDesc}
          </Button>
        </MenuItem>
      ))}
    </Menu>
  );

  /**
   * This method is used to handle the status selection the status dropdown list
   * @param selectedStatusValue
   * @param selectedStatusText
   */
  const handleStatusSelection = (selectedStatusValue: number, selectedStatusText: string) => {
    setUserState((prevState) => ({
      ...prevState,
      userhedActiveuser: selectedStatusValue,
    }));

    setSelectedStatus(selectedStatusText);
  };

  const handleKeyDown = async (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      try {
        const response = await getUsersByUsercode(userState.userhedUsercode);
       
        const newUserData = {
          userhedUsercode: userState.userhedUsercode,
          userhedTitle: 'Mx',
          userhedPassword: response.userhedPassword,
          userhedGroupcode: 0,
          userhedSerial: response.userhedSerial,
          userhedToken: response.userhedToken,
          userhedListenerid: response.userhedListenerid,
          confirmPassword: response.userhedPassword,
          userhedActiveuser: response.userhedActiveuser,
        };

        setUserState((prevState) => ({
          ...prevState,
          ...newUserData,
        }));

      } catch (error) {
        handleModalVisibleUserCode();
      }
    }
  };


  const config: AxiosRequestConfig = {
    headers: {
      // 'Content-Type': 'application/json',
      'Content-Type': `multipart/form-data; boundary=---------------------------293582696224464`,
      'Access-Control-Allow-Origin': location.hostname,
      'Access-Control-Allow-Credentials': 'true',
    },
    withCredentials: true,
  };



  const isNullOrBlank = (value: string | null): boolean => value === null || value === '';

  const validateFormData = () => {
    setUserCodeMsg(
      userState.userhedUsercode === null || userState.userhedUsercode === '' ? 'Please enter usercode.' : '',
    );
    setListenerMsg(selectedListenerName === null || selectedListenerName === '' ? 'Please enter listener.' : '');
    setPasswordMsg(
      userState.userhedPassword === null || userState.userhedPassword === '' ? 'Please enter password.' : '',
    );
    setRoleMsg(selectedRole === null || selectedRole === '' ? 'Please select role.' : '');
    setStatusMsg(selectedStatus === null || selectedStatus === '' ? 'Please select status.' : '');

    if (
      confirmPasswordMsg === '' &&
      userCodeMsg === '' &&
      listenerMsg === '' &&
      passwordMsg === '' &&
      roleMsg === '' &&
      statusMsg === '' &&
      (userState.userhedGroupcode != null || userState.userhedGroupcode != '') &&
      (userState.userhedActiveuser != null || userState.userhedActiveuser != '') &&
      (userState.userhedListenerid != null || userState.userhedListenerid != '' || userState.userhedListenerid != 0) &&
      !isNullOrBlank(userState.userhedUsercode) &&
      !isNullOrBlank(userState.userhedPassword)
    ) {
      return true;
    } else {
      return false;
    }
  };

 

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const selectedFile = event.target.files[0];
      setSelectedFile(selectedFile);
      const uuid = uuidv4();
      console.log('generated uuid', uuid);
      const imgType = selectedFile.type;
      const image_type = imgType.split('/')[1];
      const imageFileName = `${uuid}.${image_type}`;
      console.log(imageFileName);
      setGeneratedUuid(uuid);

      setSelectedImage(URL.createObjectURL(selectedFile));

      // Update the programState with the selected file name
      setUserState((prevState) => ({
        ...prevState,
        userhedPicture: imageFileName,
      }));
    }
  };

  //Import url from config file
  const baseUrl = process.env.REACT_APP_API_BASEURL;
  const sublink = process.env.REACT_APP_API_SUBLINK;
  const version = process.env.REACT_APP_API_VERSION;

  const OnFileUpload = async () => {
    console.log('uuid', genaratedUuid);

    // Create an object of formData
    const formData = new FormData();
    formData.append('file from uplaod ', selectedFile);
    formData.append('strFolder', 'Users');
    formData.append('fileName', genaratedUuid);
    const response = await axios.post(`${baseUrl}/${sublink}/${version}/CommonCodes/fileUpload`, formData, config);
    console.log('response', response);
    const isSuccess = response.data.success;
    console.log('success', isSuccess);
    if (isSuccess) {
      console.log('user state', userState);
      const updateUserPic = await AddNewUser(userState);
      console.log('response', updateUserPic);
      const picAddedSuccess = updateUserPic.success;
      if (picAddedSuccess) {
        notificationController.success({
          message: t('notifications.successTitle'),
          description: t('notifications.successProfilePictureDescription'),
        });
        setSelectedImage('');
      } else {
        notificationController.warning({
          message: t('notifications.warningTitle'),
          description: t('notifications.customWarningDescription'),
        });
        setSelectedImage('');
      }
    }
  };

  const onExitFileUplaod = () => {
    setSelectedImage('');
  };

  // To show the status in dropdown options
  const statusMenu = (
    <Menu>
      <MenuItem>
        <Button
          type="link"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => handleStatusSelection(1, 'Active')}
        >
          Active
        </Button>
      </MenuItem>
      <MenuItem>
        <Button
          type="link"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => handleStatusSelection(0, 'Inactive')}
        >
          Inactive
        </Button>
      </MenuItem>
      <MenuItem>
        <Button
          type="link"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => handleStatusSelection(2, 'Delete')}
        >
          Delete
        </Button>
      </MenuItem>
      <MenuItem>
        <Button
          type="link"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => handleStatusSelection(3, 'Pending')}
        >
          Pending
        </Button>
      </MenuItem>
    </Menu>
  );

  const handleRowClickInPage = (clickedRow: any) => {
    const listenerID = parseInt(clickedRow, 10);
    setUserState((prevState) => ({
      ...prevState,
      userhedListenerid: isNaN(listenerID) ? 0 : listenerID,
    }));
    console.log('you selected listener', clickedRow);
  };

  const handleListenerName = (clickedRow: any) => {
    console.log('you selected listener name', clickedRow);
    setSelectedListenerName(clickedRow);
  };

  return (
    <Card>
      <Row gutter={{ xs: 10, md: 15, xl: 30 }}>
        <Col xs={24} md={8}>
          <Row gutter={{ xs: 10, md: 15, xl: 30 }}>
            <Col xs={24} md={24}>
              <div className="icon-center">
                <img
                  src={selectedImage || viewImage || defaltUserProfilePic}
                  alt="default user profile"
                  className="h-full w-90 user-pic"
                />
              </div>
            </Col>
            <Col xs={24} md={24}>
              <div>
                <label htmlFor="files" className="btn">
                  <EditFilled className="icon-center" />
                </label>
                <input id="files" style={{ visibility: 'hidden' }} type="file" onChange={handleFileChange} />
                <div className="img-upload-icon">
                  {selectedImage ? <CheckCircleFilled className="mb-20 icon-size-20" onClick={OnFileUpload} /> : null}
                  {selectedImage ? (
                    <CloseCircleFilled className="mb-20 icon-size-20" onClick={onExitFileUplaod} />
                  ) : null}
                </div>
              </div>
            </Col>
            <Col xs={24} md={24} className="p-10">
              <div className="SignUpWithText">Sign Up With</div>
              <SignInWithComponent
                signIn={false}
                where="users"
                usercode={userState.userhedUsercode}
                listenerID={userState.userhedListenerid}
              />
            </Col>
          </Row>
        </Col>

        <Col xs={24} md={16}>
          <Row gutter={{ xs: 10, md: 15, xl: 30 }}>
            <Col xs={24} md={12} className="mb-20">
              <label>{t('common.usercode')}</label>
              <Input
                placeholder={t('common.usercode')}
                value={userState.userhedUsercode}
                onChange={handleUserCodeChange}
                onKeyDown={handleKeyDown}
                addonAfter={<SearchOutlined onClick={() => handleSearchUsers()} />}
                disabled={isSearching}
              />
              {userCodeMsg ? <p className="validation">{userCodeMsg}</p> : null}
              
            </Col>

            <Col xs={24} md={12} className="mb-20">
              <SearchModalWithTable
                FieldName={t('common.listeners')}
                Placeholder="Listener Name"
                strMenuCode={'UL001'}
                strSearchControl={'listeners'}
                strSearchKey={'ULS01'}
                strSearchCol={'LISTENER_FIRST_NAME'}
                strFilter={'a'}
                OrderBy={'asc'}
                searchPlace={1}
                actionAdd={true}
                actionView={false}
                actionEdit={false}
                actionDelete={false}
                onRowClick={handleRowClickInPage}
                ListenerName={handleListenerName}
              />
              {selectedListenerName ? <label className="color-green"> Listener : {selectedListenerName}</label> : null}
              {listenerMsg ? <p className="validation">{listenerMsg}</p> : null}
            </Col>

            <Col xs={24} md={12} className="mb-20">
              <label>{t('common.userRole')}</label>
              <Dropdown overlay={rolesMenu} className="w-full" disabled={isSearching}>
                <Button>
                  {selectedRole || defaultRoleText || t('common.select')} <DownOutlined />
                </Button>
              </Dropdown>
              {roleMsg ? <p className="validation">{roleMsg}</p> : null}
            </Col>

            <Col xs={24} md={12} className="mb-20">
              <label>{t('common.userSerial')}</label>
              <Input
                placeholder={t('common.userSerial')}
                value={userState.userhedSerial}
                onChange={handleUserSerialChange}
                disabled={isSearching}
              />
            </Col>

            <Col xs={24} md={12} className="mb-20">
              <label>{t('common.userToken')}</label>
              <Input
                placeholder={t('common.userToken')}
                value={userState.userhedToken}
                onChange={handleUserTokenChange}
                disabled={isSearching}
              />
            </Col>

            <Col xs={24} md={12} className="mb-20">
              <label>{t('common.status')}</label>
              <Dropdown overlay={statusMenu} className="w-full" disabled={isSearching}>
                <Button onClick={(e) => e.preventDefault()}>
                  {selectedStatus || t('common.select')} <DownOutlined />
                </Button>
              </Dropdown>
              {statusMsg ? <p className="validation">{statusMsg}</p> : null}
            </Col>

            <Col xs={24} md={12} className="mb-20">
              <label>{t('common.userPassword')}</label>
              <div className="password-input-container">
                <input
                  placeholder={t('common.userPassword')}
                  className={isSearching ? 'user-password-disabled' : 'user-password'}
                  type={showPassword ? 'text' : 'password'}
                  value={userState.userhedPassword}
                  onChange={handlePasswordChange}
                  disabled={isSearching}
                  //To prevent from coping and paste
                  onPaste={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  onCopy={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                />
                <div className="eye-icon" onClick={() => setShowPassword(!showPassword)}>
                  <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                </div>
              </div>
            </Col>

            <Col xs={24} md={12} className="mb-20">
              <label>{t('common.userPasswordConfirm')}</label>
              <div className="password-input-container">
                <input
                  placeholder={t('common.userPasswordConfirm')}
                  className={isSearching ? 'user-password-disabled' : 'user-password'}
                  type={showConfirmPassword ? 'text' : 'password'}
                  value={userState.confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  disabled={isSearching}
                  //To prevent from coping and paste
                  onPaste={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  onCopy={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                />
                <div className="eye-icon" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                  <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} />
                </div>
              </div>
              {confirmPasswordMsg ? <p className="validation">{confirmPasswordMsg}</p> : null}
            </Col>

            <Col xs={24} md={24} className="mb-20 flex-end">
              <Button onClick={onSubmit} disabled={isSearching}>
                Submit
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};
