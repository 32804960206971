import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { Input } from 'antd';
import { Button } from '@app/components/common/buttons/Button/Button';
import { useTranslation } from 'react-i18next';
import { Card } from '@app/components/common/Card/Card';
import { Dropdown } from '@app/components/common/Dropdown/Dropdown';
import { Menu, MenuItem } from '@app/components/common/Menu/Menu';
import { DownOutlined } from '@ant-design/icons';
import dayjs, { Dayjs } from 'dayjs';
import { notificationController } from '@app/controllers/notificationController';
import { DecryptData } from '../../Common/DecryptData';
import { addNewSongRequest } from '@app/pages/Utilities/SongRequests/SongRequestData/SongRequestController';

interface SongRequestProps {
  songReqID?: number;
  songReqName: string;
  songReqArtist: string;
  songReqType: string;
  songReqComment: string;
  songReqStatus?: number;
  songReqCreatedBy?: number;
  songReqUpdateBy?: number;
  songReqIsDeleted?: boolean;
  songReqDeleteBy?: number;
}

interface SongRequestDataProps {
  onClick?: () => void;
  searchData?: any;
  isEditable?: any;
  listenerID?: any;
}

export const SongRequestData: React.FC<SongRequestDataProps> = ({ searchData, isEditable, onClick, listenerID }) => {
  const { t } = useTranslation();
  const [isSearching, setIsSearching] = useState(Boolean);
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);

  const [songNameMsg, setsongNameMsg] = useState('');
  const [songArtistMsg, setSongArtistMsg] = useState('');
  const [songTypeMsg, setSongTypeMsg] = useState('');

  //To get the userID
  const userId = sessionStorage.getItem('session2');
  const userIdDecrypted = DecryptData(userId);

  const { TextArea } = Input;
  const [songRequestState, setSongRequestState] = useState<SongRequestProps>({
    songReqName: '',
    songReqArtist: '',
    songReqType: '',
    songReqComment: '',
    songReqStatus: 0,
    songReqCreatedBy: userIdDecrypted,
    songReqIsDeleted: false,
  });

  const handleSongNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    if (inputValue === '') {
      setsongNameMsg('Please enter song name.');
    } else {
      setsongNameMsg('');
    }

    setSongRequestState((prevState) => ({
      ...prevState,
      songReqName: inputValue,
    }));
  };

  const handleSongArtistChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    if (inputValue === '') {
      setSongArtistMsg('Please enter artist name.');
    } else {
      setSongArtistMsg('');
    }
    setSongRequestState((prevState) => ({
      ...prevState,
      songReqArtist: inputValue,
    }));
  };

  const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSongRequestState((prevState) => ({
      ...prevState,
      songReqComment: e.target.value,
    }));
  };

  const handleStatusChange = (status: string) => {
    setSelectedStatus(status);
    if (status === 'Select') {
      setSongTypeMsg('Please select song type.');
    } else {
      setSongTypeMsg('');
    }
    setSongRequestState((prevState) => ({
      ...prevState,
      songReqType: status,
    }));
  };

  const validateFormData = () => {
    if (
      songRequestState.songReqName !== '' &&
      songRequestState.songReqArtist !== '' &&
      songRequestState.songReqType !== '' &&
      songNameMsg === '' &&
      songTypeMsg === '' &&
      songArtistMsg === ''
    ) {
      return true;
    } else {
      return false;
    }
  };

  const statusMenu = (
    <Menu>
      <MenuItem>
        <Button type="link" onClick={() => handleStatusChange('Original')}>
          Original
        </Button>
      </MenuItem>
      <MenuItem>
        <Button type="link" onClick={() => handleStatusChange('Cover')}>
          Cover
        </Button>
      </MenuItem>
    </Menu>
  );

  const handleSubmit = async () => {
    console.log('song request data', songRequestState);
    if (!validateFormData()) {
      notificationController.warning({
        message: t('notifications.warningTitle'),
        description: t('notifications.customValidationDescription'),
      });
      return;
    }
    try {
      const newSongRequest = await addNewSongRequest(songRequestState);
      console.log('add new song response', newSongRequest);
      const isSuccess = newSongRequest.success;
      if (isSuccess) {
        if (searchData) {
          if (searchData.SONG_REQ_ID > 0) {
            notificationController.success({
              message: t('notifications.successTitle'),
              description: t('notifications.successSongRequestUpdateDescription'),
            });
          } else {
          }
        } else {
          notificationController.success({
            message: t('notifications.successTitle'),
            description: t('notifications.successSongRequestAddDescription'),
          });
        }
        clearFormData();
      } else {
        notificationController.warning({
          message: t('notifications.warningTitle'),
          description: t('notifications.customWarningDescription'),
        });
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const clearFormData = () => {
    const newData = {
      songReqName: '',
      songReqArtist: '',
      songReqType: '',
      songReqComment: '',
    };
    setSongRequestState(newData);
  };

  useEffect(() => {
    console.log('search data', searchData);
    if (searchData) {
      const newData = {
        songReqID: searchData.SONG_REQ_ID,
        songReqName: searchData.SONG_REQ_NAME,
        songReqArtist: searchData.SONG_REQ_ARTIST,
        songReqType: searchData.SONG_REQ_TYPE,
        songReqComment: searchData.SONG_REQ_COMMENT,
        songReqStatus: searchData.SONG_REQ_STATUS,
        songReqUpdateBy: userIdDecrypted,
      };
      setSelectedStatus(searchData.SONG_REQ_TYPE);
      setSongRequestState(newData);
      if (isEditable === true) {
        setIsSearching(false);
      } else {
        setIsSearching(true);
      }
    } else if (searchData === null) {
      const newData = {
        songReqID: 0,
        songReqName: '',
        songReqArtist: '',
        songReqType: '',
        songReqComment: '',
        songReqUpdateBy: 0,
      };
      setSelectedStatus('Select');
      setSongRequestState(newData);
    }
  }, [searchData, isEditable]);

  return (
    <Card>
      <Row gutter={{ xs: 10, md: 15, xl: 30 }} className="flex-center">
        <Col xs={24} md={8} className="mb-20">
          <label>{t('common.songRequestName')}</label>
          <Input
            placeholder={t('common.songRequestName')}
            value={songRequestState.songReqName}
            onChange={handleSongNameChange}
            disabled={isSearching}
          />
          {songNameMsg ? <p className="validation">{songNameMsg}</p> : null}
        </Col>

        <Col xs={24} md={8} className="mb-20">
          <label>{t('common.songRequestArtist')}</label>
          <Input
            placeholder={t('common.songRequestArtist')}
            value={songRequestState.songReqArtist}
            onChange={handleSongArtistChange}
            disabled={isSearching}
          />
          {songArtistMsg ? <p className="validation">{songArtistMsg}</p> : null}
        </Col>

        <Col xs={24} md={8} className="mb-20">
          <label>{t('common.songRequestType')}</label>
          <Dropdown overlay={statusMenu} className="w-full" disabled={isSearching}>
            <Button onClick={(e) => e.preventDefault()}>
              {selectedStatus === null ? 'Select' : selectedStatus}
              <DownOutlined />
            </Button>
          </Dropdown>
          {songTypeMsg ? <p className="validation">{songTypeMsg}</p> : null}
        </Col>

        <Col xs={24} md={24} className="mb-20">
          <label>{t('common.songRequestComment')}</label>
          <TextArea
            rows={4}
            placeholder={t('common.songRequestComment')}
            value={songRequestState.songReqComment}
            onChange={handleCommentChange}
            disabled={isSearching}
          />
        </Col>

        <Col xs={24} md={24} className="mb-20 flex-end">
          <Button onClick={handleSubmit} disabled={isSearching}>
            Submit
          </Button>
        </Col>
      </Row>
    </Card>
  );
};
