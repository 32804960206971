import axios, { AxiosRequestConfig } from 'axios';
import ApiCallAxio from '@app/api/apiCallAxio';
import APICallMethods from '@app/types/APICallMethods';

//Import url from config file
const baseUrl = process.env.REACT_APP_API_BASEURL;
const sublink = process.env.REACT_APP_API_SUBLINK;
const version = process.env.REACT_APP_API_VERSION;

const config: AxiosRequestConfig = {
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': location.hostname,
    'Access-Control-Allow-Credentials': 'true',
  },
  withCredentials: true,
};

/**
 * This method is used to get search results for the dynamic data table
 * @param strMenuCode - menucode from side bar
 * @param strSearchControl - search control (ex: master,sub)
 * @param strSearchKey - unique key
 * @param strSearchCol - search from which column
 * @param strSearchVal - search value
 * @param intPageNo
 * @param intRowPerPage
 * @param strFilter - if there is any additional query
 * @param OrderBy - order by which column
 * @param searchPlace
 * @returns
 */
const getSearchResults = async (
  strMenuCode: string,
  strSearchControl: string,
  strSearchKey: string,
  strSearchCol: string,
  strSearchVal: string,
  intPageNo: number,
  intRowPerPage: number,
  strFilter: string,
  OrderBy: string,
  searchPlace: number,
) => {
  try {
    const url = `${baseUrl}/${sublink}/${version}/CommonCodes/searchDynamic`;
    const params = {
      strMenuCode,
      strSearchControl,
      strSearchKey,
      strSearchCol,
      strSearchVal,
      intPageNo,
      intRowPerPage,
      strFilter,
      OrderBy,
      searchPlace,
    };
    const response = await axios.get(url, { ...config, params });
    const Listener = response.data;
    return Listener;
  } catch (error) {
    console.error('Error fetching user data:', error);
    throw error;
  }
};

/**
 * This method is used to get the columns for the data table
 * @param strMenuCode - menucode from side bar
 * @param strController - search control (ex: master,sub)
 * @returns
 */
const getSearchPara = async (strMenuCode: string, strController: string) => {
  try {
    const url = `${baseUrl}/${sublink}/${version}/CommonCodes/searchPara`;
    const params = { strMenuCode, strController };
    const response = await axios.get(url, { ...config, params });
    const Listener = response.data;
    console.log('search Results', response);
    return Listener;
  } catch (error) {
    console.error('Error fetching user data:', error);
    throw error;
  }
};

export { getSearchResults, getSearchPara };
