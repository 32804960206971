import ApiCallProps from '../types/apiCallProps';
import axios, { AxiosRequestConfig } from 'axios';
import APICallMethods from '../types/APICallMethods';
//import errorToast from "../components/Toast/ErrorToast";
//import {removeSessionCookie} from "./SessionHandler";

const ApiCallAxio = async (props: ApiCallProps) => {
  //eslint-disable-next-line
  let data: any = {};

  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': location.hostname,
      'Access-Control-Allow-Credentials': 'true',
    },
    withCredentials: true,
  };
  switch (props.method) {
    case APICallMethods.POST:
      await axios
        .post(props.url, props.data, config)
        .then((result) => {
          if (result.status == 200) {
            data = result.data;
          } else if (result.status == 401) {
            sessionStorage.clear();
            window.location.href = '/';
            // removeSessionCookie()
          } else if (result.status == 403) {
            //errorToast('you have no permission !')
          } else {
            const msg = result.data.error.detail;
            if (
              msg.includes(
                'was deadlocked on lock resources with another process and has been chosen as the deadlock victim.',
              )
            ) {
              console.log(`error ${props.url}`, result.data.error.detail);
            } else {
              console.log(`error ${props.url}`, result.data.error.detail);
              //errorToast(result.data.error.detail)
            }
          }
          //console.log(result)
        })
        .catch((error) => {
          console.log('error', error);
          if (error.response.status == 401) {
            // window.location.href = '/'
            // removeSessionCookie()
          } else if (error.response.status == 403) {
            //errorToast('you have no permission !')
          } else {
            const msg = error.response.data.error.detail;
            if (
              msg.includes(
                'was deadlocked on lock resources with another process and has been chosen as the deadlock victim.',
              )
            ) {
              console.log(`error ${props.url}`, error.response.data.error.detail);
            } else {
              console.log(`error ${props.url}`, error.response.data.error.detail);
              //errorToast(error.response.data.error.detail)
            }
          }
          data = error;
        });
      break;
    case APICallMethods.GET:
      await axios
        .get(props.url, config)
        .then((result) => {
          if (result.status == 200) {
            data = result.data;
          } else if (result.status == 401) {
            window.location.href = '/';
            //removeSessionCookie()
          } else if (result.status == 403) {
            //errorToast('you have no permission !')
          } else {
            const msg = result.data.error.detail;
            if (
              msg.includes(
                'was deadlocked on lock resources with another process and has been chosen as the deadlock victim.',
              )
            ) {
              console.log(`error ${props.url}`, result.data.error.detail);
            } else {
              console.log(`error ${props.url}`, result.data.error.detail);
              //errorToast(result.data.error.detail)
            }
          }

          //console.log(result)
        })
        .catch((error) => {
          console.log('error', error);
          if (error.response.status == 401) {
            window.location.href = '/';
            //removeSessionCookie()
          } else if (error.response.status == 403) {
            //errorToast('you have no permission !')
          } else {
            const msg = error.response.data.error.detail;
            if (
              msg.includes(
                'was deadlocked on lock resources with another process and has been chosen as the deadlock victim.',
              )
            ) {
              console.log(`error ${props.url}`, error.response.data.error.detail);
            } else {
              console.log(`error ${props.url}`, error.response.data.error.detail);
              //errorToast(error.response.data.error.detail)
            }
          }
          data = error;
        });
      break;
    case APICallMethods.PUT:
      await axios
        .post(props.url, props.data, config)
        .then((result) => {
          if (result.status == 200) {
            data = result.data;
          } else if (result.status == 401) {
            window.location.href = '/';
            //removeSessionCookie()
          } else if (result.status == 403) {
            // errorToast('you have no permission !')
          } else {
            const msg = result.data.error.detail;
            if (
              msg.includes(
                'was deadlocked on lock resources with another process and has been chosen as the deadlock victim.',
              )
            ) {
              console.log(`error ${props.url}`, result.data.error.detail);
            } else {
              // errorToast(result.data.error.detail)
            }
          }
          //console.log(result)
        })
        .catch((error) => {
          console.log('error', error);
          if (error.response.status == 401) {
            window.location.href = '/';
            //removeSessionCookie()
          } else if (error.response.status == 403) {
            // errorToast('you have no permission !')
          } else {
            const msg = error.response.data.error.detail;
            if (
              msg.includes(
                'was deadlocked on lock resources with another process and has been chosen as the deadlock victim.',
              )
            ) {
              console.log(`error ${props.url}`, error.response.data.error.detail);
            } else {
              // errorToast(error.response.data.error.detail)
            }
          }
          data = error;
        });
      break;
  }

  return data;
};

export default ApiCallAxio;
