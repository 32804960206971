import axios, { AxiosRequestConfig } from 'axios';
import ApiCallAxio from '@app/api/apiCallAxio';
import APICallMethods from '@app/types/APICallMethods';
import dayjs from 'dayjs';

//Import url from config file
const baseUrl = process.env.REACT_APP_API_BASEURL;
const sublink = process.env.REACT_APP_API_SUBLINK;
const version = process.env.REACT_APP_API_VERSION;

const config: AxiosRequestConfig = {
  headers: {
    'Content-Type':'application/json',
    'Access-Control-Allow-Origin':location.hostname,
    'Access-Control-Allow-Credentials':'true',
  },
  withCredentials: true,
};

interface ProgramDetail {
  programDetailHostId: number;
  programDetailCreateBy: number;
}

interface programDataProp {
  programId?: number;
  programName?: string;
  programDescription?: string;
  programType?: string;
  programCreator?: number;
  programDate?: dayjs.Dayjs | null;
  programStartTime?: string | null;
  programEndTime?: string | null;
  programCreateBy?: number;
  programIsdeleted?: boolean;
  programDeleteBy?: number;
  programPhotos?: string;
  programDetailsReq?: ProgramDetail[];
}

interface deleteprogramDataProp {
  programDetailId: number;
  programDetailIsdeleted: boolean;
}

/**
 * This method is used to add a new program
 * @param newProgramData
 * @returns
 */
const AddNewProgram = async (newProgramData: programDataProp) => {
  const url = `${baseUrl}/${sublink}/${version}/program`;
  const result = await ApiCallAxio({ url: url, method: APICallMethods.POST, data: newProgramData });
  return result;
};

/**
 * This method is used to get programs by page no and page size
 * @param pageNo
 * @param pageSize
 * @returns
 */
const getPrograms = async (pageNo: number, pageSize: number) => {
  try {
    const url = `${baseUrl}/${sublink}/${version}/program`;
    const params = { pageNo, pageSize };
    const response = await axios.get(url, { ...config, params });
    const Listener = response.data;
    return Listener;
  } catch (error) {
    console.error('Error fetching user data:', error);
    throw error;
  }
};

/**
 * This method is used to get program by listenerID
 * @param programID
 * @returns
 */
const getProgramByID = async (programID: number) => {
  try {
    const url = `${baseUrl}/${sublink}/${version}/program/single`;
    const params = { programID };
    const response = await axios.get(url, { ...config, params });
    const program = response.data;
    return program;
  } catch (error) {
    console.error('Error fetching user data:', error);
    throw error;
  }
};

const getProgramDetailsByProgramId = async (programID: number) => {
  try {
    const url = `${baseUrl}/${sublink}/${version}/program/programDetails`;
    const params = { programID };
    const response = await axios.get(url, { ...config, params });
    const programDetails = response.data;
    return programDetails;
  } catch (error) {
    console.error('Error fetching user data:', error);
    throw error;
  }
};

const DeleteProgramDetails = async (ProgramData: deleteprogramDataProp) => {
  const url = `${baseUrl}/${sublink}/${version}/program/programDetails`;

  const result = await ApiCallAxio({ url: url, method: APICallMethods.POST, data: ProgramData });
  return result;
};

export { AddNewProgram, getPrograms, getProgramByID, getProgramDetailsByProgramId, DeleteProgramDetails };
