import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { WithChildrenProps } from '@app/types/generalTypes';

const RequireAuth: React.FC<WithChildrenProps> = ({ children }) => {
  const token1 = useAppSelector((state) => state.auth.token);
  const token = localStorage.getItem('accessToken');
  const session = sessionStorage.getItem('session2');

  return session ? <>{children}</> : <Navigate to="/auth/login" replace />;
};

export default RequireAuth;
