import React, { useState, useEffect } from 'react';
import HoverImage from '@app/components/auth/LoginForm/HoverImage';
import GoogleGray from '@app/assets/SignInWith/Google-gray.png';
import GoogleColor from '@app/assets/SignInWith/Google-color.png';
import FacebookGray from '@app/assets/SignInWith/Facebook-gray.png';
import FacebookColor from '@app/assets/SignInWith/Facebook-color.png';
import TweeterGray from '@app/assets/SignInWith/Tweeter-gray.png';
import TweeterColor from '@app/assets/SignInWith/Tweeter-color.png';
import GithubGray from '@app/assets/SignInWith/Github-gray.png';
import GithubColor from '@app/assets/SignInWith/Github-color.png';
import AppleGray from '@app/assets/SignInWith/Apple-gray.png';
import AppleColor from '@app/assets/SignInWith/Apple-color.png';
import PhoneGray from '@app/assets/SignInWith/Phone-gray.png';
import PhoneColor from '@app/assets/SignInWith/Phone-color.png';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  onAuthStateChanged,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  GithubAuthProvider,
} from 'firebase/auth';
import { Button, Input, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseConfig from '@app/pages/Utilities/FireBaseAuth/FIrebaseConfig';
import { FacebookAuthProvider, TwitterAuthProvider } from 'firebase/auth';
import { getSearchResults } from '@app/pages/Utilities/SearchModal/SearchModalController';
import { DecryptData } from '@app/pages/Utilities/Common/DecryptData';
import { loginUser } from '@app/components/auth/LoginForm/LoginController';
import { notificationController } from '@app/controllers/notificationController';
import { useTranslation } from 'react-i18next';
import { userData } from '@app/pages/Utilities/Modal/Types/UserDataTypes';
import { AddNewUser } from '../Users/UserData/UserDataController';
import CryptoJS from 'crypto-js';

interface signInProps {
  signIn?: boolean;
  where?: string;
  usercode?: string;
  listenerID?: number;
}

export const SignInWithComponent = (props: signInProps) => {
  const [recaptchaVerifier, setRecaptchaVerifier] = useState<RecaptchaVerifier | null>(null);
  const [OTP, setOTP] = useState('');
  const navigate = useNavigate();
  const [platform, setPlatform] = useState('');
  const [userToken, setUserToken] = useState('');
  const { t } = useTranslation();
  const [encrptedData, setEncrptedData] = useState('');

  //To send as a navigation state
  const [where, setWhere] = useState('');
  const [navigateStateUserCode, setNavigateStateUserCode] = useState('');
  const [navigateStateListenerId, setNavigateStateListenerId] = useState<number>();

  // Initialize Firebase
  const app = initializeApp(FirebaseConfig);
  const analytics = getAnalytics(app);
  const auth = getAuth();
  auth.useDeviceLanguage();
  const googleProvider = new GoogleAuthProvider();
  const githubProvider = new GithubAuthProvider();
  const facebookProvider = new FacebookAuthProvider();
  const TwitterProvider = new TwitterAuthProvider();

  const [userState, setUserState] = useState<userData>({
    userhedUsercode: '',
    userhedPassword: '',
    userhedToken: '',
    userhedListenerid: 0,
    userhedGmailToken: '',
    userhedFbToken: '',
    userhedGithubToken: '',
    userhedTwitterToken: '',
    userhedAppleToken: '',
  });

  const UserSignInUsingPhone = async (searchColumn: any) => {
    navigate('/auth/sign-phone', { state: { searchColumn, where, navigateStateListenerId, navigateStateUserCode } });
  };

  const secretPass = process.env.REACT_APP_ENCRYPTION_SECRET_KEY as string;

  /**
   * This method is used to encrypt the listenerID before store in session storage
   * @param session
   * @returns
   */
  const encryptData = (session: number) => {
    const data = CryptoJS.AES.encrypt(JSON.stringify(session), secretPass).toString();

    setEncrptedData(data);
    return data;
  };

  /**
   * This method is used to check if the token is available in the database and then allow signup with relavant option
   * @param searchColumn
   * @param token
   */
  const userSignIn = async (searchColumn: any, token: any) => {
    const strMenuCode = 'UL001';
    const searchControl = 'signIn';
    const searchKey = 'ULS01';
    const intPageNo = 0;
    const intRowPerPage = 5;
    const strFilter = 'a';
    const OrderBy = 'USERHED_ID';
    const searchPlace = 1;

    const response = await getSearchResults(
      strMenuCode,
      searchControl,
      searchKey,
      searchColumn,
      token,
      intPageNo,
      intRowPerPage,
      strFilter,
      OrderBy,
      searchPlace,
    );
    console.log('token response', response);
    console.log('token success', response.success);
    const isSuccess = response.success;
    const data = response.data.length;
    console.log('token data', data);
    console.log('token response user', response.data[0].FULL_NAME);
    console.log('token response user', response.data[0].USERHED_ID);
    console.log('token response user', response.data[0].USERHED_LISTENERID);
    console.log('token response user', response.data[0].USERHED_USERCODE);

    const listenerIdForUserInfo = response.data[0].USERHED_LISTENERID;
    const UsernameForUserInfo = response.data[0].FULL_NAME;
    const userCodeForUserInfo = response.data[0].USERHED_USERCODE;
    const userIdForUserInfo = response.data[0].USERHED_ID;

    //Encrypt the listenerID and userID
    const sessionListenerID = encryptData(listenerIdForUserInfo);
    const sessionUsername = encryptData(UsernameForUserInfo);
    const sessionUserCode = encryptData(userCodeForUserInfo);
    const sessionUserId = encryptData(userIdForUserInfo);

    if (data > 0) {
      sessionStorage.setItem('session', sessionListenerID);
      sessionStorage.setItem('session1', sessionUsername);
      sessionStorage.setItem('session2', sessionUserId);
      sessionStorage.setItem('session3', sessionUserCode);
      const sessionStartTime = new Date().getTime();
      sessionStorage.setItem('sessionStartTime', sessionStartTime.toString());

      navigate('/');
    } else {
      notificationController.warning({
        message: t('notifications.warningTitle'),
        description: t('notifications.customWarningDescription'),
      });
    }
  };

  /**
   * This method is used to sign in with google
   * @param searchColumn
   */
  const userSignInWithGoogle = async (searchColumn: any) => {
    signInWithPopup(auth, googleProvider)
      .then((result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        if (credential) {
          //use token as gmail token
          const token = credential.accessToken;
          const userUid = result.user.uid;
          console.log('token', token);
          if (searchColumn == 'USERHED_GMAIL_TOKEN') {
            userSignIn(searchColumn, userUid);
          } else if (searchColumn == 'SIGN_UP') {
            setPlatform('google');
            if (userUid) setUserToken(userUid);
          }
        }
      })
      .catch((error) => {
        const errorCode = error.code;
        const message = error.message;
        console.log('error code', errorCode, 'error message', message);
        const email = error.customData.email;
        const credential = GoogleAuthProvider.credentialFromError(error);
      });
  };

  /**
   * This method is used to sign in with Github
   * @param searchColumn
   */
  const userSignInWithGitHub = async (searchColumn: any) => {
    signInWithPopup(auth, githubProvider)
      .then((result) => {
        // This gives you a GitHub Access Token. You can use it to access the GitHub API.
        const credential = GithubAuthProvider.credentialFromResult(result);
        if (credential) {
          const token = credential.accessToken;

          // The signed-in user info.
          const user = result.user;
          console.log('user from github', token);

          //use uid as the github token
          const userUid = user.uid;
          console.log('uid from github', userUid);
          if (searchColumn == 'USERHED_GITHUB_TOKEN') {
            userSignIn(searchColumn, userUid);
          } else if (searchColumn == 'SIGN_UP') {
            setPlatform('github');
            if (userUid) setUserToken(userUid);
          }
        }
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.customData.email;
        const credential = GithubAuthProvider.credentialFromError(error);
      });
  };

  //To get the session data from session storage
  const listenerID = sessionStorage.getItem('session');
  const userID = sessionStorage.getItem('session2');
  const userCode = sessionStorage.getItem('session3');
  const listenerIdDecrypted = DecryptData(listenerID);
  const userIdDecrypted = DecryptData(userID);
  const userCodeDecrypted = DecryptData(userCode);

  const hanldeStoreToken = async () => {
    updateUserState();
  };

  const updateUserState = () => {
    if (platform == 'google') {
      if (props.where === 'users' && props.usercode != null && props.listenerID != null) {
        const newData = {
          userhedUsercode: props.usercode,
          userhedPassword: 'password',
          userhedId: userIdDecrypted,
          userhedListenerid: props.listenerID,
          userhedGmailToken: userToken,
        };
        setUserState(newData);
      } else {
        const newData = {
          userhedUsercode: userCodeDecrypted,
          userhedPassword: 'password',
          userhedId: userIdDecrypted,
          userhedListenerid: listenerIdDecrypted,
          userhedGmailToken: userToken,
        };
        setUserState(newData);
      }
    } else if (platform == 'github') {
      if (props.where === 'users' && props.usercode != null && props.listenerID != null) {
        const newData = {
          userhedUsercode: props.usercode,
          userhedPassword: 'password',
          userhedId: userIdDecrypted,
          userhedListenerid: props.listenerID,
          userhedGithubToken: userToken,
        };
        setUserState(newData);
      } else {
        const newData = {
          userhedUsercode: userCodeDecrypted,
          userhedPassword: 'password',
          userhedId: userIdDecrypted,
          userhedListenerid: listenerIdDecrypted,
          userhedGithubToken: userToken,
        };
        setUserState(newData);
      }
    } else if (platform == 'twitter') {
      if (props.where === 'users' && props.usercode != null && props.listenerID != null) {
        const newData = {
          userhedUsercode: props.usercode,
          userhedPassword: 'password',
          userhedId: userIdDecrypted,
          userhedListenerid: props.listenerID,
          userhedTwitterToken: userToken,
        };
        setUserState(newData);
      } else {
        const newData = {
          userhedUsercode: userCodeDecrypted,
          userhedPassword: 'password',
          userhedId: userIdDecrypted,
          userhedListenerid: listenerIdDecrypted,
          userhedTwitterToken: userToken,
        };
        setUserState(newData);
      }
    }
  };

  /**
   * This method is used to check the user and then store the token to relavant user
   */
  const checkTheUserAndstoreTokens = async () => {
    try {
      let userName = '';
      if (props.where === 'users' && props.usercode != null) {
        userName = props.usercode;
      } else {
        userName = userCodeDecrypted;
      }
      console.log('userstate', userState);
      const result = await AddNewUser(userState);
      const isSuccess = result.success;
      console.log('response', result);

      //To show success and warning toast based on the result
      if (isSuccess) {
        notificationController.success({
          message: t('notifications.successTitle'),
          description: t('notifications.successUserDescription'),
        });
        setUserToken('');
      } else {
        notificationController.warning({
          message: t('notifications.warningTitle'),
          description: t('notifications.customWarningDescription'),
        });
      }
   
    } catch (error) {
      notificationController.warning({
        message: t('notifications.warningTitle'),
        description: t('notifications.customWarningDescription'),
      });
    }
  };

  //Sign in with phone number
  useEffect(() => {
    if (props.where) setWhere(props.where);

    if (props.listenerID) setNavigateStateListenerId(props.listenerID);

    if (props.usercode) setNavigateStateUserCode(props.usercode);

    if (!recaptchaVerifier) {
      setRecaptchaVerifier(
        new RecaptchaVerifier(auth, 'recaptcha-container', {
          size: 'invisible',
          callback: (response: any) => {
            console.log('reCAPTCHA response', response);
            // reCAPTCHA solved, allow signInWithPhoneNumber.
          },
        }),
      );
    }

    console.log('user State in useEffect', userState);
    if (userState.userhedUsercode != '' && userState.userhedPassword != '') {
      if (userToken != '') checkTheUserAndstoreTokens();
    }
  }, [recaptchaVerifier, updateUserState]);

  useEffect(() => {
    hanldeStoreToken();
  }, [userToken]);

  /**
   * This method is used to sign in with Facebook
   */
  const userSignInWithFacebook = async () => {
    signInWithPopup(auth, facebookProvider)
      .then((result) => {
        // The signed-in user info.
        const user = result.user;
        const credential = FacebookAuthProvider.credentialFromResult(result);
        console.log('user', user);
        if (credential) {
          const accessToken = credential.accessToken;
          console.log('facebook', accessToken);
        }
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.customData.email;
        const credential = FacebookAuthProvider.credentialFromError(error);
        console.log('error', error);
      });
  };

  const userSignInWithTwitter = async (searchColumn: any) => {
    signInWithPopup(auth, TwitterProvider)
      .then((result) => {
        // This gives you a the Twitter OAuth 1.0 Access Token and Secret.
        // You can use these server side with your app's credentials to access the Twitter API.
        const credential = TwitterAuthProvider.credentialFromResult(result);
        if (credential) {
          const token = credential.accessToken;
          const secret = credential.secret;
          console.log('twitter', token);
          console.log('twitter', secret);
        }

        // The signed-in user info.
        const userUid = result.user.uid;
        if (searchColumn == 'USERHED_TWITTER_TOKEN') {
          userSignIn(searchColumn, userUid);
        } else if (searchColumn == 'SIGN_UP') {
          setPlatform('twitter');
          if (userUid) setUserToken(userUid);
        }
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.customData.email;
        const credential = TwitterAuthProvider.credentialFromError(error);
        console.log(error);
      });
  };

  return (
    <div className="SignInWithTable">
      <thead>
        <tr>
          <th>
            <HoverImage
              defaultSrc={GoogleGray}
              hoverSrc={GoogleColor}
              alt="Google logo"
              onClick={
                props.signIn ? () => userSignInWithGoogle('USERHED_GMAIL_TOKEN') : () => userSignInWithGoogle('SIGN_UP')
              }
            />
          </th>
          {/* <th>
              <HoverImage defaultSrc={FacebookGray} hoverSrc={FacebookColor} alt="Facebook logo" 
              onClick={userSignInWithFacebook}
              />
            </th> */}
          <th>
            <HoverImage
              defaultSrc={TweeterGray}
              hoverSrc={TweeterColor}
              alt="Twitter logo"
              onClick={
                props.signIn
                  ? () => userSignInWithTwitter('USERHED_TWITTER_TOKEN')
                  : () => userSignInWithTwitter('SIGN_UP')
              }
            />
          </th>
          {/* <th>
              <HoverImage defaultSrc={AppleGray} hoverSrc={AppleColor} alt="Apple logo"/>
            </th> */}
          <th>
            <HoverImage
              defaultSrc={GithubGray}
              hoverSrc={GithubColor}
              alt="Github logo"
              onClick={
                props.signIn
                  ? () => userSignInWithGitHub('USERHED_GITHUB_TOKEN')
                  : () => userSignInWithGitHub('SIGN_UP')
              }
            />
          </th>
          <th>
            <HoverImage
              defaultSrc={PhoneGray}
              hoverSrc={PhoneColor}
              alt="Phone logo"
              onClick={
                props.signIn ? () => UserSignInUsingPhone('USERHED_TOKEN') : () => UserSignInUsingPhone('SIGN_UP')
              }
            />
          </th>
        </tr>
        <div id="sign-in-button"></div>
        <div id="recaptcha-container"></div>
      </thead>
    </div>
  );
};
