import axios, { AxiosRequestConfig } from 'axios';
import ApiCallAxio from '@app/api/apiCallAxio';
import APICallMethods from '@app/types/APICallMethods';
import { getListenerByID } from '@app/pages/Utilities/Listeners/ListenerData/ListenerDataController';
import { changePasswordProp } from '@app/pages/Utilities/Modal/Types/PasswordData';
import { userData } from '@app/pages/Utilities/Modal/Types/UserDataTypes';
import { capitalizeFirstLetter } from '../../Common/CapitalizeFirstLetter';

//Import url from config file
const baseUrl = process.env.REACT_APP_API_BASEURL;
const sublink = process.env.REACT_APP_API_SUBLINK;
const version = process.env.REACT_APP_API_VERSION;

interface ListenerDataProp {
  userhedId: number;
  userhedUsercode: string;
  listenerFirstName: string;
  listenerMiddleName: string;
  listenerLastName: string;
  listenerEmail: string;
  listenerNic: string;
  userhedGroupName: string;
  userhedToken: string;
  userhedSerial: string;
  userhedPassword: string;
  userhedActiveuser: number;
  userhedListenerid: number;
  userhedPicture: string;
  listenerRate?: number;
}

const config: AxiosRequestConfig = {
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': location.hostname,
    'Access-Control-Allow-Credentials': 'true',
  },
  withCredentials: true,
};

const AddNewUser = async (newUserData: userData) => {
  const url = `${baseUrl}/${sublink}/${version}/users`;
  const result = await ApiCallAxio({ url: url, method: APICallMethods.POST, data: newUserData });
  return result;
};

/**
 * This method is used to get users by giving page no and page size
 * @param pageNo
 * @param pageSize
 * @returns
 */
const getUsers = async (pageNo: number, pageSize: number) => {
  try {
    const url = `${baseUrl}/${sublink}/${version}/users`;
    const params = { pageNo, pageSize };
    const response = await axios.get(url, { ...config, params });
    const userData = response.data;
    return userData;
  } catch (error) {
    console.error('Error fetching user data:', error);
    throw error;
  }
};

/**
 * This method is used to get the user by usercode
 * @param usercode
 * @returns
 */
const getUsersByUsercode = async (usercode: string): Promise<ListenerDataProp> => {
  try {
    const url = `${baseUrl}/${sublink}/${version}/users/getsingleuser`;
    const params = { usercode };
    const response = await axios.get(url, { ...config, params });
    const user = response.data;
    const listenerID = response.data.user.userhedListenerid;
    const listenersDataResponse = await getListenerByID(listenerID);

    console.log('listenerresponse', user);

    // Set data to listenerData object
    const newData = {
      userhedId: user.user.userhedId,
      userhedUsercode: usercode,
      listenerFirstName: listenersDataResponse.listener.listenerFirstName,
      listenerMiddleName: listenersDataResponse.listener.listenerMiddleName,
      listenerLastName: listenersDataResponse.listener.listenerLastName,
      listenerEmail: listenersDataResponse.listener.listenerEmail,
      listenerNic: listenersDataResponse.listener.listenerNic,
      userhedGroupName: user.user.userhedGroupName,
      userhedToken: user.user.userhedToken,
      userhedSerial: user.user.userhedSerial,
      userhedPassword: user.user.userhedPassword,
      userhedActiveuser: user.user.userhedActiveuser,
      userhedListenerid: user.user.userhedListenerid,
      userhedPicture: user.user.userhedPicture,
    };

    console.log('new data', newData);

    return newData;
  } catch (error) {
    console.error('Error fetching user data:', error);
    throw error;
  }
};

const getUsersByUserId = async (userId: number): Promise<ListenerDataProp> => {
  try {
    const url = `${baseUrl}/${sublink}/${version}/users/singleuser`;
    const params = { userId };
    const response = await axios.get(url, { ...config, params });
    const user = response.data;
    const userCode = await getUsersByUsercode(user.user.userhedUsercode);
    console.log('user code data', userCode.userhedGroupName);
    const userRole = capitalizeFirstLetter(userCode.userhedGroupName);
    const listenerID = response.data.user.userhedListenerid;
    const listenersDataResponse = await getListenerByID(listenerID);

    console.log('user response', user);
    console.log('listener response', listenersDataResponse);

    // Set data to listenerData object
    const newData = {
      userhedId: userId,
      userhedUsercode: user.user.userhedUsercode,
      listenerFirstName: listenersDataResponse.listener.listenerFirstName,
      listenerMiddleName: listenersDataResponse.listener.listenerMiddleName,
      listenerLastName: listenersDataResponse.listener.listenerLastName,
      listenerEmail: listenersDataResponse.listener.listenerEmail,
      listenerNic: listenersDataResponse.listener.listenerNic,
      userhedGroupName: userRole,
      userhedToken: user.user.userhedToken,
      userhedSerial: user.user.userhedSerial,
      userhedPassword: user.user.userhedPassword,
      userhedActiveuser: user.user.userhedActiveuser,
      userhedListenerid: user.user.userhedListenerid,
      userhedPicture: user.user.userhedPicture,
      listenerRate: listenersDataResponse.listener.listenerRate,
    };

    console.log('new data', newData);

    return newData;
  } catch (error) {
    console.error('Error fetching user data:', error);
    throw error;
  }
};

/**
 * This method is used to change the password
 * @param newListenerData
 * @returns
 */
const changePassword = async (userPassword: changePasswordProp) => {
  const url = `${baseUrl}/${sublink}/${version}/users`;
  const data = JSON.stringify({
    userhedId: userPassword['userId'],
    userhedUsercode: userPassword['userCode'],
    userhedPassword: userPassword['newPassword'],
    userhedListenerid: userPassword['listenerId'],
  });
  console.log('data', userPassword);
  const result = await ApiCallAxio({ url: url, method: APICallMethods.POST, data: data });
  return result;
};

export { AddNewUser, getUsers, getUsersByUsercode, changePassword, getUsersByUserId };
