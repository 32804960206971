import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { Input } from 'antd';
import { Button } from '@app/components/common/buttons/Button/Button';
import { useTranslation } from 'react-i18next';
import { Card } from '@app/components/common/Card/Card';
import { Dropdown } from '@app/components/common/Dropdown/Dropdown';
import { Menu, MenuItem } from '@app/components/common/Menu/Menu';
import { DownOutlined } from '@ant-design/icons';
import { DayjsDatePicker } from '@app/components/common/pickers/DayjsDatePicker';
import type { DatePickerProps } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { AddNewListener } from '@app/pages/Utilities/Listeners/ListenerData/ListenerDataController';
import { notificationController } from '@app/controllers/notificationController';
import { ValidationRequestProps } from '@app/pages/Utilities/Validations/ValidationProps';
import ValidationRuleType from '@app/pages/Utilities/Validations/ValidationRuleType';
import Validations from '@app/pages/Utilities/Validations/Validations';
import { DecryptData } from '../../Common/DecryptData';

interface ListenersProp {
  listenerFirstName: string;
  listenerMiddleName: string;
  listenerLastName: string;
  listenerEmail: string;
  listenerNic: string;
  listenerDob: dayjs.Dayjs | null;
  listenerGroup: number;
  listenerMobile: number;
  listenerCreateBy: number;
  listenerStatus: boolean;
  listenerUpdateBy?: number | null;
  listenerSpamBy?: number | null;
  listenerBlockedBy?: number | null;
  listenerDeleteBy?: number | null;
}

interface ListenerDataProps {
  onClick?: () => void;
  searchData?: any;
  isEditable?: any;
  listenerID?: any;
}

export const ListenerData: React.FC<ListenerDataProps> = ({ searchData, isEditable, onClick, listenerID }) => {
  const { t } = useTranslation();
  const [isSearching, setIsSearching] = useState(Boolean);
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);

  const [listenerFirstNameMsg, setListenerFirstNameMsg] = useState('');
  const [listenerMiddleNameMsg, setListenerMiddleNameMsg] = useState('');
  const [listenerLastNameMsg, setListenerLastNameMsg] = useState('');
  const [listenerEmailMsg, setListenerEmailMsg] = useState('');
  const [listenerNicMsg, setListenerNicMsg] = useState('');
  const [listenerDobMsg, setListenerDobMsg] = useState('');
  const [listenerGroupMsg, setListenerGroupMsg] = useState('');
  const [listenerMobileMsg, setListenerMobileMsg] = useState('');
  const [listenerStatusMsg, setListenerStatusMsg] = useState('');
  const [allowSubmit, setAllowSubmt] = useState(false);

  //To get the userID
  const userId = sessionStorage.getItem('session2');
  const userIdDecrypted = DecryptData(userId);

  const [listenerState, setListenerState] = useState<ListenersProp>({
    listenerFirstName: '',
    listenerMiddleName: '',
    listenerLastName: '',
    listenerEmail: '',
    listenerNic: '',
    listenerDob: null,
    listenerGroup: 0,
    listenerMobile: 0,
    listenerCreateBy: userIdDecrypted,
    listenerStatus: false,
    listenerUpdateBy: null,
    listenerSpamBy: null,
    listenerBlockedBy: null,
    listenerDeleteBy: null,
  });

  const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    const validationRequest: ValidationRequestProps = {
      fieldName: 'First Name',
      fieldId: 'firstNameInput',
      rules: [
        {
          Type: ValidationRuleType.REGEX,
          Rule: '^[a-zA-Z]+$',
        },
      ],
      required: true,
      value: inputValue,
      fieldType: 'text',
    };

    // Perform the validation
    const validationResponse = Validations(validationRequest);

    setListenerFirstNameMsg(validationResponse.Msg);

    // Update the state and handle validation message
    setListenerState((prevState) => ({
      ...prevState,
      listenerFirstName: inputValue,
    }));
  };

  /**
   * This method is used to handle the middle name change
   * @param e
   */
  const handleMiddleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    const validationRequest: ValidationRequestProps = {
      fieldName: 'Middle Name',
      fieldId: 'middleNameInput',
      rules: [
        {
          Type: ValidationRuleType.REGEX,
          Rule: '^[a-zA-Z]+$',
        },
      ],
      required: false,
      value: inputValue,
      fieldType: 'text',
    };

    // Perform the validation
    const validationResponse = Validations(validationRequest);

    setListenerMiddleNameMsg(validationResponse.Msg);

    setListenerState((prevState) => ({
      ...prevState,
      listenerMiddleName: inputValue,
    }));
  };

  /**
   * This method is used to last name the Email change
   * @param e
   */
  const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    const validationRequest: ValidationRequestProps = {
      fieldName: 'Last Name',
      fieldId: 'lastNameInput',
      rules: [
        {
          Type: ValidationRuleType.REGEX,
          Rule: '^[a-zA-Z]+$',
        },
      ],
      required: true,
      value: inputValue,
      fieldType: 'text',
    };

    // Perform the validation
    const validationResponse = Validations(validationRequest);
    setListenerLastNameMsg(validationResponse.Msg);

    setListenerState((prevState) => ({
      ...prevState,
      listenerLastName: inputValue,
    }));
  };

  /**
   * This method is used to handle the Email change
   * @param e
   */
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    const validationRequest: ValidationRequestProps = {
      fieldName: 'Email',
      fieldId: 'emailInput',
      rules: [
        {
          Type: ValidationRuleType.REGEX,
          Rule: '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$',
        },
      ],
      required: true,
      value: inputValue,
      fieldType: 'text',
    };

    // Perform the validation
    const validationResponse = Validations(validationRequest);
    setListenerEmailMsg(validationResponse.Msg);

    setListenerState((prevState) => ({
      ...prevState,
      listenerEmail: inputValue,
    }));
  };

  /**
   * This method is used to handle the NIC change
   * @param e
   */
  const handleNicChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    const validationRequest: ValidationRequestProps = {
      fieldName: 'NIC',
      fieldId: 'nicInput',
      rules: [
        {
          Type: ValidationRuleType.REGEX,
          Rule: '^(?:[0-9]{12}|[0-9]{9}[Vv])$',
        },
      ],
      required: true,
      value: inputValue,
      fieldType: 'text',
    };

    // Perform the validation
    const validationResponse = Validations(validationRequest);
    setListenerNicMsg(validationResponse.Msg);

    setListenerState((prevState) => ({
      ...prevState,
      listenerNic: inputValue,
    }));
  };

  const handleDOBChange = (newDate: any) => {
    setListenerState({
      ...listenerState,
      listenerDob: newDate,
    });
  };

  const onChange: DatePickerProps['onChange'] = (date, dateString) => {
    console.log(date, dateString);
  };

  /**
   * This method is used to handle the mobile number change
   * @param e
   */
  const handleMobileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    const validationRequest: ValidationRequestProps = {
      fieldName: 'Phone Number',
      fieldId: 'phoneNumberInput',
      rules: [
        {
          Type: ValidationRuleType.REGEX,
          Rule: '^[0-9]{9}$',
        },
      ],
      required: true,
      value: inputValue,
      fieldType: 'text',
    };

    // Perform the validation
    const validationResponse = Validations(validationRequest);
    setListenerMobileMsg(validationResponse.Msg);

    const mobileNumber = parseInt(inputValue, 10);
    setListenerState((prevState) => ({
      ...prevState,
      listenerMobile: isNaN(mobileNumber) ? 0 : mobileNumber,
    }));
  };

  /**
   * This method is used to handle the Group (ex: Free, Premium) change
   * @param e
   */
  const handleGroupChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    const validationRequest: ValidationRequestProps = {
      fieldName: 'Group code',
      fieldId: 'groupCodeInput',
      rules: [],
      required: true,
      value: inputValue,
      fieldType: 'text',
    };

    // Perform the validation
    const validationResponse = Validations(validationRequest);
    setListenerGroupMsg(validationResponse.Msg);

    const group = parseInt(inputValue, 10);
    setListenerState((prevState) => ({
      ...prevState,
      listenerGroup: isNaN(group) ? 0 : group,
    }));
  };

  const handleStatusChange = (status: string) => {
    setSelectedStatus(status);

    if (status === 'Active') {
      setListenerState((prevState) => ({
        ...prevState,
        listenerStatus: true,
      }));
    } else if (status === 'Inactive') {
      setListenerState((prevState) => ({
        ...prevState,
        listenerStatus: false,
      }));
    }
  };

  /**
   * This method is used to clear the form data
   */
  const clearFormData = () => {
    const newData = {
      listenerFirstName: '',
      listenerMiddleName: '',
      listenerLastName: '',
      listenerEmail: '',
      listenerNic: '',
      listenerDob: null,
      listenerGroup: 0,
      listenerMobile: 0,
      listenerCreateBy: userIdDecrypted,
      listenerStatus: false,
    };
    setIsSearching(false);
    setSelectedStatus(null);
    setListenerState(newData);
  };

  const isNullOrBlank = (value: string | null): boolean => value === null || value === '';

  const validateFormData = () => {
    setListenerDobMsg(
      listenerState.listenerDob !== null || searchData.listener.listenerDob !== null
        ? ''
        : 'Please select date of birth.',
    );
    setListenerStatusMsg(
      listenerState.listenerStatus === null || selectedStatus === '' || selectedStatus === 'Select'
        ? 'Please select status.'
        : '',
    );

    if (
      listenerFirstNameMsg === '' &&
      listenerMiddleNameMsg === '' &&
      listenerLastNameMsg === '' &&
      listenerDobMsg === '' &&
      listenerEmailMsg === '' &&
      listenerMobileMsg === '' &&
      listenerNicMsg === '' &&
      listenerStatusMsg === '' &&
      (listenerState.listenerDob != null || listenerState.listenerDob != '') &&
      (listenerState.listenerMobile != null || listenerState.listenerMobile != '') &&
      (listenerState.listenerStatus != null || listenerState.listenerStatus != '') &&
      !isNullOrBlank(listenerState.listenerFirstName) &&
      !isNullOrBlank(listenerState.listenerLastName) &&
      !isNullOrBlank(listenerState.listenerEmail) &&
      !isNullOrBlank(listenerState.listenerNic)
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (searchData != null && allowSubmit === true) {
      (window as any).allowSubmitForm = true;
    }
    console.log('user Id', userIdDecrypted);
  }, [allowSubmit]);

  /**
   * This method is used to add and update new listeners
   */
  const handleSubmit = async () => {
    console.log('listenerData', listenerState);

    if (!validateFormData()) {
      return;
    }
    //To add new listeners
    const result = await AddNewListener(listenerState);
    const isSuccess = result.success;

    //To show success and warning toast based on the result
    if (isSuccess) {
      notificationController.success({
        message: t('notifications.successTitle'),
        description: t('notifications.successListenerDescription'),
      });
      //To clear the form submitting the data
      clearFormData();
    } else {
      notificationController.warning({
        message: t('notifications.warningTitle'),
        description: t('notifications.customWarningDescription'),
      });
    }
    
  };

  //To prevent selecting a date before today
  const isDateDisabled = (date: Dayjs | null) => {
    if (date) {
      const minDate = dayjs().subtract(18, 'years').startOf('day');
      return date.isAfter(minDate);
    }
    return false;
  };

  useEffect(() => {
    const elementId = 'dateOfBirth';
    const element = document.getElementById(elementId);

    //If user didnot search for a listener by id the fields will be empty
    //Else if the user searched for a listener by id the data will show in the fields
    if (listenerID) {
    }

    if (!searchData) {
      clearFormData();
    } else {
      const dateOfBirth = dayjs(searchData.listener.listenerDob).format('YYYY-MM-DD');
      if (searchData === null || searchData.listener === null) {
        clearFormData();
      } else {
        //Load the result data to the fields in form if there is a listener related to search query
        const dateOfBirth = dayjs(searchData.listener.listenerDob).format('YYYY-MM-DD');
        let status = '';
        let statusCode;
        let group = '';
      
        if (searchData.listener.listenerGroup === 0) {
          group = 'Free Plan';
        } else if (searchData.listener.listenerGroup === 1) {
          group = 'Premium Plan';
        } else {
        }
        const newData = {
          listenerId: searchData.listener.listenerId,
          listenerFirstName: searchData.listener.listenerFirstName,
          listenerMiddleName: searchData.listener.listenerMiddleName,
          listenerLastName: searchData.listener.listenerLastName,
          listenerEmail: searchData.listener.listenerEmail,
          listenerNic: searchData.listener.listenerNic,
          listenerDob: dayjs(dateOfBirth),
          listenerGroup: searchData.listener.listenerGroup,
          listenerMobile: searchData.listener.listenerMobile,
          listenerCreateBy: searchData.listener.listenerCreateBy,
          listenerStatus: searchData.listener.listenerStatus,
        };
        if (searchData.listener.listenerStatus) {
          setSelectedStatus('Active');
          handleStatusChange('Active');
          status = 'Active';
          statusCode = true;
        } else {
          setSelectedStatus('Inactive');
          handleStatusChange('Inactive');
          status = 'Inactive';
          statusCode = false;
        }
        //Only allow to edit the fields if "EDIT" button is clicked
        if (isEditable === true) {
          setIsSearching(false);
        } else {
          setIsSearching(true);
        }

        setListenerState(newData);
      }
    }
  }, [searchData, isEditable, listenerID]);

  const statusMenu = (
    <Menu>
      <MenuItem>
        <Button type="link" onClick={() => handleStatusChange('Active')}>
          Active
        </Button>
      </MenuItem>
      <MenuItem>
        <Button type="link" onClick={() => handleStatusChange('Inactive')}>
          Inactive
        </Button>
      </MenuItem>
    </Menu>
  );

  return (
    <Card>
      <Row gutter={{ xs: 10, md: 15, xl: 30 }} className="flex-center">

        <Col xs={24} md={24}>
          <Row gutter={{ xs: 10, md: 15, xl: 30 }}>
            <Col xs={24} md={8} className="mb-20">
              <label>{t('common.name')}</label>
              <Input
                placeholder={t('common.firstName')}
                value={listenerState.listenerFirstName}
                onChange={handleFirstNameChange}
                disabled={isSearching}
              />
              {listenerFirstNameMsg ? <p className="validation">{listenerFirstNameMsg}</p> : null}
            </Col>

            <Col xs={24} md={8} className="mb-20">
              <label className="transparent">-</label>
              <Input
                placeholder={t('common.middleName')}
                value={listenerState.listenerMiddleName}
                onChange={handleMiddleNameChange}
                disabled={isSearching}
              />
              {listenerMiddleNameMsg ? <p className="validation">{listenerMiddleNameMsg}</p> : null}
            </Col>

            <Col xs={24} md={8} className="mb-20">
              <label className="transparent">-</label>
              <Input
                placeholder={t('common.lastName')}
                value={listenerState.listenerLastName}
                onChange={handleLastNameChange}
                disabled={isSearching}
              />
              {listenerLastNameMsg ? <p className="validation">{listenerLastNameMsg}</p> : null}
            </Col>

            <Col xs={24} md={12} className="mb-20">
              <div className="flex-Row">
                <label>{t('common.birthday')}</label>
                <DayjsDatePicker
                  id="dateOfBirth"
                  disabledDate={isDateDisabled}
                  value={listenerState.listenerDob}
                  onChange={handleDOBChange}
                  disabled={isSearching}
                  placeholder="Select Date"
                />
                {listenerDobMsg ? <p className="validation">{listenerDobMsg}</p> : null}
              </div>
            </Col>

            <Col xs={24} md={12} className="mb-20">
              <label>{t('common.email')}</label>
              <Input
                placeholder={t('common.email')}
                value={listenerState.listenerEmail}
                onChange={handleEmailChange}
                disabled={isSearching}
              />
              {listenerEmailMsg ? <p className="validation">{listenerEmailMsg}</p> : null}
            </Col>

            <Col xs={24} md={12} className="mb-20">
              <label>{t('common.contactNumber')}</label>
              <Input
                placeholder={t('common.contactNumber')}
                value={listenerState.listenerMobile === 0 ? '' : listenerState.listenerMobile.toString()}
                onChange={handleMobileChange}
                disabled={isSearching}
              />
              {listenerMobileMsg ? <p className="validation">{listenerMobileMsg}</p> : null}
            </Col>

            <Col xs={24} md={12} className="mb-20">
              <label>{t('common.nic')}</label>
              <Input
                placeholder={t('common.nic')}
                value={listenerState.listenerNic}
                onChange={handleNicChange}
                disabled={isSearching}
              />
              {listenerNicMsg ? <p className="validation">{listenerNicMsg}</p> : null}
            </Col>

            <Col xs={24} md={12} className="mb-20">
              <label>{t('common.status')}</label>
              <Dropdown overlay={statusMenu} className="w-full" disabled={isSearching}>
                <Button onClick={(e) => e.preventDefault()}>
                  {selectedStatus === null ? 'Select' : selectedStatus}
                  <DownOutlined />
                </Button>
              </Dropdown>
              {listenerStatusMsg ? <p className="validation">{listenerStatusMsg}</p> : null}
            </Col>

            <Col xs={24} md={24} className="mb-20 flex-end">
              <Button onClick={handleSubmit} disabled={isSearching}>
                Submit
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};
