import React, { useState, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { SignInWithComponent } from '@app/pages/Utilities/SignUpWith/SignInWithComponent';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import tuneInLogo from '@app/assets/logo/TuneIn_logo.png';
import { loginUser } from '@app/components/auth/LoginForm/LoginController';
import { reCaptchaTokenVerify } from '@app/pages/Utilities/FireBaseAuth/ReCaptcha';
import { Input, Row, Col } from 'antd';
import CryptoJS from 'crypto-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

interface LoginFormData {
  username: string;
  password: string;
}

export const LoginForm: React.FC = () => {
  const [encrptedData, setEncrptedData] = useState('');
  const [decrptedData, setDecrptedData] = useState('');
  const [listenerID, setListenerID] = useState(0);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [token, setToken] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const [loginFormState, setLoginFormState] = useState<LoginFormData>({
    username: '',
    password: '',
  });

  const secretPass = process.env.REACT_APP_ENCRYPTION_SECRET_KEY as string;

  /**
   * This method is used to encrypt the listenerID before store in session storage
   * @param session
   * @returns
   */
  const encryptData = (session: number) => {
    const data = CryptoJS.AES.encrypt(JSON.stringify(session), secretPass).toString();

    setEncrptedData(data);
    return data;
  };

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoginFormState((prevState) => ({
      ...prevState,
      username: e.target.value,
    }));
  };

  const handlePasswordChanage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoginFormState((prevState) => ({
      ...prevState,
      password: e.target.value,
    }));
  };

  const onSubmit = async () => {
    try {
      const result = await loginUser(loginFormState.username, loginFormState.password);
      const listenerIdForUserInfo = result.user.listenerId;
      const UsernameForUserInfo = result.user.userName;
      const userCodeForUserInfo = result.user.userCode;
      const userIdForUserInfo = result.user.userId;
      setListenerID(listenerIdForUserInfo);

      //Encrypt the listenerID and userID
      const sessionListenerID = encryptData(listenerIdForUserInfo);
      const sessionUsername = encryptData(UsernameForUserInfo);
      const sessionUserCode = encryptData(userCodeForUserInfo);
      const sessionUserId = encryptData(userIdForUserInfo);

      sessionStorage.setItem('session', sessionListenerID);
      sessionStorage.setItem('session1', sessionUsername);
      sessionStorage.setItem('session2', sessionUserId);
      sessionStorage.setItem('session3', sessionUserCode);
      const sessionStartTime = new Date().getTime();
      sessionStorage.setItem('sessionStartTime', sessionStartTime.toString());

      const isSuccess = result.success;
      if (isSuccess) {
        reCaptchaVerify();
      } else {
        setIsValid(false);
      }
    } catch (error) {
      setIsValid(false);
    }
  };

  const reCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }
    const token = await executeRecaptcha('Login');
    if (token) {
      const response = await reCaptchaTokenVerify(token);
      if (response) {
        navigate('/');
      } else {
        console.log('There is something wrong with recaptcha');
      }
    }
  }, [executeRecaptcha]);

  return (
    <Auth.FormWrapper style={{ overflowX: 'hidden' }}>
      <div>
        <div className="LogoStyle">
          <img src={tuneInLogo} alt="logo" />
        </div>
        {isValid ? null : <label className="invalid-message-error">{t('common.invalidLogin')}</label>}

        <Row gutter={{ xs: 10, md: 15, xl: 30 }}>
          <Col xs={24} md={24} className="mb-20">
            <label className="loginLabel">{t('common.username')}</label>
            <Input
              placeholder={t('common.username')}
              className="loginInput"
              value={loginFormState.username}
              onChange={handleUsernameChange}
            />
          </Col>
          <Col xs={24} md={24} className="mb-20">
            <label className="loginLabel">{t('common.password')}</label>
            <div className="password-input-container">
              <input
                placeholder={t('common.password')}
                className="loginInput-password"
                type={showPassword ? 'text' : 'password'}
                value={loginFormState.password}
                onChange={handlePasswordChanage}
              />
              <div className="eye-icon" onClick={() => setShowPassword(!showPassword)}>
                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
              </div>
            </div>
          </Col>
        </Row>
        <Auth.ActionsWrapper>
          <BaseForm.Item name="rememberMe" valuePropName="checked" noStyle>
            <Auth.FormCheckbox>
              <div className="RememberMeText">{t('login.rememberMe')}</div>
            </Auth.FormCheckbox>
          </BaseForm.Item>
          
        </Auth.ActionsWrapper>
        <BaseForm.Item noStyle>
          <Auth.SubmitButton type="primary" htmlType="submit" onClick={onSubmit}>
            {t('common.login')}
          </Auth.SubmitButton>
        </BaseForm.Item>
        <div className="LoginWithText">Or login using</div>
        <BaseForm.Item>
          <div id="sign-in-button"></div>
          <div id="recaptcha-container"></div>
          <SignInWithComponent signIn={true} />
        </BaseForm.Item>
      </div>
    </Auth.FormWrapper>
  );
};
