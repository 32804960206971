import { AxiosRequestConfig } from 'axios';
import ApiCallAxio from '@app/api/apiCallAxio';
import APICallMethods from '@app/types/APICallMethods';

//Import url from config file
const baseUrl = process.env.REACT_APP_API_BASEURL;
const sublink = process.env.REACT_APP_API_SUBLINK;
const version = process.env.REACT_APP_API_VERSION;

const config: AxiosRequestConfig = {
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': location.hostname,
    'Access-Control-Allow-Credentials': 'true',
  },
  withCredentials: true,
};

const reCaptchaTokenVerify = async (token: string) => {
  try {
    const url = `${baseUrl}/${sublink}/${version}/CommonCodes/recaptcha/${token}`;
    const response = await ApiCallAxio({ url: url, method: APICallMethods.GET });
    const success = response.success;

    if (response && response.data && response.data.error) {
      console.log('API Error:', response.data.error);
      throw new Error('API Error');
    }

    return success;
  } catch (error) {
    console.error('Error fetching user data:', error);
    throw error;
  }
};

export { reCaptchaTokenVerify };
