import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { Image, Spin, Button, Modal, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { VerificationCodeInput } from '@app/components/common/VerificationCodeInput/VerificationCodeInput';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { doVerifySecurityCode } from '@app/store/slices/authSlice';
import { notificationController } from '@app/controllers/notificationController';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import * as S from '@app/components/auth/SecurityCodeForm/SecurityCodeForm.styles';
import OTPVerificationImage from '@app/assets/images/OTPVerificationImage.png';
import { loginUser } from '@app/components/auth/LoginForm/LoginController';
import { AddNewUser } from '../Users/UserData/UserDataController';
import { DecryptData } from '../Common/DecryptData';
import { userData } from '../Modal/Types/UserDataTypes';
import { getSearchResults } from '../SearchModal/SearchModalController';
import CryptoJS from 'crypto-js';

interface SecurityCodeFormProps {
  onBack?: () => void;
  onFinish?: () => void;
}

export const OTPVerification: React.FC<SecurityCodeFormProps> = ({ onBack, onFinish }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isModalVisible, setModalVisible] = useState(false);
  const [password, setPassword] = useState('');
  const [userToken, setUserToken] = useState('');
  const [encrptedData, setEncrptedData] = useState('');

  const navigateBack = useCallback(() => navigate(-1), [navigate]);
  const navigateForward = useCallback(() => navigate('/'), [navigate]);

  const location = useLocation();
  const action = (location.state as any)?.action || '';
  const where = (location.state as any)?.signInWhere || '';
  const naviListenerId = (location.state as any)?.navigateListenerId || '';
  const naviUserCode = (location.state as any)?.navigatetUserCode || '';

  const [securityCode, setSecurityCode] = useState('');
  const [isLoading, setLoading] = useState(false);

  const [userState, setUserState] = useState<userData>({
    userhedUsercode: '',
    userhedPassword: '',
    userhedToken: '',
    userhedListenerid: 0,
  });

  useEffect(() => {

    if (securityCode.length === 6) {
      setLoading(true);
      dispatch(doVerifySecurityCode({ code: securityCode }))
        .unwrap()
        .then(confirmOTP)
        .catch((err) => {
          notificationController.error({ message: err.message });
          setLoading(false);
        });
    }
  }, [securityCode, navigateForward, onFinish, dispatch]);

  useEffect(() => {
    if (where === 'users' && naviListenerId != null && naviUserCode != null) {
      const newData = {
        userhedUsercode: naviUserCode,
        userhedPassword: password,
        userhedToken: userToken,
        userhedListenerid: naviListenerId,
      };
      setUserState(newData);
    } else {
      const newData = {
        userhedUsercode: userCodeDecrypted,
        userhedPassword: password,
        userhedToken: userToken,
        userhedListenerid: userIdDecrypted,
      };
      setUserState(newData);
    }
  }, [password]);

  const confirmOTP = async () => {
    (window as any).confirmationResult
      .confirm(securityCode)
      .then((result: any) => {
        // User signed in successfully.
        const user = result.user;

        //UID
        const userUid = user.uid;
        console.log('user', userUid);
        if (action == 'USERHED_TOKEN') {
          console.log('gettin userhed token');
          userSignIn(action, userUid);
        } else if (action == 'SIGN_UP') {
          setModalVisible(true);
          setUserToken(userUid);
        }
      })
      .catch((error: any) => {
        console.log(error);
        notificationController.warning({
          message: t('notifications.warningTitle'),
          description: t('notifications.customWarningDescription'),
        });
        setLoading(false);
      });
  };

  const secretPass = process.env.REACT_APP_ENCRYPTION_SECRET_KEY as string;

  /**
   * This method is used to encrypt the listenerID before store in session storage
   * @param session
   * @returns
   */
  const encryptData = (session: number) => {
    const data = CryptoJS.AES.encrypt(JSON.stringify(session), secretPass).toString();

    setEncrptedData(data);
    return data;
  };

  const userSignIn = async (searchColumn: any, token: any) => {
    const strMenuCode = 'UL001';
    const searchControl = 'signIn';
    const searchKey = 'ULS01';
    const intPageNo = 0;
    const intRowPerPage = 5;
    const strFilter = 'a';
    const OrderBy = 'USERHED_ID';
    const searchPlace = 1;

    const response = await getSearchResults(
      strMenuCode,
      searchControl,
      searchKey,
      searchColumn,
      token,
      intPageNo,
      intRowPerPage,
      strFilter,
      OrderBy,
      searchPlace,
    );
    console.log('token response', response);
    console.log('token success', response.success);
    const isSuccess = response.success;
    const data = response.data.length;
    console.log('token data', data);
    console.log('token response user', response.data[0].FULL_NAME);
    console.log('token response user', response.data[0].USERHED_ID);
    console.log('token response user', response.data[0].USERHED_LISTENERID);
    console.log('token response user', response.data[0].USERHED_USERCODE);

    const listenerIdForUserInfo = response.data[0].USERHED_LISTENERID;
    const UsernameForUserInfo = response.data[0].FULL_NAME;
    const userCodeForUserInfo = response.data[0].USERHED_USERCODE;
    const userIdForUserInfo = response.data[0].USERHED_ID;

    //Encrypt the listenerID and userID
    const sessionListenerID = encryptData(listenerIdForUserInfo);
    const sessionUsername = encryptData(UsernameForUserInfo);
    const sessionUserCode = encryptData(userCodeForUserInfo);
    const sessionUserId = encryptData(userIdForUserInfo);

    if (data > 0) {
      sessionStorage.setItem('session', sessionListenerID);
      sessionStorage.setItem('session1', sessionUsername);
      sessionStorage.setItem('session2', sessionUserId);
      sessionStorage.setItem('session3', sessionUserCode);
      const sessionStartTime = new Date().getTime();
      sessionStorage.setItem('sessionStartTime', sessionStartTime.toString());

      navigate('/');
    } else {
      notificationController.warning({
        message: t('notifications.warningTitle'),
        description: t('notifications.customWarningDescription'),
      });
    }
  };

  const listenerID = sessionStorage.getItem('session');
  const userID = sessionStorage.getItem('session2');
  const userCode = sessionStorage.getItem('session3');
  const userIdDecrypted = DecryptData(userID);
  const userCodeDecrypted = DecryptData(userCode);

  const hanldeStoreToken = () => {
    console.log('password', password);
    checkTheUserAndstoreTokens();
  };

  const checkTheUserAndstoreTokens = async () => {
    console.log('user State', userState);

    try {
      let userName = '';
      if (where === 'users' && naviUserCode != null) {
        userName = naviUserCode;
      } else {
        userName = userCodeDecrypted;
      }
      console.log('username', userName, 'password', password);

      const checkCurrentPassword = await loginUser(userName, password);
      console.log('response from password change', checkCurrentPassword);

      const isSuccess = checkCurrentPassword.success;
      if (isSuccess) {
        console.log('userstate', userState);

        const result = await AddNewUser(userState);
        const isSuccessAddToken = result.success;
        console.log('response', result);

        //To show success and warning toast based on the result
        if (isSuccessAddToken) {
          notificationController.success({
            message: t('notifications.successTitle'),
            description: t('notifications.successUserDescription'),
          });
          setModalVisible(false);

          window.location.href = '/profile/security-settings';
        } else {
          console.log('token not added successfully!');
        }
      } else {
        notificationController.warning({
          message: t('notifications.warningTitle'),
          description: t('notifications.wrongPasswordDescription'),
        });
        console.log('the password is not matching');
      }
    } catch (error) {
      console.log('catched an error');
    }
  };

  return (
    <>
      <Auth.FormWrapper>
        <BaseForm layout="vertical" requiredMark="optional">
          <Auth.BackWrapper onClick={onBack || navigateBack}>
            <Auth.BackIcon />
            {t('common.back')}
          </Auth.BackWrapper>
          <S.ContentWrapper>
            <S.ImageWrapper>
              <Image src={OTPVerificationImage} alt="Not found" preview={false} width={200} />
            </S.ImageWrapper>
            <Auth.FormTitle>{t('securityCodeForm.otpTitle')}</Auth.FormTitle>
            <S.VerifyEmailDescription>{t('common.verifOTPSent')}</S.VerifyEmailDescription>
            {isLoading ? <Spin /> : <VerificationCodeInput autoFocus onChange={setSecurityCode} />}
            <Link to="/" target="_blank">
              <S.NoCodeText>{t('securityCodeForm.noCode')}</S.NoCodeText>
            </Link>
          </S.ContentWrapper>
        </BaseForm>
      </Auth.FormWrapper>
      <Modal
        visible={isModalVisible}
        onCancel={() => setModalVisible(false)}
        footer={[
          <Button key="continue" type="primary" onClick={hanldeStoreToken}>
            Continue
          </Button>,
        ]}
        // To remove the OK button
        okButtonProps={{ style: { display: 'none' } }}
        bodyStyle={{ maxHeight: 'calc(100vh - 300px)', overflowY: 'auto' }}
        width={500}
      >
        <h3>Plase Enter Your password to continue</h3>
        <Input
          placeholder="Password"
          type="password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          className="w-full"
        ></Input>
      </Modal>
    </>
  );
};
