//Google auth sign in
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const FirebaseConfig = {
  apiKey: 'AIzaSyBtL751IalZhZ2prpQn_8IE9_1D3xxd3LU',
  authDomain: 'radio-376203.firebaseapp.com',
  projectId: 'radio-376203',
  storageBucket: 'radio-376203.appspot.com',
  messagingSenderId: '55351561859',
  appId: '1:55351561859:web:2fecf3d81fc3f14a1b95b6',
  measurementId: 'G-XQEKVCDVXC',
};

export default FirebaseConfig;
