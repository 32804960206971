import React, { useState } from 'react';

interface HoverImageProps {
  defaultSrc: string;
  hoverSrc: string;
  alt: string;
  onClick?: () => void;
}

const HoverImage: React.FC<HoverImageProps> = ({ defaultSrc, hoverSrc, alt, onClick }) => {
  const isMobileOrTablet = window.matchMedia('(max-width: 768px)').matches;
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  if (isMobileOrTablet) {
    return (
      <div className="HoverImageContainer" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
        <img src={isHovered ? hoverSrc : hoverSrc} alt={alt} onClick={onClick} />
      </div>
    );
  } else {
    return (
      <div className="HoverImageContainer" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
        <img src={isHovered ? hoverSrc : defaultSrc} alt={alt} onClick={onClick} />
      </div>
    );
  }
};

export default HoverImage;
