import styled from 'styled-components';
import { Card as CommonCard } from '@app/components/common/Card/Card';

export const TablesWrapper = styled.div`
  margin-top: 1.875rem;
`;

export const Card = styled(CommonCard)`
  margin-bottom: 2rem;
`;

export const SearchWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px; /* Optional: Adjust margin as needed */

  @media (min-width: 768px) {
    /* Adjust the breakpoint value as per your design */
    width: 50%;
    margin-left: 50%; /* Push the input field to the right */
  }

  @media (max-width: 767px) {
    /* Adjust the breakpoint value as per your design */
    width: 100%;
  }
`;
