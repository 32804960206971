import React, { useState, useEffect } from 'react';
import { Table, Space, Modal } from 'antd';
import { searchDataProps } from '@app/pages/Utilities/Modal/Types/SearchData';
import { getSearchPara, getSearchResults } from '@app/pages/Utilities/SearchModal/SearchModalController';
import { Button } from 'components/common/buttons/Button/Button';
import { useTranslation } from 'react-i18next';
import {
  PlusOutlined,
  EyeOutlined,
  SortDescendingOutlined,
  SortAscendingOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { notificationController } from '@app/controllers/notificationController';

export const CustomTable = (props: searchDataProps) => {
  const { t } = useTranslation();
  const [columns, setColumns] = useState([]);
  const [subColumns, setSubColumns] = useState([]);
  const [changedColumn, setChangedColumn] = useState('');
  const [title, setTitle] = useState('');
  const [searchColumn, setSearchColumn] = useState('');
  const [tableData, setTableData] = useState({ data: [] });
  const [subTableData, setSubTableData] = useState({ data: [] });
  const [isViewModalVisible, setViewModalVisible] = useState(false);
  const [clickedRow, setClickedRow] = useState();
  const [rowClickedUserID, setRowClickedUserID] = useState<number | undefined>();
  const [pageNo, setPageNo] = useState(0);
  const [testTableData, setTestTableData] = useState(props.data);
  //to change the sort order
  const [sortOrder, setSortOrder] = useState('asc');
  const [entertainers, setEntertainers] = useState<number[]>([]);
  const [dataLength, setDataLength] = useState(0);

  /**
   * This method is used to do a action when click on a row
   * @param key
   */
  const handleRowClick = (record: any) => {
    setRowClickedUserID(record.USERHED_ID);
    if (props.strMenuCode === 'UL001' && props.strSearchControl === 'listeners') {
      setClickedRow(record.LISTENER_ID);
      if (props.onRowClick) props.onRowClick(record.LISTENER_ID);
      if (props.ListenerName) props.ListenerName(record.LISTENER_FIRST_NAME);
    } else if (props.strMenuCode === 'U0003' && props.strSearchControl === 'programs') {
      setClickedRow(record.PROGRAM_ID);
      if (props.onRowClick) props.onRowClick(record.PROGRAM_ID);
    } else if (
      props.strMenuCode === 'USR01' &&
      (props.strSearchControl === 'songRequest' || props.strSearchControl === 'songRequestSearch')
    ) {
      setClickedRow(record.SONG_REQ_ID);
      if (props.onRowClick) props.onRowClick(record);
    } else {
      setClickedRow(record.USERHED_USERCODE);
      if (props.onRowClick) props.onRowClick(record.USERHED_USERCODE);
    }
  };

  useEffect(() => {
    console.log('added listener id', rowClickedUserID);
    if (props.strMenuCode === 'U0003' && props.strSearchControl === 'entertainer') {
      if (rowClickedUserID !== undefined && setValue) {
        if (props.OnAddEntertainers) 
        props.OnAddEntertainers([rowClickedUserID]);
          setValue(rowClickedUserID);
          notificationController.success({
            message: t('notifications.successTitle'),
            description: t('notifications.successEntertainerDescription'),
          });
      }
      }else{

      }
  }, [rowClickedUserID]);



  useEffect(() => {
    console.log('sort', sortOrder);

    console.log(rowClickedUserID);
    //If the search is to get entertainers
    if (props.strSearchKey === 'UP001') {
      //To get the data for sub table
      if (clickedRow !== null && clickedRow !== '' && clickedRow !== undefined) {
        (async () => {
          //To get the column names
          if (props.strMenuCode != null) {
            const searchControl = 'entertainerSub';
            const response = await getSearchPara(props.strMenuCode, searchControl);
            console.log('columns for upcoming programs', response);

            const parsedUpcomingProgramColumns = response.data.strDispCol.split(',').map((col: any, index: any) => ({
              title: col,
              dataIndex: response.data.strSearchCol.split(',')[index],
              width: `${response.data.strColSize.split(',')[index]}%`,
              onHeaderCell: (column: any) => ({
                onclick: () => {
                  console.log('Clicked column dataIndex:', column.dataIndex);
                  console.log('Clicked column display name:', column.title);
                },
              }),
            }));
            //Set columns to the table
            setSubColumns(parsedUpcomingProgramColumns);

            const intPageNo = 0;
            const intRowPerPage = 5;
            const searchKey = 'UPE01';
            try {
              if (
                props.strMenuCode != null &&
                searchControl != null &&
                searchKey != null &&
                props.strSearchCol != null &&
                searchColumn !== null &&
                intPageNo != null &&
                intRowPerPage != null &&
                props.strFilter != null &&
                props.OrderBy != null &&
                props.searchPlace != null &&
                clickedRow != null
              ) {
                const response = await getSearchResults(
                  props.strMenuCode,
                  searchControl,
                  searchKey,
                  searchColumn,
                  clickedRow,
                  intPageNo,
                  intRowPerPage,
                  props.strFilter,
                  sortOrder,
                  props.searchPlace,
                );
                console.log('result response upcoming programs', response.data);
                setSubTableData({ data: response.data });
              }
            } catch (error) {
              console.error('Error fetching user data:', error);
            }
          } else {
          }
        })();
      }
    } else {
    }

    //To select which search column will used to search
    if (props.strSearchCol != null) {
      if (changedColumn === '' || props.reset === true) {
        setSearchColumn(props.strSearchCol);
      } else {
        setSearchColumn(changedColumn);
      }
    }

    (async () => {
      if (props.strMenuCode != null && props.strSearchControl) {
        //To get the column names
        const response = await getSearchPara(props.strMenuCode, props.strSearchControl);
        console.log('columns', response);

        //To set column names to the table
        const parsedColumns = response.data.strDispCol.split(',').map((col: any, index: any) => ({
          title: (
            <div onClick={handleToggleSorting}>
              <span>{col}</span>
              {sortOrder === 'asc' ? (
                <SortAscendingOutlined style={{ marginLeft: '4px' }} />
              ) : (
                <SortDescendingOutlined style={{ marginLeft: '4px' }} />
              )}
            </div>
          ),
          dataIndex: response.data.strSearchCol.split(',')[index],
          width: `${response.data.strColSize.split(',')[index]}%`,
          onHeaderCell: (column: any) => ({
            onClick: () => {
              console.log('Clicked column dataIndex:', column.dataIndex);
              console.log('Clicked column display name:', column.title);
              setTitle(col);
              setChangedColumn(column.dataIndex);
            },
          }),
        }));

        let idColumnDataIndex = 'LISTENER_ID';
        //To hide the ID
        if (props.strMenuCode === 'UL001' && props.strSearchControl === 'listeners' && props.strSearchKey === 'ULS01') {
          idColumnDataIndex = 'LISTENER_ID';
        } else if (
          props.strMenuCode === 'U0003' &&
          props.strSearchControl === 'entertainer' &&
          props.strSearchKey === 'UP001'
        ) {
          idColumnDataIndex = 'USERHED_ID';
        } else if (
          props.strMenuCode === 'USR01' &&
          (props.strSearchControl === 'songRequest' || props.strSearchControl === 'songRequestSearch') &&
          props.strSearchKey === 'USR01'
        ) {
          idColumnDataIndex = 'SONG_REQ_ID';
        } else {
          idColumnDataIndex = 'PROGRAM_ID';
        }

        const filteredColumns = parsedColumns.filter((column: any) => column.dataIndex !== idColumnDataIndex);

        console.log('columns', parsedColumns);

        //To show action buttons
        const actionButtons: React.ReactNode[] = [];

        actionButtons.push(
          <Button
            type="default"
            key="add"
            disabled={!props.actionAdd}
            onClick={() => {
              props.actionAdd ? handleAddAction() : null;
            }}
            className="borderless"
          >
            <PlusOutlined />
          </Button>,
        );

        actionButtons.push(
          <>
            <Button
              type="default"
              key="view"
              disabled={!props.actionView}
              onClick={() => {
                props.actionView ? handleViewAction() : null;
              }}
              className="borderless"
            >
              <EyeOutlined />
            </Button>
          </>,
        );

        filteredColumns.push({
          title: t('tables.actions'),
          dataIndex: 'actions',
          width: '15%',
          render: () => <Space>{actionButtons}</Space>,
        });

        setColumns(filteredColumns);
      }
    })();

    handleSearchQuery(pageNo);
    whichColumnIsSelected();
  }, [props.searchedValue, changedColumn, clickedRow, rowClickedUserID, sortOrder, pageNo]);

  const handleToggleSorting = () => {
    setSortOrder((prevSortOrder) => (prevSortOrder === 'asc' ? 'desc' : 'asc'));
  };


  const { value, setValue } = props;
  const { userCode, setUserCode } = props;

  const handleAddAction = async () => {
    try {
      if (props.strMenuCode === 'U0003' && props.strSearchControl === 'entertainer') {

      } else if (props.strMenuCode === 'UL001' && props.strSearchControl === 'listeners') {
        if (props.onCloseModal) {
          props.onCloseModal();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * This method is used to handle "VIEW" Button Action
   */
  const handleViewAction = () => {
    if (props.strMenuCode === 'U0003' && props.strSearchControl === 'entertainer') {
      setViewModalVisible(true);
    } else {
      setTestTableData(clickedRow);

      if (props.onTableDataChange && clickedRow) props.onTableDataChange(clickedRow);

      if (props.onCloseModal) {
        props.onCloseModal();
      }
      if (setUserCode && clickedRow) {
        setUserCode(clickedRow);
      }
    }
  };



  /**
   * This method is used to handle the search query
   */
  const handleSearchQuery = async (intPageNo: number) => {
    //const intPageNo = 0 ;
    const intRowPerPage = 5;

    try {
      if (
        props.strMenuCode != null &&
        props.strSearchControl != null &&
        props.strSearchKey != null &&
        props.strSearchCol != null &&
        searchColumn !== null &&
        intPageNo != null &&
        intRowPerPage != null &&
        props.strFilter != null &&
        props.OrderBy != null &&
        props.searchPlace != null &&
        props.searchedValue != null
      ) {

        let searchvalue = '';
        if (props.searchedValue === '') {
          searchvalue = '0';
        } else {
          searchvalue = props.searchedValue;
        }

        let searchedColumn = searchColumn;
        if (searchedColumn === '') {
          searchedColumn = props.strSearchCol;
        } else {
          searchedColumn = searchColumn;
        }

        console.log('search column ', searchColumn);

        const response = await getSearchResults(
          props.strMenuCode,
          props.strSearchControl,
          props.strSearchKey,
          searchedColumn,
          searchvalue,
          intPageNo,
          intRowPerPage,
          props.strFilter,
          sortOrder,
          props.searchPlace,
        );
        console.log('result response', response.data);

        setTableData({ data: response.data });
        setDataLength(response.data.length);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const whichColumnIsSelected = () => {
    if (props.onSelectedColumnChange) {
      props.onSelectedColumnChange(title);
    }
  };

  const showThearray = () => {
    console.log(entertainers);
  };

  const handleNextPage = () => {
    setPageNo(pageNo + 1);
  };

  const handlePreviousPage = () => {
    if (pageNo > 0) {
      setPageNo(pageNo - 1);
    }
  };

  return (
    <>
      <Table
        dataSource={tableData.data}
        columns={columns}
        className="w-full"
        pagination={false}
        onRow={(record) => ({
          onClick: () => handleRowClick(record),
        })}
      />
      <div className="custom-pagination">
        <Button onClick={handlePreviousPage} disabled={pageNo + 1 === 1}>
          <LeftOutlined />
        </Button>
        <span className="page-no"> {pageNo + 1}</span>
        <Button onClick={handleNextPage} disabled={dataLength !== undefined && dataLength < 5}>
          <RightOutlined />
        </Button>
      </div>

      {/* To show the view details */}
      <Modal
        title={clickedRow}
        visible={isViewModalVisible}
        onCancel={() => setViewModalVisible(false)}
        // To remove the OK button
        okButtonProps={{ style: { display: 'none' } }}
        bodyStyle={{ maxHeight: 'calc(100vh - 300px)', overflowY: 'auto', padding: '10px' }}
        width={800}
      >
        {props.strSearchKey === 'UP001' ? (
          <Table dataSource={subTableData.data} columns={subColumns} className="w-full" />
        ) : null}
      </Modal>
    </>
  );
};
