import ApiCallAxio from '@app/api/apiCallAxio';
import APICallMethods from '@app/types/APICallMethods';

//Import url from config file
const baseUrl = process.env.REACT_APP_API_BASEURL;
const sublink = process.env.REACT_APP_API_SUBLINK;
const version = process.env.REACT_APP_API_VERSION;

interface LoginFormData {
  username: string;
  password: string;
}

// const loginUser = async (userLogin: LoginFormData) => {
//     const url = `${baseUrl}/${sublink}/${version}/users/signin`;
//     // const data = JSON.stringify({
//     //   username: username,
//     //   password: password,
//     // })

//     const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.POST, data: userLogin});
//     return result;
// };

const loginUser = async (username: string, password: string) => {
  const url = `${baseUrl}/${sublink}/${version}/users/signin`;
  const data = JSON.stringify({
    username: username,
    password: password,
  });

  const result = await ApiCallAxio({ url: `${url}`, method: APICallMethods.POST, data: data });
  return result;
};

// const loginUser = async (userLogin: LoginFormData) => {
//     const url = `${baseUrl}/${sublink}/${version}/users/signin`;
//     // const data = JSON.stringify({
//     //   username: username,
//     //   password: password,
//     // })

//     const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.POST, data: userLogin});
//     return result;
// };

export { loginUser };
