import React, { useState, useEffect } from 'react';
import { Table, Space } from 'antd';
import { Button } from 'components/common/buttons/Button/Button';
import { useTranslation } from 'react-i18next';
import {
  LeftOutlined,
  PlusOutlined,
  RightOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
} from '@ant-design/icons';
import { getSearchPara, getSearchResults } from '../../SearchModal/SearchModalController';

export const SongRequestDataTable = () => {
  const { t } = useTranslation();
  const [columns, setColumns] = useState([]);
  const [tableData, setTableData] = useState({ data: [] });
  const [pageNo, setPageNo] = useState(0);
  const [sortOrder, setSortOrder] = useState('asc');
  const [dataLength, setDataLength] = useState(0);

  const menucode = 'USR01';
  const searchControl = 'songRequest';
  const searchKey = 'USR01';
  const SearchCol = 'SONG_REQ_NAME';
  const searchValue = '0';
  const intRowPerPage = 5;
  const strFilter = 'a';
  const searchPlace = 1;

  const handleNextPage = () => {
    setPageNo(pageNo + 1);
  };

  const handlePreviousPage = () => {
    if (pageNo > 0) {
      setPageNo(pageNo - 1);
    }
  };

  const handleToggleSorting = () => {
    setSortOrder((prevSortOrder) => (prevSortOrder === 'asc' ? 'desc' : 'asc'));
  };

  useEffect(() => {
    (async () => {
      //To get the column names

      const response = await getSearchPara(menucode, searchControl);
      console.log('columns', response);

      //To set column names to the table
      const parsedColumns = response.data.strDispCol.split(',').map((col: any, index: any) => ({
        title: (
          <div onClick={handleToggleSorting}>
            <span>{col}</span>
            {sortOrder === 'asc' ? (
              <SortAscendingOutlined style={{ marginLeft: '4px' }} />
            ) : (
              <SortDescendingOutlined style={{ marginLeft: '4px' }} />
            )}
          </div>
        ),
        dataIndex: response.data.strSearchCol.split(',')[index],
        width: `${response.data.strColSize.split(',')[index]}%`,
        render: (text: any) => {
          // Assuming "status" is the dataIndex for the status column
          if (col.dataIndex === 'SONG_REQ_STATUS') {
            return text === '0' ? 'Pending' : text;
          }
          return text;
        },
        onHeaderCell: (column: any) => ({
          onClick: () => {
            console.log('Clicked column dataIndex:', column.dataIndex);
            console.log('Clicked column display name:', column.title);
          },
        }),
      }));

      const idColumnDataIndex = 'SONG_REQ_ID';
      const filteredColumns = parsedColumns.filter((column: any) => column.dataIndex !== idColumnDataIndex);
      console.log('columns', parsedColumns);

      //To show action buttons
      const actionButtons: React.ReactNode[] = [];

      actionButtons.push(
        <Button
          type="default"
          key="add"
        >
          <PlusOutlined />
        </Button>,
      );

      filteredColumns.push({
        title: t('tables.actions'),
        dataIndex: 'actions',
        width: '15%',
        render: () => <Space>{actionButtons}</Space>,
      });

      setColumns(filteredColumns);

      try {
        const response = await getSearchResults(
          menucode,
          searchControl,
          searchKey,
          SearchCol,
          searchValue,
          pageNo,
          intRowPerPage,
          strFilter,
          sortOrder,
          searchPlace,
        );
        console.log('result response song requests', response.data[0].SONG_REQ_STATUS);

        const modifiedData = response.data.map((record: any) => ({
          ...record,
          SONG_REQ_STATUS:
            record.SONG_REQ_STATUS === '0'
              ? 'Pending'
              : record.SONG_REQ_STATUS === '1'
              ? 'Done'
              : record.SONG_REQ_STATUS,
        }));

        setTableData({ data: modifiedData });
        setDataLength(modifiedData.length);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    })();
  }, [pageNo, sortOrder]);

  return (
    <>
      <Table
        dataSource={tableData.data}
        columns={columns}
        className="w-full"
        pagination={false}
      />
      <div className="custom-pagination">
        <Button onClick={handlePreviousPage} disabled={pageNo + 1 === 1}>
          <LeftOutlined />
        </Button>
        <span className="page-no"> {pageNo + 1}</span>
        <Button onClick={handleNextPage} disabled={dataLength !== undefined && dataLength < intRowPerPage}>
          <RightOutlined />
        </Button>
      </div>
    </>
  );
};
