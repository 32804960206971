import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { SignInWithComponent } from '@app/pages/Utilities/SignUpWith/SignInWithComponent';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import tuneInLogo from '../../../assets/logo/TuneIn_logo.png';
import { loginUser } from './LoginController';
import { reCaptchaTokenVerify } from '@app/pages/Utilities/FireBaseAuth/ReCaptcha';
import { notificationController } from '@app/controllers/notificationController';
import { Button, Input } from 'antd';
import axios from 'axios';
import CryptoJS from 'crypto-js';

import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { isNullishCoalesce } from 'typescript';

interface LoginFormData {
  email: string;
  password: string;
}

export const initValues: LoginFormData = {
  email: 'hello@altence.com',
  password: 'some-test-pass',
};

export const LoginForm: React.FC = () => {
  const [encrptedData, setEncrptedData] = useState('');
  const [decrptedData, setDecrptedData] = useState('');

  const secretPass = process.env.REACT_APP_ENCRYPTION_SECRET_KEY as string;
  // console.log("secret key",secret);

  // const secretPass = "XkhZG4fW2t2W";

  /**
   * This method is used to encrypt the listenerID before store in session storage
   * @param session
   * @returns
   */
  const encryptData = (session: number) => {
    const data = CryptoJS.AES.encrypt(JSON.stringify(session), secretPass).toString();

    setEncrptedData(data);
    return data;
  };

  const [listenerID, setListenerID] = useState(0);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [token, setToken] = useState('');

  const onSubmit = async (data: LoginFormData) => {
    // setLoading(true);
    try {
      const result = await loginUser(data.email, data.password);
      const listenerIdForUserInfo = result.user.listenerId;
      const UsernameForUserInfo = result.user.userName;
      const userCodeForUserInfo = result.user.userCode;
      const userIdForUserInfo = result.user.userId;
      setListenerID(listenerIdForUserInfo);

      //Encrypt the listenerID and userID
      const sessionListenerID = encryptData(listenerIdForUserInfo);
      const sessionUsername = encryptData(UsernameForUserInfo);
      const sessionUserCode = encryptData(userCodeForUserInfo);
      const sessionUserId = encryptData(userIdForUserInfo);

      sessionStorage.setItem('session', sessionListenerID);
      sessionStorage.setItem('session1', sessionUsername);
      sessionStorage.setItem('session2', sessionUserId);
      sessionStorage.setItem('session3', sessionUserCode);

      const isSuccess = result.success;
      console.log(isSuccess);
      if (isSuccess) {
        reCaptchaVerify();
      } else {
        setIsValid(false);
      }
    } catch (error) {
      setIsValid(false);
    }
  };

  const { executeRecaptcha } = useGoogleReCaptcha();

  const reCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }
    const token = await executeRecaptcha('Login');
    if (token) {
      const response = await reCaptchaTokenVerify(token);
      console.log('result', response);
      if (response) {
        navigate('/');
      } else {
        console.log('There is something wrong with recaptcha');
      }
    }
  }, [executeRecaptcha]);

  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" onFinish={onSubmit} requiredMark="optional">
        <div className="LogoStyle">
          <img src={tuneInLogo} alt="logo" />
        </div>
        {isValid ? null : <label className="invalid-message-error">{t('common.invalidLogin')}</label>}
        <Auth.FormItem
          name="email"
          label={t('common.username')}
          rules={[
            { required: true, message: t('common.requiredField') },
            {
              message: t('common.notValidUserName'),
            },
          ]}
        >
          <Auth.FormInput placeholder={t('common.username')} style={{ background: '#ffffff' }} />
        </Auth.FormItem>
        <Auth.FormItem
          label={t('common.password')}
          name="password"
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInputPassword className="loginInput" placeholder={t('common.password')} />
        </Auth.FormItem>
        <Auth.ActionsWrapper>
          <BaseForm.Item name="rememberMe" valuePropName="checked" noStyle>
            <Auth.FormCheckbox>
              <div className="RememberMeText">{t('login.rememberMe')}</div>
            </Auth.FormCheckbox>
          </BaseForm.Item>
          <Link to="/auth/forgot-password">
            <div className="ForgotPasswordText">{t('common.forgotPass')}</div>
          </Link>
        </Auth.ActionsWrapper>
        <BaseForm.Item noStyle>
          <Auth.SubmitButton
            type="primary"
            htmlType="submit"
            // loading={isLoading}
          >
            {t('common.login')}
          </Auth.SubmitButton>
        </BaseForm.Item>
        <div className="LoginWithText">Or login using</div>
        <BaseForm.Item>
          <div id="sign-in-button"></div>
          <div id="recaptcha-container"></div>
          <SignInWithComponent />
        </BaseForm.Item>
      </BaseForm>
    </Auth.FormWrapper>
  );
};
