import styled from 'styled-components';
import { FONT_SIZE, FONT_WEIGHT } from '@app/styles/themes/constants';
import { Card as CommonCard } from 'components/common/Card/Card';

export const TablesWrapper = styled.div`
  margin-top: 0.25rem;

  .ant-table-thead > tr > th {
    color: white;
  }
`;

export const Card = styled(CommonCard)`
  margin-bottom: 0.25rem;
`;

export const ButtonStyle = styled(CommonCard)`
  float: right;
`;

export const FormInputStyle = styled(CommonCard)`
  display: flex;
  flex-direction: column; /* Arrange items in a column */
`;

export const FormInputLabel = styled.label`
  /* Add any label styles you need */
  margin-bottom: 10px; /* Adjust the spacing between the label and input */
`;

export const FormInputField = styled.input`
  /* Add any input styles you need */
  width: 100%; /* Input field takes full width */
`;

export const InputStyle = styled.div`
  .col {
    margin-bottom: 20px;
  }
`;

export const ElementRow = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LabelStyle = styled.div`
  border: 1px solid #a9a9a9;
  border-radius: 5px;
  height: 47px;
  color: gray;
  padding: 10px;
`;

export const ElementColumn = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

export const DraggerIconWrapper = styled.div`
  font-size: 4rem;
  color: var(--primary-color);
`;

export const DraggerTitle = styled.div`
  font-size: ${FONT_SIZE.xl};
  font-weight: ${FONT_WEIGHT.bold};
`;

export const DraggerDescription = styled.div`
  font-size: ${FONT_SIZE.md};
  padding: 0 1rem;
`;

export const BorderlessTable = styled.div`
  .ant-table-wrapper,
  .ant-table,
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    border: none;
  }
`;
