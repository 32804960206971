import React, { useEffect, useState } from 'react';
import { Col, Row, message, Input, Modal, Space, Table } from 'antd';
import { Card } from '@app/components/common/Card/Card';
import { DayjsDatePicker } from '@app/components/common/pickers/DayjsDatePicker';
import { Button } from 'components/common/buttons/Button/Button';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';
import {
  AddNewProgram,
  DeleteProgramDetails,
  getProgramDetailsByProgramId,
} from '@app/pages/Utilities/Programs/ProgramController';
import { notificationController } from '@app/controllers/notificationController';
import { Pagination, UserSearchUsercodeForEntertainerTableRow, addedEntertainersTableRow } from '@app/api/table.api';
import { getUsersByUserId, getUsersByUsercode } from '@app/pages/Utilities/Users/UserData/UserDataController';
import { ColumnsType } from 'antd/es/table';
import { capitalizeFirstLetter } from '@app/pages/Utilities/Common/CapitalizeFirstLetter';
import { SearchModalWithTable } from '@app/pages/Utilities/SearchModal/SearchModalWithTable';
import { PlusOutlined, EyeOutlined } from '@ant-design/icons';
import { Rate } from '@app/components/common/Rate/Rate';
import axios, { AxiosRequestConfig } from 'axios';
import { DecryptData } from '@app/pages/Utilities/Common/DecryptData';
import { v4 as uuidv4 } from 'uuid';
import { InboxOutlined } from '@ant-design/icons';

const initialPagination: Pagination = {
  current: 0,
  pageSize: 16,
};

interface ProgramDataProps {
  onClick?: () => void;
  searchData?: any;
  isEditable?: any;
}

interface Entertainers {
  name: string;
  rate: number;
}

export const ProgramsData: React.FC<ProgramDataProps> = ({ searchData, isEditable, onClick }) => {
  const { t } = useTranslation();
  const [isSearching, setIsSearching] = useState(true);
  const [uploadedFile, setUploadedFile] = useState({});
  const [allowSubmit, setAllowSubmt] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState('');
  const initialCombinedArray: { hostid: any; programdetailsid: any }[] = [];

  const [combinedState, setCombinedState] = useState(initialCombinedArray);
  const [isHostIds, setIsHostIds] = useState(false);

  //To show validate messages
  const [programNameMsg, setProgramNameMsg] = useState('');
  const [programTypeMsg, setProgramTypeMsg] = useState('');
  const [programDescriptionMsg, setProgramDescriptionMsg] = useState('');
  const [programDateMsg, setProgramDateMsg] = useState('');
  const [programStartTimeMsg, setProgramStartTimeMsg] = useState('');
  const [programEndTimeMsg, setProgramEndTimeMsg] = useState('');
  const [programEntertainersMsg, setProgramEntertainersMsg] = useState('');
  const [programImageMsg, setProgramImageMsg] = useState('');

  const [uploading, setUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState<any>();
  const [programPhotosFile, setProgramPhotosFile] = useState<File | null>(null);
  const [isModalVisibleEntertainer, setModalVisibleEntertainer] = useState(false);
  const [isModalVisibleSingleEntertainer, setModalVisibleSingleEntertainer] = useState(false);
  const [entertainer, setEntertainer] = useState('');
  //Usercode search for entetainers
  const [userCode, setUsetCode] = useState('');
  const [entertainerID, setEntertainerID] = useState('');
  const [value, setValue] = useState<number>(0);
  const [addedHostIds, setAddedHostIds] = useState<number[]>([]);
  const [hostIDs, setHostIDs] = useState<number[]>([]);
  const [programCoverImage, setProgramCoverImage] = useState('');
  const [imageName, setImageName] = useState<string | null>(null);
  const [addedEntertainers, setAddedEntertainers] = useState<any[]>([]);

  const uuid = uuidv4();

  //Import url from config file
  const baseUrl = process.env.REACT_APP_API_BASEURL;
  const sublink = process.env.REACT_APP_API_SUBLINK;
  const version = process.env.REACT_APP_API_VERSION;

  const boundary = `my-boundary-${Math.random().toString(16)}`;
  const config: AxiosRequestConfig = {
    headers: {
      // 'Content-Type': 'application/json',
      'Content-Type': `multipart/form-data; boundary=---------------------------293582696224464`,
      'Access-Control-Allow-Origin': location.hostname,
      'Access-Control-Allow-Credentials': 'true',
    },
    withCredentials: true,
  };



  const customOnChange = async ({
    file,
    onSuccess,
    onError,
  }: {
    file: any;
    onSuccess: (response: any, file: File) => void;
    onError: (error: any) => void;
  }) => {
    try {
      const lastModified = file.lastModified;
      const lastModifiedDate = file.lastModifiedDate;
      const name = file.name;
      const type = file.type;
      const size = file.size;
      setUploadedFile({ lastModified, lastModifiedDate, name, type, size });

      const testFile = new File([file], name, {
        lastModified: lastModified,
        type: type,
      });

      const formData = new FormData();
      formData.append('formdata', testFile);
      formData.append('strFolder', 'Programs');
      formData.append('fileName', uuid);

      const response = await axios.post(uploadProps.action, formData, config);
      onSuccess(response.data, file);
    } catch (error) {
      onError(error);
    }
  };

  const uploadProps = {
    name: 'file',
    multiple: false,
    action: `${baseUrl}/${sublink}/${version}/CommonCodes/fileUpload`,
    headers: {
      ...config.headers,
    },
    withCredentials: config.withCredentials,
    onChange: customOnChange as any,
  };


  //for image uplaod
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const uuid = uuidv4();

    if (event.target.files && event.target.files.length > 0) {
      const selectedFile = event.target.files[0];
      setSelectedFile(selectedFile);
      setProgramPhotosFile(selectedFile);
      setSelectedFileName(selectedFile.name);
      const imgType = selectedFile.type;
      const image_type = imgType.split('/')[1];
      const imageFileName = `${uuid}.${image_type}`;

      // Update the programState with the selected file name
      setProgramState((prevState) => ({
        ...prevState,
        programPhotos: imageFileName,
      }));
    }
  };

  const handleFileDrop = (event: React.DragEvent<HTMLLabelElement>) => {
    event.preventDefault();
    const uuid = uuidv4();
    const files = event.dataTransfer.files;

    if (files.length > 0) {
      const selectedFile = files[0];
      setSelectedFile(selectedFile);
      setProgramPhotosFile(selectedFile);

      setProgramState((prevState) => ({
        ...prevState,
        programPhotos: uuid,
      }));
    }
  };

  const handleUpload = () => {
    const formData = new FormData();

    if (programPhotosFile) {
      formData.append('programPhotos', programPhotosFile);
    }
  };

  const OnFileUpload = async () => {
    // Create an object of formData
    const formData = new FormData();
    formData.append('file from uplaod ', selectedFile);
    formData.append('strFolder', 'Programs');
    formData.append('fileName', uuid);

    const response = axios.post(`${baseUrl}/${sublink}/${version}/CommonCodes/fileUpload`, formData, config);
  };

  interface ProgramDetail {
    programDetailHostId: number;
    programDetailCreateBy: number;
  }

  interface programDataProp {
    programName: string;
    programDescription: string;
    programType: string;
    programCreator: number;
    programDate: dayjs.Dayjs | null;
    programStartTime: string | null;
    programEndTime: string | null;
    programPhotos: string;
    programCreateBy: number;
    programDetailsReq: ProgramDetail[];
  }

  //To get the userID
  const userId = sessionStorage.getItem('session2');
  const userIdDecrypted = DecryptData(userId);

  const [programState, setProgramState] = useState<programDataProp>({
    programName: '',
    programDescription: '',
    programType: '',
    programCreator: userIdDecrypted,
    programDate: null,
    programStartTime: null,
    programEndTime: '',
    programPhotos: '',
    programCreateBy: userIdDecrypted,
    programDetailsReq: [],
  });

  /**
   * This method is used to handle the program name
   * @param e
   */
  const handleProgramNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProgramState((prevState) => ({
      ...prevState,
      programName: e.target.value,
    }));
  };

  /**
   * This method is used to handle the program description
   * @param e
   */
  const handleProgramDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProgramState((prevState) => ({
      ...prevState,
      programDescription: e.target.value,
    }));
  };

  /**
   * This method is used to handle the program type
   * @param e
   */
  const handleProgramTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProgramState((prevState) => ({
      ...prevState,
      programType: e.target.value,
    }));
  };

  const handleProgramDateChange = (newDate: any) => {
    setProgramState({
      ...programState,
      programDate: newDate,
    });
  };

  const handleProgramStartTimeChange = (newTime: dayjs.Dayjs | null, dateString: string) => {
    if (newTime) {
      const formattedTime = newTime.format('HH:mm:ss');
      setProgramState({
        ...programState,
        programStartTime: formattedTime,
      });
    } else {
      setProgramState({
        ...programState,
        programStartTime: '',
      });
    }
  };

  const handleProgramEndTimeChange = (newTime: dayjs.Dayjs | null, dateString: string) => {
    if (newTime) {
      const formattedTime = newTime.format('HH:mm:ss');
      setProgramState({
        ...programState,
        programEndTime: formattedTime,
      });
    } else {
      setProgramState({
        ...programState,
        programEndTime: '',
      });
    }
  };

  //To access the program host id from the program state object
  const programDetailHostId =
    programState.programDetailsReq.length > 0 ? programState.programDetailsReq[0].programDetailHostId : null;

  const handleProgramHostIDChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEntertainerID(e.target.value);
  };

  const clearData = () => {
    const newData = {
      programName: '',
      programDescription: '',
      programType: '',
      programCreator: userIdDecrypted,
      programDate: null,
      programStartTime: '',
      programEndTime: '',
      programPhotos: '',
      programCreateBy: userIdDecrypted,
      programDetailsReq: [],
    };
    setProgramState(newData);
  };

  const isNullOrBlank = (value: string | null): boolean => value === null || value === '';

  const validateFormData = () => {
    setProgramNameMsg(
      programState.programName === null || programState.programName === '' ? 'Please enter program name.' : '',
    );
    setProgramTypeMsg(
      programState.programType === null || programState.programType === '' ? 'Please enter program type.' : '',
    );
    setProgramDescriptionMsg(
      programState.programType === null || programState.programType === '' ? 'Please enterthe description.' : '',
    );
    setProgramDateMsg(programState.programDate === null ? 'Please select the program date.' : '');
    setProgramStartTimeMsg(
      programState.programStartTime === null || programState.programStartTime === '' ? 'Please select start time.' : '',
    );
    setProgramEndTimeMsg(() => {
      if (
        programState.programEndTime === null ||
        programState.programEndTime === ''
      ) {
        return 'Please select end time.';
      } else if (
        programState.programStartTime === null ||
        programState.programStartTime === '' ||
        programState.programStartTime > programState.programEndTime
      ) {
        return 'End time should be later than start time.';
      } else {
        return '';
      }
    });

    setProgramEntertainersMsg(programState.programDetailsReq.length === 0 ? 'Please add entertainers' : '');
    
    if (
      programNameMsg === '' &&
      programTypeMsg === '' &&
      programDescriptionMsg === '' &&
      programDateMsg === '' &&
      programStartTimeMsg === '' &&
      programEndTimeMsg === '' &&
     
      !isNullOrBlank(programState.programName) &&
      !isNullOrBlank(programState.programType) &&
      !isNullOrBlank(programState.programDescription) &&
      (programState.programDate != null || programState.programDate != '') &&
      !isNullOrBlank(programState.programStartTime) &&
      !isNullOrBlank(programState.programEndTime) &&
      addedHostIds.length > 0
    
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = async () => {
    setIsHostIds(true);
    if (!validateFormData()) {
      return;
    }
    OnFileUpload();
    //To add new programs
    const result = await AddNewProgram(programState);
    const isSuccess = result.success;

    //To show success and warning toast based on the result
    if (isSuccess) {
      notificationController.success({
        message: t('notifications.successTitle'),
        description: t('notifications.successProgramDescription'),
      });
      clearData();
      setSelectedFileName('');
    } else {
      notificationController.warning({
        message: t('notifications.warningTitle'),
        description: t('notifications.customWarningDescription'),
      });
    }
  
  };

  //To prevent selecting a date before today
  const isDateDisabled = (date: Dayjs | null) => {
    return date ? date.isBefore(dayjs(), 'day') : false;
  };

  // To get the current day and time
  const today = dayjs();


  
 
  const addEntetainerToProgram = () => {
  
    // Create a Set to store unique IDs
    const uniqueEntertainerIDs = new Set();
  
    // Map and filter to ensure unique IDs
    const updatedEntertainerIDs = addedHostIds.map((id) => ({
      programDetailHostId: id,
      programDetailCreateBy: userIdDecrypted,
    })).filter((entertainer) => {
      const idString = entertainer.programDetailHostId.toString(); // Convert the ID to a string for easy comparison
      if (!uniqueEntertainerIDs.has(idString)) {
        uniqueEntertainerIDs.add(idString); // Add the ID to the Set
        return true; // Return true for unique IDs
      }
      return false; // Return false for duplicate IDs
    });
  
    setProgramState((prevState) => ({
      ...prevState,
      programDetailsReq: [
        ...prevState.programDetailsReq,
        ...updatedEntertainerIDs,
      ],
    }));
  };

  const [tableData, setTableData] = useState<{
    data: UserSearchUsercodeForEntertainerTableRow[];
    pagination: Pagination;
    loading: boolean;
  }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });

  const handleSearchUsers = async () => {
    try {
      const response = await getUsersByUsercode(entertainerID);

      const EntertainerType = capitalizeFirstLetter(response.userhedGroupName);

      if (response) {
        const formattedData: UserSearchUsercodeForEntertainerTableRow[] = [
          {
            key: response.userhedListenerid,
            usercode: response.userhedUsercode,
            name: `${response.listenerFirstName} ${response.listenerMiddleName} ${response.listenerLastName}`,
            email: response.listenerEmail,
            group: EntertainerType,
          },
        ];

        setTableData({
          data: formattedData,
          pagination: tableData.pagination,
          loading: false,
        });
      } else {
        setTableData({
          data: [],
          pagination: tableData.pagination,
          loading: false,
        });
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      setTableData({
        data: [],
        pagination: tableData.pagination,
        loading: false,
      });
    }
  };

  const handleModalVisibleUserCode = () => {
    setModalVisibleEntertainer(true);
    handleSearchUsers();
  };



  const addValue = (value: number) => {
    // setAddedHostIds((prevValues : any) => [value]);
      setAddedHostIds((prevValues: number[]) => {
        if (!prevValues.includes(value)) {
          return [value];
        }
        return prevValues;
      });
   
    
  };

  useEffect(()=>{
    addEntetainerToProgram();
  },[addedHostIds])

  useEffect(() => {

    if(value > 0){
      addValue(value);
    }
    
    if (!searchData && isEditable === false) {
      setIsSearching(false);
    } else {
      if (searchData === null || searchData.listener === null) {
        setIsSearching(false);
        clearData();
      } else {
        const programDate = dayjs(searchData.program.programDate).format('YYYY-MM-DD');
        const startTime = searchData.program.programStartTime;

        const newProgramData = {
          programId: searchData.program.programId,
          programName: searchData.program.programName,
          programDescription: searchData.program.programDescription,
          programType: searchData.program.programType,
          programCreator: userIdDecrypted,
          programDate: dayjs(programDate),
          programStartTime: startTime,
          programEndTime: searchData.program.programEndTime,
          programPhotos: searchData.program.programPhotos,
          programCreateBy: userIdDecrypted,
          programDetailsReq: [],
        };

        (async () => {
          const programDetails = await getProgramDetailsByProgramId(searchData.program.programId);

          const programDetailsArray = programDetails.programDetails;
          //added host id list
          const programDetailHostIds = [];
          const programDetailDetailsIds = [];

          const userDataArray = [];
          const combinedArray = [];

          for (let i = 0; i < programDetailsArray.length; i++) {
            programDetailHostIds.push(programDetailsArray[i].programDetailHostId);
            programDetailDetailsIds.push(programDetailsArray[i].programDetailId);
            const userDataFromUserID = await getUsersByUserId(programDetailsArray[i].programDetailHostId);
            const obj = {
              hostid: programDetailsArray[i].programDetailHostId,
              programdetailsid: programDetailsArray[i].programDetailId,
            };
            combinedArray.push(obj);
            // Push userDataFromUserID into userDataArray
            userDataArray.push(userDataFromUserID);
          }

          const formattedData: addedEntertainersTableRow[] = userDataArray.map((entertainer: any) => ({
            key: entertainer.userhedId,
            name: `${entertainer.listenerFirstName} ${entertainer.listenerLastName}`,
            role: entertainer.userhedGroupName,
            rating: entertainer.listenerRate,
          }));

          setTableEntertainersData({ data: formattedData });

          setCombinedState(combinedArray);
          setHostIDs(programDetailHostIds);
        })();

        const picture = searchData.program.programPhotos;
        const imageUrl = baseUrl + '/Programs/' + picture.replace(/\s/g, '%20');

        setProgramCoverImage(imageUrl);
        setIsSearching(true);
        setProgramState(newProgramData);
      }
    }

    if (isEditable === true) {
      setIsSearching(false);
    } else {
    }
  }, [value, searchData, isEditable]);

  const [tableEntertainersData, setTableEntertainersData] = useState<{ data: readonly addedEntertainersTableRow[] }>({
    data: [],
  });

  const columns: ColumnsType<addedEntertainersTableRow> = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Role',
      dataIndex: 'role',
    },
    {
      title: 'Rate',
      dataIndex: 'rating',
    },
    {
      title: t('tables.actions'),
      dataIndex: 'actions',
      width: '15%',
      render: (text: string, record: { name: string; key: number }) => {
        return (
          <Space>
            <Button type="default" danger onClick={() => handleDeleteRow(record.key)}>
              Remove
            </Button>
          </Space>
        );
      },
    },
  ];

  const handleDeleteRow = async (key: number) => {
    deleteValueFromArray(key);
    const programDetailsId = getProgramDetailsIdByHostId(key);
    const newData = {
      programDetailId: programDetailsId,
      programDetailIsdeleted: true,
    };
    const deleteProgramDetails = await DeleteProgramDetails(newData);
    const isSuccess = deleteProgramDetails.success;
  };

  const deleteValueFromArray = (valueToDelete: number) => {
    const newArray = hostIDs.filter((row) => row !== valueToDelete);
    setHostIDs(newArray);
  };

  const getProgramDetailsIdByHostId = (hostIdToFind: any): any => {
    const foundItem = combinedState.find((item) => item.hostid === hostIdToFind);
    return foundItem ? foundItem.programdetailsid : null;
  };

  const [filteredData, setFilteredData] = useState<
    {
      key: number;
      listenerFirstName: string;
      listenerRate: number | undefined;
    }[]
  >([]);


  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputImage = event.target.files?.[0];
    setImageName(inputImage?.name || null);
  };


  const handleAddedEntertainers = (clickedRow: any) => {
    setAddedEntertainers((prevArray) => [...prevArray, clickedRow]);
  };

  return (
    <Card>
      <Row gutter={{ xs: 10, md: 15, xl: 30 }}>
        <Col xs={24} md={12} className="mb-20">
          <label>{t('common.programName')}</label>
          <Input
            placeholder={t('common.programName')}
            value={programState.programName}
            onChange={handleProgramNameChange}
            disabled={isSearching}
          />
          {programNameMsg ? <p className="validation">{programNameMsg}</p> : null}
        </Col>

        <Col xs={24} md={12} className="mb-20">
          <label>{t('common.programType')}</label>
          <Input
            placeholder={t('common.programType')}
            value={programState.programType}
            onChange={handleProgramTypeChange}
            disabled={isSearching}
          />
          {programTypeMsg ? <p className="validation">{programTypeMsg}</p> : null}
        </Col>

        <Col xs={24} md={24} className="mb-20">
          <label>{t('common.programDescription')}</label>
          <Input
            placeholder={t('common.programDescription')}
            value={programState.programDescription}
            onChange={handleProgramDescriptionChange}
            disabled={isSearching}
          />
          {programDescriptionMsg ? <p className="validation">{programDescriptionMsg}</p> : null}
        </Col>

        <Col xs={24} md={4} className="mb-20">
          <div className="flex-Row">
            <label>{t('common.programDate')}</label>
            <DayjsDatePicker
              value={programState.programDate}
              disabledDate={isDateDisabled}
              onChange={handleProgramDateChange}
              disabled={isSearching}
            />
          </div>
          {programDateMsg ? <p className="validation">{programDateMsg}</p> : null}
        </Col>

        <Col xs={24} md={4} className="mb-20">
          <div className="flex-Row">
            <label>{t('common.programTime')}</label>
            <DayjsDatePicker.TimePicker
              value={programState.programStartTime ? dayjs(programState.programStartTime, 'HH:mm:ss') : null}
              onChange={handleProgramStartTimeChange}
              disabled={isSearching}
            />
          </div>
          {programStartTimeMsg ? <p className="validation">{programStartTimeMsg}</p> : null}
        </Col>

        <Col xs={24} md={4} className="mb-20">
          <div className="flex-Row">
            <label className="transparent">-</label>
            <DayjsDatePicker.TimePicker
              value={programState.programEndTime ? dayjs(programState.programEndTime, 'HH:mm:ss') : null}
              onChange={handleProgramEndTimeChange}
              disabled={isSearching}
            />
          </div>
          {programEndTimeMsg ? <p className="validation">{programEndTimeMsg}</p> : null}
        </Col>

        <Col xs={24} md={12} className="mb-20">
          <SearchModalWithTable
            FieldName={t('common.programEntertainers')}
            Placeholder={t('common.programEntertainers')}
            strMenuCode={'U0003'}
            strSearchControl={'entertainer'}
            strSearchKey={'UP001'}
            strSearchCol={'USERHED_USERCODE'}
            strFilter={'a'}
            OrderBy={'asc'}
            searchPlace={1}
            actionAdd={true}
            actionView={true}
            actionEdit={false}
            actionDelete={false}
            value={value}
            setValue={setValue}
            OnAddEntertainers={handleAddedEntertainers}
          />
          {programEntertainersMsg ? <p className="validation">{programEntertainersMsg}</p> : null}
        </Col>

        <Col xs={24} md={24} className="mb-20">
          <div className="draggable-upload-input">
            <label
              htmlFor="inputTag"
              className="draggable-upload-label"
              onDragOver={(e) => e.preventDefault()}
              onDrop={handleFileDrop}
            >
              <InboxOutlined className="upload-image-icon" />
              <h4>Click or drag file to this area to upload</h4>
              <p>
                Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files
              </p>
              <br />
              <input
                id="inputTag"
                type="file"
                onChange={handleFileChange}
                style={{ display: 'none' }}
                disabled={isSearching}
              />
              <br />
              <span id="imageName">{selectedFileName ? selectedFileName : null}</span>
            </label>
          </div>
          {programImageMsg ? <p className="validation">{programImageMsg}</p> : null}
          {programCoverImage ? <img src={programCoverImage} alt="Programimage" width={84} height={84} /> : null}
        </Col>

        <Col xs={24} md={24} className="mb-20">
          {hostIDs.length !== 0 ? (
            <Table
              columns={columns}
              dataSource={tableEntertainersData.data as addedEntertainersTableRow[]}
              scroll={{ x: 800 }}
              bordered
              pagination={false}
            />
          ) : null}
        </Col>

        <Col xs={24} md={24} className="mb-20 flex-end">
          <Button onClick={handleSubmit} disabled={isSearching}>
            Submit
          </Button>
        </Col>
      </Row>
    </Card>
  );
};
