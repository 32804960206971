import React, { useState } from 'react';
import { RolesData } from './RolesData/RolesData';
import { useTranslation } from 'react-i18next';
import * as S from '@app/pages/Utilities/Users/Users.style';
import TopToolBar from '@app/framework/layout/TopToolBar';
import { RoleDataTable } from '@app/pages/Utilities/Roles/RolesData/RoleDataTable';

export const Roles: React.FC = () => {
  const { t } = useTranslation();
  const [showRoleData, setShowRoleData] = useState(true);
  const [showRoleDataTable, setShowUserRoleDataTable] = useState(false);
  const handleViewClick = () => {
    setShowRoleData(false); // Show the RoleDataTable
    setShowUserRoleDataTable(true); // and hide the RoleData
  };

  const onAddNewClick = () => {
    setShowRoleData(true); // Show the RoleData
    setShowUserRoleDataTable(false); // Hide the RoleDataTable
  };

  return (
    <>
      <S.TablesWrapper>
        <S.Card id="user-table" title={t('common.roles')} padding="1.25rem 1.25rem 0">
          <TopToolBar onViewClick={handleViewClick} onAddNewClick={onAddNewClick} />
          {/* {showRoleData ? <RolesData/> : null} */}
          {/* Only render RoleData when showRoleData is true */}
          {/* {showRoleDataTable ? <RoleDataTable /> : null}  */}
          <RoleDataTable />
          {/* Only render RoleDataTable when showRoleDataTable is true */}
        </S.Card>
      </S.TablesWrapper>
    </>
  );
};
