import React, { useState, useEffect } from 'react';
import { ListenerData } from '@app/pages/Utilities/Listeners/ListenerData/ListenersData';
import { ListenerDataTable } from '@app/pages/Utilities/Listeners/ListenerData/ListenerDataTable';
import { useTranslation } from 'react-i18next';
import * as S from './Listeners.style';
import TopToolBar from '@app/framework/layout/TopToolBar';
import { UpdateListener, getListenerByID } from '@app/pages/Utilities/Listeners/ListenerData/ListenerDataController';
import { notificationController } from '@app/controllers/notificationController';
import { SearchModalWithTable } from '../SearchModal/SearchModalWithTable';
import { Modal } from 'antd';
import { DecryptData } from '../Common/DecryptData';

interface listenerIDProp {
  listenerID?: any;
}
export const Listeners: React.FC<listenerIDProp> = ({ listenerID }) => {
  const { t } = useTranslation();
  const [showListenerData, setShowListenerData] = useState(true);
  const [showListenerDataTable, setShowListenerDataTable] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchData, setSearchData] = useState<any>(null);
  const [userCode, setUserCode] = useState('');
  const [rowClickedID, setRowClickedId] = useState(0);
  const [updatedListenerID, setUpdatedListenerID] = useState<any>(null);

  const handleViewClick = () => {
    setShowListenerData(false);
    setShowListenerDataTable(true);
  };

  const onAddNewClick = () => {
    setShowListenerData(true);
    setShowListenerDataTable(false);
  };

  //To get the listeners data by id
  const handleSearchListener = async () => {
    try {
      const response = await getListenerByID(parseInt(searchQuery));
      const Listener = response.data;
      if (response) {
        setSearchData(response);
        return Listener;
      } else if (response.listener === null) {
        notificationController.info({
          message: t('notifications.informationTitle'),
          description: t('notifications.NoResultDescription'),
        });
      }
    } catch (error) {
      console.error('Error fetching listener data:', error);
    }
  };

  const handleEditListener = () => {
    setShowListenerData(true);
    setShowListenerDataTable(false);
    setIsEditable(true);
  };

  // Callback function to update the listenerID when called
  const handleListenerIDUpdate = (updatedID: any) => {
    setUpdatedListenerID(updatedID);
  };

  const handleListenerData = async (ID: any) => {
    try {
      const response = await getListenerByID(parseInt(ID));
      const Listener = response.data;
      if (response) {
        setSearchData(response);
        const idk = listenerID;
        return Listener;
      } else if (response.listener === null) {
        notificationController.info({
          message: t('notifications.informationTitle'),
          description: t('notifications.NoResultDescription'),
        });
      }
    } catch (error) {
      console.error('Error fetching listener data:', error);
    }
  };

  useEffect(() => {
    if (updatedListenerID > 0) {
      setShowListenerData(true);
      setShowListenerDataTable(false);
      handleListenerData(updatedListenerID);
    } else if (userCode != null) {
      
    }
  }, [updatedListenerID, userCode]);


  const handleRowClickInPage = (clickedRow: any) => {
    setShowListenerData(true);
    setShowListenerDataTable(false);
    handleListenerData(clickedRow);
    setRowClickedId(clickedRow);
  };

  const userId = sessionStorage.getItem('session2');
  const userIdDecrypted = DecryptData(userId);

  const handleDelete = async () => {
    Modal.confirm({
      title: 'Confirm Delete',
      content: 'Are you sure you want to delete this listener?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: async () => {

        const newData = {
          listenerId: rowClickedID,
          listenerIsdelete: true,
          listenerDeleteBy: userIdDecrypted,
        };
        const deleteUser = await UpdateListener(newData);
        const isSuccess = deleteUser.success;

        if (isSuccess) {
          notificationController.success({
            message: t('notifications.successTitle'),
            description: t('notifications.successListenerDeleteDescription'),
          });
          setIsEditable(true);
          setSearchData(null);
        } else {
          notificationController.warning({
            message: t('notifications.warningTitle'),
            description: t('notifications.customWarningDescription'),
          });
        }
      },
      onCancel() {
        //on cancel
      },
    });
  };

  return (
    <>
      <S.TablesWrapper>
        <S.Card id="user-table" title={t('listeners.listenerTable')} padding="1.25rem 1.25rem 0">
          <div className="search-Wrapper">
            <SearchModalWithTable
              FieldName=""
              Placeholder="Listener Name"
              strMenuCode={'UL001'}
              strSearchControl={'listeners'}
              strSearchKey={'ULS01'}
              strSearchCol={'LISTENER_FIRST_NAME'}
              strFilter={'a'}
              OrderBy={'asc'}
              searchPlace={1}
              actionAdd={false}
              actionView={true}
              actionEdit={false}
              actionDelete={false}
              userCode={userCode}
              setUserCode={setUserCode}
              onRowClick={handleRowClickInPage}
            />
          </div>
          <TopToolBar
            onViewClick={handleViewClick}
            onAddNewClick={onAddNewClick}
            onEditClick={handleEditListener}
            onDelete={handleDelete}
          />
          {showListenerData ? (
            <ListenerData onClick={handleSearchListener} searchData={searchData} isEditable={isEditable} />
          ) : null}{' '}
          {/* Only render ListenerData when showListenerData is true */}
          {showListenerDataTable ? <ListenerDataTable onListenerIDUpdate={handleListenerIDUpdate} /> : null}{' '}
          {/* Only render ListenerDataTable when showListenerDataTable is true */}
        </S.Card>
      </S.TablesWrapper>
    </>
  );
};
