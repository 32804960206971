import React, { useState, useEffect } from 'react';
import { ProgramsData } from '../Programs/ProgramsData/ProgramData';
import { useTranslation } from 'react-i18next';
import { ProgramsDataTable } from '@app/pages/Utilities/Programs/ProgramsData/ProgramDataTable';
import TopToolBar from '@app/framework/layout/TopToolBar';
import { AddNewProgram, getProgramByID } from '@app/pages/Utilities/Programs/ProgramController';
import { notificationController } from '@app/controllers/notificationController';
import * as S from './BasicStyleForm';
import { SearchModalWithTable } from '../SearchModal/SearchModalWithTable';
import { Modal } from 'antd';
import { DecryptData } from '../Common/DecryptData';

export const Programs: React.FC = () => {
  const { t } = useTranslation();
  const [showProgramData, setShowProgramData] = useState(true);
  const [showProgramDataTable, setShowProgramDataTable] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [searchData, setSearchData] = useState<any>(null);
  const [userCode, setUserCode] = useState('');
  const [rowClickedID, setRowClickedId] = useState(0);

  const handleViewClick = () => {
    setShowProgramData(false);
    setShowProgramDataTable(true);
  };

  const onAddNewClick = () => {
    setShowProgramData(true);
    setShowProgramDataTable(false);
  };

  const text = t('New Program');

  const handleEditProgram = () => {
    setShowProgramData(true);
    setShowProgramDataTable(false);
    setIsEditable(true);
  };

  const handleProgramData = async (ID: any) => {
    try {
      const response = await getProgramByID(parseInt(ID));
      const program = response.data;
      if (response) {
        setSearchData(response);
        return program;
      } else if (response.listener === null) {
        notificationController.info({
          message: t('notifications.informationTitle'),
          description: t('notifications.NoResultDescription'),
        });
      }
    } catch (error) {
      console.error('Error fetching listener data:', error);
    }
  };

  const [updatedProgramID, setUpdatedProgramID] = useState<any>(null);
  // Callback function to update the listenerID when called
  const handleProgramIDUpdate = (updatedID: any) => {
    setUpdatedProgramID(updatedID);
  };

  useEffect(() => {
    if (updatedProgramID > 0) {
      setShowProgramData(true);
      setShowProgramDataTable(false);
      handleProgramData(updatedProgramID);
    } else if (userCode != null) {
      
    }
  }, [updatedProgramID, userCode]);


  const handleRowClickInPage = (clickedRow: any) => {
    setShowProgramData(true);
    setShowProgramDataTable(false);
    handleProgramData(clickedRow);
    setRowClickedId(clickedRow);
  };

  const userId = sessionStorage.getItem('session2');
  const userIdDecrypted = DecryptData(userId);

  const handleDelete = async () => {
    Modal.confirm({
      title: 'Confirm Delete',
      content: 'Are you sure you want to delete this program?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: async () => {
        
        const newData = {
          programId: rowClickedID,
          programIsdeleted: true,
          programDeleteBy: userIdDecrypted,
          programPhotos: 'string',
          programDetailsReq: [],
        };
        const deleteUser = await AddNewProgram(newData);
        const isSuccess = deleteUser.success;

        if (isSuccess) {
          notificationController.success({
            message: t('notifications.successTitle'),
            description: t('notifications.successProgramDeleteDescription'),
          });
          setIsEditable(true);
          setSearchData(null);
        } else {
          notificationController.warning({
            message: t('notifications.warningTitle'),
            description: t('notifications.customWarningDescription'),
          });
        }
      },
      onCancel() {
        //on cancel
      },
    });
  };

  return (
    <>
      <S.TablesWrapper>
        <S.Card id="user-table" title={t('programs.programTable')} padding="1.25rem 1.25rem 0">
          <div className="search-Wrapper">
            <SearchModalWithTable
              FieldName=""
              Placeholder="Program Name"
              strMenuCode={'U0003'}
              strSearchControl={'programs'}
              strSearchKey={'UP001'}
              strSearchCol={'PROGRAM_NAME'}
              strFilter={'a'}
              OrderBy={'asc'}
              searchPlace={2}
              actionAdd={false}
              actionView={true}
              actionEdit={false}
              actionDelete={false}
              userCode={userCode}
              setUserCode={setUserCode}
              onRowClick={handleRowClickInPage}
            />
          </div>
          <TopToolBar
            onViewClick={handleViewClick}
            onAddNewClick={onAddNewClick}
            onEditClick={handleEditProgram}
            onDelete={handleDelete}
          />
          {showProgramData ? (
            <>
              <ProgramsData searchData={searchData} isEditable={isEditable} />
            </>
          ) : null}
          {showProgramDataTable ? <ProgramsDataTable onProgramIDUpdate={handleProgramIDUpdate} /> : null}
        </S.Card>
      </S.TablesWrapper>
    </>
  );
};
