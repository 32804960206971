import React, { useEffect, useState } from 'react';
import { UsersData } from '@app/pages/Utilities/Users/UserData/UsersData';
import { useTranslation } from 'react-i18next';
import * as S from './Users.style';
import TopToolBar from '@app/framework/layout/TopToolBar';
import { UsersDataTable } from '@app/pages/Utilities/Users/UserData/UserDataTable';
import { getUsersByUsercode, AddNewUser } from './UserData/UserDataController';
import { notificationController } from '@app/controllers/notificationController';
import { SearchModalWithTable } from '../SearchModal/SearchModalWithTable';
import { Modal } from 'antd';

export const Users: React.FC = () => {
  const { t } = useTranslation();
  const [showUserData, setShowUserData] = useState(true);
  const [showUserDataTable, setShowUserDataTable] = useState(false);
  const [searchData, setSearchData] = useState<any>(null);
  const [isEditable, setIsEditable] = useState(false);
  const [userCode, setUserCode] = useState('');
  const [updatedUsercode, setUpdatedUsercode] = useState<any>(null);
  const [rowClickedID, setRowClickedId] = useState('');

  const handleViewClick = () => {
    setShowUserData(false); // Show the UserDataTable
    setShowUserDataTable(true); // and hide the UserData
  };

  const onAddNewClick = () => {
    setShowUserData(true); // Show the UserData
    setShowUserDataTable(false); // Hide the UserDataTable
  };


  const handleDelete = async () => {
    Modal.confirm({
      title: 'Confirm Delete',
      content: 'Are you sure you want to delete this user?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: async () => {
        const userListenerID = await getUsersByUsercode(rowClickedID);
        console.log('listener ID delete', userListenerID);
        const listenerID = userListenerID.userhedListenerid;

        const newData = {
          userhedUsercode: rowClickedID,
          userhedPassword: 'password',
          userhedListenerid: listenerID,
          userhedActiveuser: 0,
        };
        const deleteUser = await AddNewUser(newData);
        console.log('delete user', deleteUser);
        const isSuccess = deleteUser.success;

        if (isSuccess) {
          notificationController.success({
            message: t('notifications.successTitle'),
            description: t('notifications.successUserDeleteDescription'),
          });
          setIsEditable(true);
          setSearchData(null);
        } else {
          notificationController.warning({
            message: t('notifications.warningTitle'),
            description: t('notifications.customWarningDescription'),
          });
        }
      },
      onCancel() {
        //on cancel
      },
    });
  };

  const handleEditUser = () => {
    setShowUserData(true);
    setShowUserDataTable(false);
    setIsEditable(true);
  };

  // Callback function to update the listenerID when called
  const handleUsercodeUpdate = (updatedID: any) => {
    setUpdatedUsercode(updatedID);
  };

  //To get the user data by userCode
  const handleViewUser = async (userCode: string) => {
    try {
      const response = await getUsersByUsercode(userCode);
      console.log('search by usercode', response);
      if (response) {
        setSearchData(response);
      } else if (response === null) {
        notificationController.info({
          message: t('notifications.informationTitle'),
          description: t('notifications.NoResultDescription'),
        });
      }
    } catch (error) {
      console.error('Error fetching listener data:', error);
    }
  };

  useEffect(() => {
    console.log('updatde usercode', updatedUsercode);
    if (updatedUsercode != null && updatedUsercode != undefined && updatedUsercode != '') {
      setShowUserData(true);
      setShowUserDataTable(false);
      handleViewUser(updatedUsercode);
    } else if (userCode != null && userCode != undefined && userCode != '') {
    }
    console.log('usercode from table', userCode);
  }, [updatedUsercode, userCode]);

  const handleRowClickInPage = (clickedRow: any) => {
    setShowUserData(true);
    setShowUserDataTable(false);
    handleViewUser(clickedRow);
    setRowClickedId(clickedRow);
    console.log('Clicked row data in Page component:', clickedRow);
  };

  return (
    <>
      <S.TablesWrapper>
        <S.Card id="user-table" title={t('users.userTable')} padding="1.25rem 1.25rem 0">
          <div className="search-Wrapper">
            <SearchModalWithTable
              FieldName=""
              Placeholder={t('common.usercode')}
              strMenuCode={'U0001'}
              strSearchControl={'user'}
              strSearchKey={'UU001'}
              strSearchCol={'USERHED_USERCODE'}
              strFilter={'a'}
              OrderBy={'asc'}
              searchPlace={1}
              actionAdd={false}
              actionView={true}
              actionEdit={false}
              actionDelete={false}
              userCode={userCode}
              setUserCode={setUserCode}
              onRowClick={handleRowClickInPage}
            />
          </div>
          <TopToolBar
            onViewClick={handleViewClick}
            onAddNewClick={onAddNewClick}
            onEditClick={handleEditUser}
            onDelete={handleDelete}
          />
          {showUserData ? <UsersData searchData={searchData} isEditable={isEditable} /> : null}{' '}
          {/* Only render UsersData when showUserData is true */}
          {showUserDataTable ? <UsersDataTable onUsercodeUpdate={handleUsercodeUpdate} /> : null}{' '}
          {/* Only render UsersDataTable when showUserDataTable is true */}
        </S.Card>
      </S.TablesWrapper>
    </>
  );
};
