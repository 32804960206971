import React, { useState, useEffect } from 'react';
import { Avatar, Col, Row } from 'antd';
import { Dropdown } from '@app/components/common/Dropdown/Dropdown';
import { H6 } from '@app/components/common/typography/H6/H6';
import { ProfileOverlay } from '../ProfileOverlay/ProfileOverlay';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { useResponsive } from '@app/hooks/useResponsive';
import * as S from './ProfileDropdown.styles';
import DefaultUserProfile from '@app/assets/images/default_user_profile_picture.png';
import { DecryptData } from '@app/pages/Utilities/Common/DecryptData';
import { getUsersByUsercode } from '@app/pages/Utilities/Users/UserData/UserDataController';

//NS: Log out
export const ProfileDropdown: React.FC = () => {
  const { isTablet } = useResponsive();

  const user = useAppSelector((state) => state.user.user);
  const [viewImage, setViewImage] = useState('');

  const listenerIDString = sessionStorage.getItem('session1');
  const username = DecryptData(listenerIDString);
  const name = username.split(' ');
  const firstname = name[0];
  const userCode = sessionStorage.getItem('session3');
  const userCodeDecrypted = DecryptData(userCode);

  const baseUrl = process.env.REACT_APP_API_BASEURL;

  useEffect(() => {
    (async () => {
      try {
        const getUserProfilePicture = await getUsersByUsercode(userCodeDecrypted);
        const picture = getUserProfilePicture.userhedPicture;
        const imageUrl = baseUrl + '/Users/' + picture.replace(/\s/g, '%20');
        if (picture != '') {
          setViewImage(imageUrl);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  return (
    <Dropdown overlay={<ProfileOverlay />} trigger={['click']}>
      <S.ProfileDropdownHeader as={Row} gutter={[10, 10]} align="middle">
        <Col>
          <Avatar src={viewImage ? viewImage : DefaultUserProfile} alt="User" shape="circle" size={40} />
        </Col>
        {isTablet && (
          <Col>
            <H6>{firstname}</H6>
          </Col>
        )}
      </S.ProfileDropdownHeader>
    </Dropdown>
  );
};
