import ApiCallAxio from '@app/api/apiCallAxio';
import APICallMethods from '@app/types/APICallMethods';

//Import url from config file
const baseUrl = process.env.REACT_APP_API_BASEURL;
const sublink = process.env.REACT_APP_API_SUBLINK;
const version = process.env.REACT_APP_API_VERSION;

interface SongRequestProps {
  songReqID?: number;
  songReqName?: string;
  songReqArtist?: string;
  songReqType?: string;
  songReqComment?: string;
  songReqStatus?: number;
  songReqCreatedBy?: number;
  songReqUpdateBy?: number;
  songReqIsDeleted?: boolean;
  songReqDeleteBy?: number;
}

const addNewSongRequest = async (newSongRequest: SongRequestProps) => {
  const url = `${baseUrl}/${sublink}/${version}/songRequest`;
  const result = await ApiCallAxio({ url: url, method: APICallMethods.POST, data: newSongRequest });
  return result;
};

export { addNewSongRequest };
