import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar } from 'antd';
import { UserModel } from '@app/domain/UserModel';
import DefaultUser from '@app/assets/images/default_user_profile_picture.png';
import { Rate } from '@app/components/common/Rate/Rate';
import CryptoJS from 'crypto-js';
import * as S from './ProfileInfo.styles';
import { DecryptData } from '@app/pages/Utilities/Common/DecryptData';
import { EditFilled, CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import axios, { AxiosRequestConfig } from 'axios';
import { AddNewUser } from '@app/pages/Utilities/Users/UserData/UserDataController';
import { userData } from '@app/pages/Utilities/Modal/Types/UserDataTypes';
import { notificationController } from '@app/controllers/notificationController';
import { getUsersByUsercode } from '@app/pages/Utilities/Users/UserData/UserDataController';

interface ProfileInfoProps {
  profileData: UserModel | null;
}

export const ProfileInfo: React.FC = () => {
  const [fullness] = useState(70);
  const { t } = useTranslation();
  const listenerIDString = sessionStorage.getItem('session1');
  const username = DecryptData(listenerIDString);
  const [genaratedUuid, setGeneratedUuid] = useState('');
  const [selectedFile, setSelectedFile] = useState<any>('');
  const [viewImage, setViewImage] = useState('');

  // const uuid = uuidv4();

  const userId = sessionStorage.getItem('session2');
  const listenerID = sessionStorage.getItem('session');
  const userCode = sessionStorage.getItem('session3');
  const userIdDecrypted = DecryptData(userId);
  const userListenerIDDecrypted = DecryptData(listenerID);
  const userCodeDecrypted = DecryptData(userCode);

  const [userState, setUserState] = useState<userData>({
    userhedId: userIdDecrypted,
    userhedUsercode: userCodeDecrypted,
    userhedPassword: 'password',
    userhedListenerid: userListenerIDDecrypted,
    userhedPicture: '',
  });

  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const config: AxiosRequestConfig = {
    headers: {
      // 'Content-Type': 'application/json',
      'Content-Type': `multipart/form-data; boundary=---------------------------293582696224464`,
      'Access-Control-Allow-Origin': location.hostname,
      'Access-Control-Allow-Credentials': 'true',
    },
    withCredentials: true,
  };

  useEffect(() => {
    (async () => {
      try {
        const getUserProfilePicture = await getUsersByUsercode(userCodeDecrypted);
        const picture = getUserProfilePicture.userhedPicture;
        const imageUrl = baseUrl + '/Users/' + picture.replace(/\s/g, '%20');
        if (picture != '') {
          setViewImage(imageUrl);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const selectedFile = event.target.files[0];
      setSelectedFile(selectedFile);
      const uuid = uuidv4();
      const imgType = selectedFile.type;
      const image_type = imgType.split('/')[1];
      const imageFileName = `${uuid}.${image_type}`;
      setGeneratedUuid(uuid);

      setSelectedImage(URL.createObjectURL(selectedFile));

      // Update the programState with the selected file name
      setUserState((prevState) => ({
        ...prevState,
        userhedPicture: imageFileName,
      }));
      // OnFileUpload(selectedFile, uuid);
    }
  };

  //Import url from config file
  const baseUrl = process.env.REACT_APP_API_BASEURL;
  const sublink = process.env.REACT_APP_API_SUBLINK;
  const version = process.env.REACT_APP_API_VERSION;

  const OnFileUpload = async () => {

    // Create an object of formData
    const formData = new FormData();
    formData.append('file from uplaod ', selectedFile);
    formData.append('strFolder', 'Users');
    formData.append('fileName', genaratedUuid);
    const response = await axios.post(`${baseUrl}/${sublink}/${version}/CommonCodes/fileUpload`, formData, config);
    const isSuccess = response.data.success;
    if (isSuccess) {
      const updateUserPic = await AddNewUser(userState);
      const picAddedSuccess = updateUserPic.success;
      if (picAddedSuccess) {
        notificationController.success({
          message: t('notifications.successTitle'),
          description: t('notifications.successProfilePictureDescription'),
        });
        setSelectedImage('');
      } else {
        notificationController.warning({
          message: t('notifications.warningTitle'),
          description: t('notifications.customWarningDescription'),
        });
        setSelectedImage('');
      }
    }
  };

  const onExitFileUplaod = () => {
    setSelectedImage('');
  };

  return (
    <S.Wrapper>
      <S.ImgWrapper>
        <Avatar shape="circle" src={selectedImage || viewImage || DefaultUser} alt="Profile" />
      </S.ImgWrapper>
      <div>
        <label htmlFor="files" className="btn">
          <EditFilled />
        </label>
        <input id="files" style={{ visibility: 'hidden' }} type="file" onChange={handleFileChange} />
        <div className="img-upload-icon">
          {selectedImage ? <CheckCircleFilled className="mb-20 icon-size-20" onClick={OnFileUpload} /> : null}
          {selectedImage ? <CloseCircleFilled className="mb-20 icon-size-20" onClick={onExitFileUplaod} /> : null}
        </div>
      </div>

      <S.Title>{username}</S.Title>
      {/* <S.Subtitle>
        <Rate allowHalf defaultValue={4} />
      </S.Subtitle> */}
      {/* <S.FullnessWrapper>
        <S.FullnessLine width={fullness}>{fullness}%</S.FullnessLine>
      </S.FullnessWrapper>
      <S.Text>{t('profile.fullness')}</S.Text> */}
      <hr className="mt-10" />
    </S.Wrapper>
  );
};
