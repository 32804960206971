import React, { useEffect, useState, useCallback } from 'react';
import { Space, TablePaginationConfig } from 'antd';
import { ListenerTableRow } from '@app/api/table.api';
import { Table } from '@app/components/common/Table/Table';
import { ColumnsType } from 'antd/es/table';
import { Button } from '@app/components/common/buttons/Button/Button';
import { useTranslation } from 'react-i18next';
import { useMounted } from '@app/hooks/useMounted';
import { getListeners } from '@app/pages/Utilities/Listeners/ListenerData/ListenerDataController';
import { format } from 'date-fns';
import TopToolBar from '@app/framework/layout/TopToolBar';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';

const pageSize = 5;

export const ListenerDataTable: React.FC<{ onListenerIDUpdate: (listenerID: any) => void }> = ({
  onListenerIDUpdate,
}) => {
  const [tableData, setTableData] = useState<{ data: ListenerTableRow[]; currentPage: number; loading: boolean }>({
    data: [],
    currentPage: 0,
    loading: false,
  });

  const { t } = useTranslation();
  const { isMounted } = useMounted();
  const [listenerID, setListenerID] = useState<any>(null);
  const [dataLength, setDataLength] = useState<number>();

  const fetch = useCallback(
    async (page: number) => {
      setTableData((tableData) => ({ ...tableData, loading: true }));
      try {
        const response = await getListeners(page * pageSize, pageSize);
        if (isMounted.current) {
          // Extract relevant information from the response and format it as ListenerTableRow[]
          const formattedData: ListenerTableRow[] = response.listener.map((listener: any) => ({
            key: listener.listenerId,
            name: `${listener.listenerFirstName} ${listener.listenerLastName}`,
            birthday: format(new Date(listener.listenerDob), 'yyyy-MM-dd'),
            email: listener.listenerEmail,
            contactNumber: listener.listenerMobile.toString(),
            nic: listener.listenerNic,
            group: listener.listenerGroup == 0 ? t('tables.free') : t('tables.premium'),
            status: listener.listenerStatus ? t('tables.active') : t('tables.inactive'),
          }));
          setDataLength(formattedData.length);
          setTableData({ data: formattedData, currentPage: page, loading: false });
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
      }
    },
    [isMounted, t],
  );

  useEffect(() => {
    fetch(0);
  }, [fetch]);

  const handleNextPage = () => {
    fetch(tableData.currentPage + 1);
  };

  const handlePreviousPage = () => {
    if (tableData.currentPage > 0) {
      fetch(tableData.currentPage - 1);
    }
  };

  const handleViewListenersByID = (rowId: number) => {
    if (!rowId) {
    } else {
      setListenerID(rowId);
      onListenerIDUpdate(rowId);
      return rowId;
    }
  };

  const columns: ColumnsType<ListenerTableRow> = [
    {
      title: t('common.name'),
      dataIndex: 'name',
    },
    {
      title: t('common.birthday'),
      dataIndex: 'birthday',
    },
    {
      title: t('common.email'),
      dataIndex: 'email',
    },
    {
      title: t('common.contactNumber'),
      dataIndex: 'contactNumber',
    },
    {
      title: t('common.nic'),
      dataIndex: 'nic',
    },
    {
      title: t('common.group'),
      dataIndex: 'group',
    },
    {
      title: t('common.status'),
      dataIndex: 'status',
    },
    {
      title: t('tables.actions'),
      dataIndex: 'actions',
      width: '15%',
      render: (text: string, record: { name: string; key: number }) => {
        return (
          <Space>
            <Button type="default" onClick={() => handleViewListenersByID(record.key)}>
              {t('common.view')}
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <div className="hide">{listenerID !== null && <TopToolBar listenerID={listenerID} />}</div>

      <Table columns={columns} dataSource={tableData.data} loading={tableData.loading} bordered pagination={false} />
      <div className="custom-pagination">
        <Button onClick={handlePreviousPage} disabled={tableData.currentPage + 1 === 1}>
          <LeftOutlined />
        </Button>
        <span className="page-no"> {tableData.currentPage + 1}</span>
        <Button onClick={handleNextPage} disabled={dataLength !== undefined && dataLength < 5}>
          <RightOutlined />
        </Button>
      </div>
    </>
  );
};
