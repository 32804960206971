import React, { useState, useEffect } from 'react';
import { Input, Modal } from 'antd';
import { Button } from 'components/common/buttons/Button/Button';
import { useTranslation } from 'react-i18next';
import { SearchOutlined } from '@ant-design/icons';
import { searchDataProps } from '../Modal/Types/SearchData';
import { CustomTable } from '@app/pages/Utilities/SearchModal/Table';

export const SearchModalWithTable = (props: searchDataProps) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState('');
  const [isModalVisible, setModalVisible] = useState(false);
  const [selectedColumn, setSelectedColumn] = useState('');
  const [isReset, setIsReset] = useState(false);

  const [entertainers, setEntertainers] = useState<number[]>([]);

  const handleModalVisible = () => {
    setModalVisible(true);
  };

  const handleSelectedColumnChange = (column: any) => {
    setSelectedColumn(column);
  };

  useEffect(() => {
    //To clear the search input after selected a another search column
    setSearchValue('');
    console.log(entertainers);
  }, [selectedColumn, entertainers]);

  const handleReseteSearchQuery = () => {
    setSelectedColumn('');
    setIsReset(true);
  };

  const handleKeyDown = async (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleModalVisible();
    }
  };

  const handleCloseModal = () => {
    // Set the modal visibility state to false to close the modal
    setModalVisible(false);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  return (
    <>
      <label>{props.FieldName}</label>
      <Input
        placeholder={selectedColumn === '' ? 'Search by ' + props.Placeholder : 'Search by ' + selectedColumn}
        value={searchValue}
        onKeyDown={handleKeyDown}
        onChange={handleSearchChange}
        onClick={handleModalVisible}
        addonAfter={<SearchOutlined onClick={() => handleModalVisible()} />}
      />
      <Modal
        title={
          <>
            <div className="search-bar-modal">
              <Input
                placeholder={selectedColumn === '' ? 'Search by ' + props.Placeholder : 'Search by ' + selectedColumn}
                value={searchValue}
                onChange={(event) => setSearchValue(event.target.value)}
                addonAfter={<SearchOutlined />}
                className="w-90"
              />
              <Button className="reset-button" onClick={handleReseteSearchQuery}>
                Reset
              </Button>
            </div>
          </>
        }
        visible={isModalVisible}
        onCancel={() => setModalVisible(false)}
        // To remove the OK button
        okButtonProps={{ style: { display: 'none' } }}
        bodyStyle={{ maxHeight: 'calc(100vh - 300px)', overflowY: 'auto' }}
        width={1000}
      >
        <CustomTable
          {...props}
          searchedValue={searchValue}
          onSelectedColumnChange={handleSelectedColumnChange}
          reset={isReset}
          value={props.value}
          setValue={props.setValue}
          userCode={props.userCode}
          setUserCode={props.setUserCode}
          onCloseModal={handleCloseModal}
          data={props.data}
          onTableDataChange={props.onTableDataChange}
          onRowClick={props.onRowClick}
          ListenerName={props.ListenerName}
          OnAddEntertainers={props.OnAddEntertainers}
        />
      </Modal>
    </>
  );
};
